import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule, ConpulseModule } from 'libs/conpulse/src/index';
import { I18NextModule } from 'angular-i18next';
import { SupportContactComponent } from './support-contact.component';

@NgModule({
  declarations: [SupportContactComponent],
  imports: [CommonModule, FormsModule, ConpulseMaterialModule, I18NextModule,ConpulseModule],
  exports: [SupportContactComponent],
})
export class SupportContactModule {}
