import { Component, Input, OnInit } from '@angular/core';
import { SoWFieldValidationStatus } from '@conpulse-web/core';

@Component({
  selector: 'conpulse-web-dynamic-form-validation-chip',
  templateUrl: './dynamic-form-validation-chip.component.html',
  styleUrls: ['./dynamic-form-validation-chip.component.scss'],
})
export class DynamicFormValidationChipComponent {
  SoWFieldValidationStatus = SoWFieldValidationStatus;
  SoWFieldValidationShowStatus = [SoWFieldValidationStatus.PENDING, SoWFieldValidationStatus.VALIDATED];
  currentStatus: number =  SoWFieldValidationStatus.INITIAL
  @Input() set validationStatus(status: number){
    if(this.SoWFieldValidationShowStatus.includes(status)){
      this.currentStatus = status
    }
  }
}
