import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT_TYPE, DocumentOrLink, NotificationService, WebsiteRegex, urlBeginsWithHttp } from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';

@Component({
  selector: 'conpulse-web-upload-docs-or-link',
  templateUrl: './upload-docs-or-link.component.html',
  styleUrls: ['./upload-docs-or-link.component.scss'],
})
export class UploadDocsOrLinkComponent implements OnInit {
  @Input() documentDetails: { size: number; allowedFiles: string } = { size: 10 * 1024 * 1024, allowedFiles: "" };
  @Input() documents: Array<DocumentOrLink> = [];
  @Input() multipleUpload: boolean = false;
  @Input() viewOnly: boolean = false;
  @Output() documentUpdated: EventEmitter<void> = new EventEmitter();
  @Output() download: EventEmitter<{ key: string; name?: string }> = new EventEmitter();
  driveLink: string = '';
  websiteRegex = WebsiteRegex;
  disableDocumentUpload: boolean = false;
  documentType = DOCUMENT_TYPE;
  fileList = []
  formatList = []

  constructor(private notificationService: NotificationService, private utilityService: UtilityMethodsService) { }

  ngOnInit() {
    this.loadFileFormats()
  }

  /**
   * Loads files fornats list
   */
  async loadFileFormats() {
    const data = await this.utilityService.loadAllowedFileFormats()
    this.fileList = data.fileList
    this.formatList = data.formatList
    this.documentDetails.allowedFiles = data.allowedFiles
  }

  /**
   * Event handler for adding associated documents
   * @param event Event from file input element
   */
  uploadDocument(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;
    for (let index = 0; index < fileList.length; index++) {
      const fileName = fileList[index].name;
      const fileExtension = fileList[index].name.substring(fileList[index].name.lastIndexOf('.'), fileList[index].name.length);
      const fileSupported = this.formatList.includes(fileExtension);
      const maxFileSize = this.utilityService.findMaxFileSize(this.fileList, fileExtension)
      if (!fileSupported) {
        this.notificationService.openErrorSnackBar(`Unable to upload ${fileName} since it is not of allowed file formats`);
        return;
      } else if (fileList[index].size > (maxFileSize * Math.pow(10, 6))) {
        this.notificationService.openErrorSnackBar(
          `Total size of the file ${fileList[index].name} should be less than ${maxFileSize} MB`
        );
      } else {
        const data = {
          file: fileList[index],
          type: DOCUMENT_TYPE.DOCUMENT,
          name: fileList[index].name,
          isNewUpload: true,
          key: undefined,
        };
        this.documents.push({ ...data });
        if (!this.multipleUpload) this.disableDocumentUpload = true;
        this.documentUpdated.emit();
      }
    }
  }

  /**
   * Adding link to document list
   */
  addLinkToDocument() {
    this.documents.push({ file: null, type: DOCUMENT_TYPE.LINK, key: this.driveLink, name: this.driveLink, isNewUpload: false });
    this.driveLink = '';
    this.documentUpdated.emit();
    if (!this.multipleUpload) this.disableDocumentUpload = true;
  }

  /**
   * Reset document details based on index
   * @param index position to be modified
   */
  resetDocumentDetails(index: number) {
    this.documents.splice(index, 1);
    this.documentUpdated.emit();
    if (!this.multipleUpload) this.disableDocumentUpload = false;
  }

  /**
   * Download document
   * @param key S3 key of document
   * @param name name of document
   */
  downloadDocument(key: string, name?: string) {
    this.download.emit({ key, name });
  }

  /**
   * Get link
   * @param key S3 key of document
   */
  getLinkToOpen(key: string) {
    if (key != undefined) {
      return urlBeginsWithHttp.test(key) ? key : `//${key}`;
    }
    return '//';
  }

  /**
   * Open Link in new window
   * @param key S3 key of document
   */
  openLinkInNewWindow(key: string) {
    const url = this.getLinkToOpen(key);
    window.open(url);
  }
}
