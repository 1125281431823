import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StickyHeaderComponent } from './sticky-header.component';
import { ConpulseMaterialModule } from '../../material-module/index';

@NgModule({
  declarations: [StickyHeaderComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule
  ],
  exports: [StickyHeaderComponent],
})
export class StickyHeaderModule {}
