import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is to convert amount values from its minor units(Cents) to Units(Dollars)
 */
@Pipe({
  name: 'amountConversion'
})
export class AmountConversionPipe implements PipeTransform {

  transform(value: number, minorUnits: number): number {
    return value / Math.pow(10, minorUnits);
  }
}
