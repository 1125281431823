import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSelectComponent } from './filter-select.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [FilterSelectComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    I18NextModule
  ],
  exports: [FilterSelectComponent]
})
export class FilterSelectModule { }
