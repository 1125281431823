import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { DeleteConfirmationComponent } from './delete-confirmation.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ConpulseMaterialModule } from 'libs/conpulse/src/index';

@NgModule({
  declarations: [DeleteConfirmationComponent],
  imports: [CommonModule, ConpulseMaterialModule, I18NextModule],
  exports: [DeleteConfirmationComponent],
})
export class DeleteConfirmationModule {}
