import { FormControl } from '@angular/forms';
import {
  AdminSettingsDrawerType,
  CompanyType,
  DOCUMENT_TYPE,
  DynamicFieldDataTypes,
  MSAFieldType,
  NdaFieldName,
  NdaTabName,
  OnBoardingEditDrawerType,
  ProjectFastTracking,
  ServicedBusiness,
  SowFieldName,
  SowTabName,
  UploadType,
} from '../constants';

export interface ApiResponse {
  status: string;
  data: object | string;
}

export interface ApiError {
  error?: {
    message?: string;
  };
  message?: string;
}

export interface LoginParams {
  email?: string;
  password?: string;
  role?: string;
}

export interface ProfileDetails {
  countryCode?: string;
  firmName?: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  countryId: string;
  phone: string;
  address: string;
  city: string;
  email: string;
  role?: number;
  province: string;
  postalCode: number;
  phoneCode?: string;
  step?: number;
  isUpdate: boolean;
  progressCounter: number;
  id?: string;
  _id?: string;
  displayPhoneCode?: string;
  isoCode?: string;
  isClient?: boolean;
  companyId?: string;
  firmId?: string;
  fiscalYear?: {
    fiscalYearStartDate?: Date;
    startMonth: string;
    endMonth: string;
  };
  currencyId: any;
  currencyCode: string;
  isFreeTrial: boolean;
  trialStartAt: Date;
  picture?: string;
  isDeleteUserProfilePicture?: boolean;
  companyPicture?: string;
  isDeleteCompanyProfilePicture?: boolean;
  language?: any;
  companyLanguage?: any;
  cgnLimit: number;
  jobtitle?: string;
  createdAt?: Date;
  isEdited?: boolean;
  profileImage?: string;
  languageList?: any[];
  countries?: country[];
  isInternal?: boolean;
  libraryConfidentiality?: boolean;
  projectConfidentiality?: boolean;
  demandConfidentiality?: boolean;
}

export interface basicFormValues {
  loading: boolean;
}

export interface country {
  _id: string;
  name: string;
  isoCode: string;
  phoneCode: string;
  currencyCode: string;
  currencySymbol: string;
  region: object | string;
}

export interface language {
  name: string;
  _id: string;
  isoCode: string;
  type?: number;
  country: { name: string; isoCode: string; _id: string };
}

export interface fileSettings {
  format: string;
  _id: string;
  size: number;
}

export interface OnboardingTemplate {
  templateName: string;
  _id: string;
  languageId: string;
}

export interface Conversion {
  fromCurrency: string;
  _id: string;
  toCurrency: string;
  validity: Date;
  type?: number;
  conversionValue?: number;
  country?: { name: string; currencyCode: string; _id: string };
}
export interface industryStep3 {
  industries: object[];
  selectedIndustries: string[];
  isIndustryServed: boolean;
}

export interface RegisterFirmStep3_3 {
  isGeographyServed: boolean;
  geographyType: number;
}

export interface registerFirmStep3 {
  step: number;
  id: string; // Firm ID
  isUpdate: boolean;
  type?: number;
}

export interface capabilityStep3 {
  capabilities: object[];
  selectedCapabilities: string[];
  isCapabilityServed: boolean;
}

export interface CountryStateStep3 {
  CountryStates: object[];
  selectedCountryStates: string[];
  searchCountryStates: string[];
}

export interface regionStep3 {
  regions: object[];
  selectedRegions: string[];
}

export interface CustomRegionStep3 {
  selectedCustomRegions: string[];
}

export interface InviteUser {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  countryId?: string;
  phoneCode?: string;
  isoCode?: string;
}
export interface user {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryId: string;
  jobtitle?: string;
  role: number;
  phoneCode?: string;
  isoCode?: string;
  _id?: string;
  displayPhoneCode?: string;
  organisationTags: any;
  brand: any;
  isProfileCompleted?: boolean;
  firmId: string;
  companyId: string;
  inviteId?: string;
  language: string;
  isConsultingFirm?: boolean;
  companyName: string;
  messagePreferences: MessagePreferences;
  name?: string;
  picture?: string;
  blogCategory?: string[];
  companyLanguage: string;
  userId?: string;
  isFreeTrial?: string;
  legalEntityId?: string;
  roleId?: string;
  isVerified?: boolean;
  calender?: string;
  linkedEmail?: string;
}

export interface MessagePreferences {
  colleagues: boolean;
  clientOrFirms: boolean;
  weeklyRemainder: boolean;
}

export interface taskReminderPreferences {
  dailyReminder: boolean;
  weeklyReminder: boolean;
}

export interface UpdatePreferences {
  messagePreferences: MessagePreferences;
  taskReminderPreferences: taskReminderPreferences;
  isInternal?: boolean;
  calenderPreferences?: boolean;
}

export interface ChatContext {
  roomId: string;
  isWindowActive: boolean;
  isPrivate: boolean;
  fromUser: { _id: string; picture: string; name: string; onlineStatus: boolean };
  unreadCount: number;
  name?: string;
  projectName?: string;
}

export interface TeamList {
  displayedColumns: string[];
  page: number;
  limit: number;
  data?: any;
  totalCount?: number;
  sortBy?: string;
  endPage?: number;
  sortOrder?: string;
  pagesArray?: number[];
  isLoading?: boolean;
}

export interface EvolutionChart {
  evolutionchartTime: Array<string>;
  evolutionchartScore: Array<number>;
}

export interface Periods {
  periods: Array<string>;
}

export interface ClientSatisfactionList {
  topCapability: Array<object>;
  promotionDrivers: Array<object>;
  detractionDrivers: Array<object>;
  bottomCapability: Array<object>;
  topIndustry: Array<object>;
  bottomIndustry: Array<object>;
  topClientSatisfactionData: object;
  npsAvgScore: number;
  bottomClientSatisfactionData: object;
  strategicPoints: Array<object>;
  totalSurveys?: number;
}
export interface TableData {
  toggleValue: string;
  data: any;
  page?: number;
  totalCount?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  pagesArray?: number[];
  endPage?: number;
  displayedColumns: string[];
  search?: string;
  status?: boolean;
  contactStatus?: number;
  role?: number;
  selectedData?: string[];
  isLoading?: boolean;
  filter?: any;
  projectId?: string;
  rescheduleCount?: number;
  type?: string;
  clientOrFirms?: object[];
  subscribedUsers?: number;
  addedUsers?: number;
  disabledUsers?: number;
  activeUsers?: number;
  language?: string;
  languageId?: string;
  clientId?: string;
  firmId?: string;
  defaultLanguage: string;
  defaultLanguageId: string;
  parentId: string;
  userId?: string;
  currency?: string;
  isAdmin?: boolean;
  editDisabled?: boolean;
  // thirdDimensionName?: string;
  rfpTableType?: string;
  isInvitedFirm?: boolean;
  isSmallImg?: boolean;
  isLead?: boolean;
  isFooter?: boolean;
  isPmFooter?: boolean;
  total?: number;
  totalPercent?: number;
  customData?: object;
  isNoData?: boolean;
  isLinkedFirm?: boolean;
  contractStatus?: string;
  columns?: [];
  isFeeFooter?: boolean;
  companyCurrency?: number;
  amountInInvoiceCurrency?: number;
  totalInProjectCurrency?: number;
  currencyData?: Currency;
  organisationTerms?: { businessUnit: string };
  footerColumns?: string[][];
  emptyContent?: string;
  emptyButtonName: string;
  isArchived?: string;
  isFirmActive?: boolean;
  moduleId?: string;
  roleList?: { id: number; name: string }[];
  isEditable?: boolean;
  validateDate?: Date;
  isCpLegalEntity?: boolean;
  isValidationPending?: boolean;
  canEdit?: boolean;
  fasTrackTo: string;
}

export interface ProjectManagementChanges {
  invoicingChanges: object[];
  changes: object[];
}
export interface ProposalTableData {
  type: string;
  data: object[];
  header: object[];
  isFooter?: boolean;
  isLoading?: boolean;
  columnNames?: string[];
  totalFooterColumn?: string[];
  avgFooterColumn?: string[];
  medianFooterColumn?: string[];
  footer?: object;
  avgData?: object[];
  medianData?: object[];
}

export interface ProjectTableData {
  toggleValue: string;
  data: object[];
  page?: number;
  totalCount?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  pagesArray?: number[];
  endPage?: number;
  displayedColumns: string[];
  search?: string;
  status?: boolean;
  contactStatus?: number;
  role?: number;
  isInvitedFirm?: boolean;
  selectedData?: string[];
  isLoading?: boolean;
  filter?: ProjectFilter;
  projectId?: string;
  rescheduleCount?: number;
  type?: string;
  userId?: string;
  currency?: string;
  editDisabled?: boolean;
  organisationTerms?: OrganisationalAttributes;
  companyId?: string;
}

export interface InitiativeTableData {
  selectedData?: string[];
  toggleValue: string;
  data: object[];
  page?: number;
  totalCount?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  pagesArray?: number[];
  endPage?: number;
  displayedColumns: string[];
  search?: string;
  isLoading?: boolean;
  filter?: InitiativeFilter;
  _id?: string;
  quarter?: string;
  Year?: string;
  name?: string;
  leader?: string;
  confidentiality?: string;
  status?: 'Idea' | 'Demand' | 'Project' | 'Closed' | 'Rejected';
  estimatedBudget?: number;
  duration?: string;
  tileColor?: string;
  description?: string;
  businessUnit?: string;
  capabilities?: string;
  industries?: string;
  region?: string;
  strategicLever?: string;
}

export interface ActionData {
  data: object[];
  firmId?: string;
  page?: number;
  totalCount?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
  isLoading?: boolean;
  search?: string;
  displayedColumns?: string[];
  pagesArray?: number[];
  endPage?: number;
  filter?: ActionFilter;
  type?: string;
  actionNature?: object[];
  status?: object[] | number;
  userId?: string;
  isAdmin?: boolean;
  nature?: string;
  owner?: string | { _id: string; name: string; picture: string };
  dueDate?: Date | string;
  comment?: string;
  _id?: string;
  name?: string;
  description?: string;
  taskCategoryId?: string;
  categoryInfo?: object;
  isArchived?: boolean | number | string;
  isMyActions?: boolean;
  isNoData?: boolean;
}
export interface Actionview {
  name?: string;
  description?: string;
  comment?: string;
  _id?: string;
  dueDate?: Date | string;
  assignedOn?: Date | string;
  completedOn?: Date | string;
  owner?: object;
  assigner?: object;
  status?: number;
  nature?: number;
  categoryInfo?: TaskCategory;
  actionType?: boolean;
  isDeleted?: boolean;
  taskCategoryId?: string;
  createdAt?: Date | string;
}

export interface DashboardDateFilter {
  organisationTagId: OrganisationTags[];
  brandId: Brand[];
  startDate: string | Date;
  endDate: string | Date;
  isFilterType: boolean;
  startSelectField: number;
  revenueFilterType: number;
  projectName: string;
  industryId: industryListBasedOnFirm[];
  capabilityId: capabilityListBasedOnFirm[];
  consultingFirmId: ConsultingFirm[];
  offset: string;
  type: string;
}

export interface TaskCategory {
  _id: string;
  name: string;
  desc: string;
}

export interface TeamMemberDetails {
  delayDay?: {
    count?: number;
    averageDelay?: number;
  };
  memberId: string;
  memberName?: string;
  surveyPercentage?: {
    totalSurveySent?: number;
    successfulSurveyPercentage?: number;
  };
  avgScore?: number;
  projectCount?: number;
  performanceDetails?: {
    detractorsCount?: number;
    npsScore?: number;
  };
  adoptionDetails?: {
    lastActiveAt?: string;
    loggedInCount?: number;
  };
  dateDiff?: number;
  revenue?: number;
  organisationName?: string;
}

export interface client {
  _id: string;
  about: string;
  mainContact: KeyContact;
  geographyType: number;
  status: boolean;
  clientName: string;
  companyName: string;
  address: string;
  grossRevenue: any;
  partnerId: string;
  firmId: string;
  industry: any;
  capability: any;
  region: any;
  country: any;
  other: any;
  keyContact: KeyContact[];
  isUpdate: boolean;
  step: number;
  rejectedReason: string;
  currencyCode?: string;
  currencyId: string;
  isSame: boolean;
  isApprove: boolean;
  isFreeTrial?: boolean;
  trialStartAt?: Date;
}

export interface ProjectRoi {
  expectedImpact: {
    comment: string;
    header: any[];
    startYear: number;
    table: any[];
  };
  qualitativeImpact: string;
  roi: {
    comment: string;
  };
  roiCalculation: number;
}

export interface project {
  taxonomyMandatoryFields?: taxonomyMandatoryFields;
  industryChildList: any[];
  _id?: string;
  name?: string;
  title?: string;
  type?: number;
  tier?: string;
  strategicLever?: string;
  clientId?: string;
  grossRevenue: any;
  budget: string | number;
  description: string;
  mandate: string;
  impact: string;
  startDate: Date | string;
  endDate: Date | string;
  isPaid?: boolean;
  fromProjectView?: boolean;
  isVisibleInConavigo?: boolean;
  // Step 2
  geographyType: number;
  industry: any;
  capability: any;
  region: any;
  state: any;
  other: any;
  country: any;
  regionList: any;
  organisationTags: any;
  brand: any;
  businessUnit: any;
  businessUnits: any;
  // Step 3
  mainContact: any;
  keyContact: any;
  surveyContact: any;
  projectIncharge: string | object;
  projectSponser: string | object;
  partnerInCharge: string;
  // Common
  isUpdate: boolean;
  step: number;
  status: boolean;
  firmId: string;
  currencyCode?: string;
  currencyId: any;
  isSurveySent: boolean;
  approver?: {
    _id?: string;
    status?: number;
    comment?: string;
    name?: string;
    approverId?: {
      _id?: string;
      name?: string;
    };
  }[];
  isDrafted: boolean;
  isFileUpload?: boolean;
  driveLink?: string;
  file?: SelectedContractDocuments[];
  fileKey?: string;
  originalFileName?: string;
  rejectReason?: string;
  projectRequestId?: string | object;
  createdBy?: string | object;
  createdAt?: string | Date;
  consultingScore?: number | null;
  team?: Team[];
  approvalWorkFlow?: {
    title?: string;
  };
  isRejected?: boolean;
  requestedBy?: {
    _id?: string;
    name?: string;
    companyId?: string;
  };
  projectDetails: project;
  performanceScoreCardVisible?: boolean;
  procurementLeader?: string | object;
  fastTrackTo?: ProjectFastTracking;
  projectStep: number;
  referenceConversionId?: string;
  projectBudget?: number;
  legalEntityId?: string;
  languageId?: string;
  templateName?: string;
  templateId?: string;
  demandLanguageId?: string;
  isInvitationSent?: boolean;
  isWon?: boolean;
  isRestricted?: boolean;
  isPerformanceTeam?: boolean;
  isPmTeam?: boolean;
  isSowTeam?: boolean;
  isRfpTeam?: boolean;
  isDemandTeam?: boolean;
  projectRole?: string;
  isTeamMember?: boolean;
  isContributor?: boolean;
  isSelectionTeam?: boolean;
  isCompleted?: boolean;
  isSowSigned?: boolean;
}

export interface Initiative {
  _id?: string;
  quarter?: string;
  Year?: string;
  name?: string;
  leader?: string | { _id: string, name: string, picture: string };
  confidentiality?: string | boolean;
  status?: 'IDEA' | 'DEMAND' | 'PROJECT' | 'CLOSED' | 'REJECTED';
  estimatedBudget?: number;
  duration?: number;
  tileColor?: string;
  description?: string;
  businessUnit?: string | { _id: string, name: string };
  capabilities?: [];
  editCapabilities?: [];
  subCapabilities?: [];
  industries?: [];
  editIndustries?: [];
  subIndustries?: [];
  regions?: [];
  editRegions?: [];
  countries?: [];
  editCountries?: [];
  strategicLever?: [];
}


export interface demandApprover {
  comment: string;
  status: number;
  _id: string;
  companyId: string;
  approverId: string;
  name: string;
}

export interface projectReq {
  industryChildList: any[];
  _id?: string;
  name?: string;
  title?: string;
  type?: number;
  tier?: string;
  clientId?: string;
  grossRevenue: any;
  budget: string;
  description: string;
  mandate: string;
  impact: string;
  startDate: Date;
  endDate: Date;
  isPaid?: boolean;
  fromProjectView?: boolean;
  isVisibleInConavigo?: boolean;
  // Step 2
  geographyType: number;
  industry: any;
  capability: any;
  region: any;
  state: any;
  other: any;
  country: any;
  regionList: any;
  organisationTags: any;
  brand: any;
  businessUnit: any;
  // Step 3
  mainContact: any;
  keyContact: any;
  surveyContact: any;
  projectIncharge: string | object;
  partnerInCharge: string;
  // Common
  isUpdate: boolean;
  step: number;
  status: boolean;
  firmId: string;
  currencyCode?: string;
  currencyId: string;
  isSurveySent: boolean;
  approver?: {
    _id?: string;
    status?: number;
    comment?: string;
    name?: string;
    approverId?: {
      _id?: string;
      name?: string;
    };
  }[];
  isDrafted: boolean;
  isFileUpload?: boolean;
  driveLink?: string;
  file?: File;
  fileKey?: string;
  originalFileName?: string;
  rejectReason?: string;
  projectRequestId?: string | object;
  createdBy?: string | object;
  createdAt?: string | Date;
  consultingScore?: number | null;
  team?: Team[];
  approvalWorkFlow?: {
    title?: string;
  };
  isRejected?: boolean;
  requestedBy?: {
    _id?: string;
    name?: string;
    companyId?: string;
  };
  projectDetails: project;
  performanceScoreCardVisible?: boolean;
  procurementLeader?: string | object;
  fastTrackTo?: ProjectFastTracking;
  projectStep: number;
  referenceConversionId?: string;
  projectBudget?: number;
  legalEntityId?: string;
  languageId?: string;
  templateName?: string;
  templateId?: string;
  demandLanguageId?: string;
}

export interface KeyContact {
  _id?: string;
  contactId: {
    name?: string;
    email?: string;
  };
}

export interface ClientTeam {
  _id: string;
  memberId: string;
  role: number;
  member: {
    _id: string;
    name: string;
  };
}

export interface ProjectKeyInformation {
  _id: string;
  projectId: string;
  firmId: {
    _id: string;
    firmName: string;
  };
  projectrequest: {
    _id: string;
    budget: {
      _id: string;
      budget: Number;
    };
  };
  sow: {
    _id: string;
    projectDescription: {
      _id: string;
      projectEndDate: string;
      projectStartDate: string;
    };
  };
  clientTeams?: ClientTeam[];
  firmTeams?: ClientTeam[];
}

export interface surveyContact {
  name: string;
  email: string;
  phoneCode: string;
  countryId: string;
  phone: string;
  isoCode: string;
}

export interface partnerList {
  picture?: string;
  role?: string;
  _id?: string;
  name?: string;
  email?: string;
  disabled?: boolean;
  companyId?: string;
}

export interface colorList {
  color: string;
  _id: string;
  name: string;
}

export interface industryListBasedOnFirm {
  _id: string;
  name: string;
  parent?: string;
  children?: ChildIndustry[];
}

export interface ChildIndustry {
  _id: string;
  parentName: string;
}

export interface capabilityListBasedOnFirm {
  _id: string;
  name: string;
  parent?: string;
  children: ChildIndustry[];
}

export interface otherListBasedOnFirm {
  _id: string;
  name: string;
}

export interface regionListBasedOnFirm {
  _id: string;
  name: string;
}

export interface countryListBasedOnFirm {
  _id: string;
  name: string;
  region: string | { _id: string; name: string };
}

export interface cityBasedOnCountry {
  _id: string;
  name: string;
  country?: string | { _id: string; name: string };
}

export interface LanguageListItem {
  _id: string;
  name: string;
}

export interface organisationTagsListBasedOnFirm {
  _id: string;
  name: string;
}

export interface OrganisationalUnit {
  _id: string;
  companyId: string;
  name: string;
  brand: string;
  // segment: string;
  functions: string[];
  costCenter: string;
  // thirdDimension: string;
  manager: string;
}

export interface brandsListBasedOnFirm {
  _id: string;
  name: string;
}

export interface CostCenterList {
  _id: string;
  name: string;
}
export interface FunctionList {
  _id: string;
  name: string;
}

export interface tiersListBasedOnFirm {
  _id: string;
  name: string;
}

export interface clients {
  _id: string;
  companyName: string;
}

export interface List {
  partnerList: partnerList[];
  filterList?: partnerList[];
  industryList: industryListBasedOnFirm[];
  countryList: CountryStateStep3[] | country[];
  capabilityList: capabilityListBasedOnFirm[];
  otherList: otherListBasedOnFirm[];
  regionList: regionListBasedOnFirm[];
  isGeographyServed: boolean;
  isIndustryServed: boolean;
  isCapabilityServed: boolean;
  geographyType: number;
  consultingFirm?: ConsultingFirm[];
  clients: { companyName: string, _id: string }[];
  tiersList: tiersListBasedOnFirm[];
  segmentList: tiersListBasedOnFirm[];
  city?: cityBasedOnCountry[];
}

export interface ActionLists {
  owner?: Object[];
  createdBy?: Object[];
  nature?: Object[];
  status?: Object[];
  category?: Object[];
}

export interface ClientFilter {
  industry: string[];
  partner: string[];
  capability: string[];
  region: string[];
  country: string[];
  other: string[];
}

export interface UserFilter {
  role: number[];
  status: number[];
  organisationTag: OrganisationTags[];
  brand: Brand[];
  costCenter?: [];
  functions?: [];
  // Removed Segments and 3rd Dimensions
  // segments?: [];
  // thirdDimensions?: [];
}

export interface List {
  partnerList: partnerList[];
  clients: clients[];
}

export interface ProjectFilter {
  partner: partnerList[];
  clients?: clients[];
  organisationTag: OrganisationTags[];
  brand: Brand[];
  startStartDate: string | Date;
  startEndDate: string | Date;
  startSelectField: string | number;
  endSelectField: string | number;
  endStartDate: string | Date;
  endEndDate: string | Date;
  consultingFirm?: ConsultingFirm[];
  requestStartDate?: string | Date;
  requestEndDate?: string | Date;
  reqSelectField?: string | number;
  requestStatus?: number;
  minBudget?: number;
  maxBudget?: number;
  industry?: industryListBasedOnFirm[];
  region?: regionListBasedOnFirm[];
  country?: regionListBasedOnFirm[];
  capability?: capabilityListBasedOnFirm[];
  step: ProjectSteps[] | String[];
  marketplace: string;
}

export interface InitiativeFilter {
  partner: partnerList[];
  brand: Brand[];
  industries?: industryListBasedOnFirm[];
  subIndustries?: [];
  region?: regionListBasedOnFirm[];
  country?: regionListBasedOnFirm[];
  capability?: capabilityListBasedOnFirm[];
  subCapabilities?: [];
  color?: [];
  quarter?: string;
  strategicLever?: [];
  status?: 'Idea' | 'Demand' | 'Project' | 'Closed' | 'Rejected';
  confidentiality?: 'Restricted' | 'Public';
  drawerstatus?: boolean;
}

interface ProjectSteps {
  step: number;
  name: string;
}

export interface ActionFilter {
  status?: number | string;
  nature?: number[];
  dueDateStart?: Date | string;
  dueDateEnd?: Date | string;
  dueSelectField?: number;
  assignedDateStart?: Date | string;
  assignedDateEnd?: Date | string;
  assignedSelectField?: number;
  owner?: string[];
  createdBy?: string[];
  isArchived?: boolean;
  category?: string[];
}

export interface DateFilter {
  Begin: Date;
  End: Date;
}

export interface NotificationDetails {
  totalCount: number;
  unReadCount: number;
  notification: Array<{
    isRead: boolean;
    _id: string;
    userId: any;
    page: string;
    data: string;
    createdBy: object;
    message: string;
    type: string;
    createdAt: Date;
  }>;
  page: number;
  limit: number;
  startDate: string;
  endDate: string;
}

export interface Count {
  userCount?: number;
  clientCount: number;
  projectCount: number;
  firmCount: number;
  firmIntegratedCount: number;
}

export interface SurveyQuestion {
  _id: string;
  options: any;
  parent: string;
  previousSibling: string;
  nextSibling: string;
  question: string;
  questionId: string;
  questionType: number;
  subQuestion: SubQuestion[];
  surveyId: string;
  isRequired: boolean;
  minValue: number;
  dimension: number;
  children: SurveyQuestion[];
  isOther: boolean;
  isOtherAnswer: string;
  answer: string;
}

export interface SubQuestion {
  dimension?: string;
  _id?: string;
  question?: string;
  AvgScore?: number;
}

export interface OrganisationTags {
  organisationTagsId: string[];
}

export interface Brand {
  brand: string[];
}
// Removed Segments in v/1.30
// export interface Segment {
//   segments: string[];
// }

export interface Tiers {
  tierId: string[];
}

export interface DashboardData {
  totalProjects: number;
  totalRevenue: number;
  score: Score;
  capablities: Array<Trending>;
  industries: Array<Trending>;
  keyPromoters: Array<string>;
  keyDetractors: Array<string>;
  strength: Array<string>;
  improvements: Array<string>;
}

export interface DashboardDetails {
  allDataOfDashboard: object;
  trendingCapability: object;
  trendingIndustry: object;
  detractors: Array<object>;
  graphValues: Array<object>;
  promoters: Array<object>;
  topStrength: Array<string>;
  weakness: Array<string>;
  promoterDrivers: Array<string>;
  detractorDrivers: Array<string>;
  totalRevenue: number;
  overAllScore: number;
  projectCount: number;
  demandsCreatedCount: number;
  demandsValidatedCount: number;
  sowSignedCount: number;
}

export interface ProgressDate {
  actions: Array<Object>;
  contracts: Array<Object>;
  projects: Array<Object>;
}

export interface Trending {
  name: string;
  isTrending: boolean;
}

export interface Score {
  totalOverall: number;
  totalD1: number;
  totalD2: number;
  totalD3: number;
  totalD4: number;
  totalD5: number;
  min: number;
  max: number;
}

export interface ScoreCard {
  strengths: string[];
  weakness: string[];
  bestQuestion: string[];
  worstQuestion: string[];
  dimensionGraph: [];
  overAllScore: number;
  projectName: string;
  projectDescription: string;
  mandate: string;
  impact: string;
  projectEndDate: Date;
  clientName?: string;
  npsScore: number;
  priority: [];
  performance: [];
  isNegativeValue: boolean;
  firmName?: string;
  capabilities: [];
  industries: [];
  clientIndustries: [];
  surveyScores?: [];
  regions: [];
  countries: [];
  sharingInConavigo: boolean;
  conavigoConfidentialityLevel: number;
  conavigoConfidentialityLevelText: string;
  contacts: object[];
}

export interface ConsultingScore {
  distributionGraph: [];
  distributionGraphWithFilter: [];
  evolutionGraph: [];
  topProjects: [];
  bottomProjects: [];
}

export interface ScoreChartData {
  scoreGraphData: [];
  revenueScoreData: [];
}

export interface ProjectPerformance {
  priority: [];
  performance: [];
}

export interface FirmOrClientRoles {
  id?: number;
  name?: string;
}

export interface FirmOrClientDetails {
  firm?: ProfileDetails;
  isClient?: boolean;
  user?: user;
  status?: boolean;
}

export interface ConsultingFirmFilter {
  industry: string[];
  partner: string[];
  capability: string[];
  region: string[];
  state: string[];
  other: string[];
  segment: string[];
  tier: string[];
}

export interface providerPerformanceFilter {
  providerName: ConsultingFirm[];
  startDate: string;
  endDate: string;
  globalRisk: string;
  selectedField: number;
  relationshipManager?: { _id: string; name: string }[];
}

export interface ClientsListFilter {
  industry: string[];
  capability: string[];
  region: string[];
  country: string[];
  other: string[];
  companySize: string;
  improveoDiversity?: string[];
  improveoCommunityImpact?: number[];
  city?: string[];
}

export interface ConsultingFirm {
  _id: string;
  about: string;
  tier?: string;
  website: string;
  mainContact: KeyContact;
  geographyType: number;
  status: boolean;
  clientName: string;
  firmName: string;
  address: string;
  grossRevenue: any;
  partnerId: string;
  firmId: string;
  industry: any;
  region: any;
  state: any;
  country: any;
  other: any;
  keyContact: KeyContact[];
  isUpdate: boolean;
  step: number;
  rejectedReason: string;
  currencyCode?: string;
  currencyId: string;
  isSame: boolean;
  isApprove: boolean;
  isFreeTrial?: boolean;
  trialStartAt?: Date;
  companyInfo?: companyProfileDetails;
  additionalInfo?: additionalInfoDetails;
  capability?: CapabilityInfo[];
  servicedBusinesses: { servicedBusiness: ServicedBusinesses; percent: number }[];
  profilePhoto?: string;
  coverPhoto?: string;
  aboutSection?: {
    title: string;
    description: string;
  };
  referenceId?: string;
  isLinked: boolean;

  // new implementation fields
  firmPartnerFirstName: string;
  firmPartnerLastName: string;
  firmPartnerEmail: string;
  languageId?: string;
  language?: language;
}

export interface CapabilityInfo {
  capabilityId: { _id: number; name: string };
  percent: number;
}

export interface IndustryInfo {
  industryId: { _id: number; name: string };
  percent: number;
}

export interface ServicedBusinesses {
  servicedBusinesses: { servicedBusiness: ServicedBusiness; percent: number }[];
}
export interface ServicedBusinessData {
  servicedBusiness: string;
  percent: number;
}
export interface additionalInfoDetails {
  establishedYear: string;
  companyType: string;
  industry: { _id: number; name: string };
  diversity: string;
  acquiredDetails: AcquiredDetails[];
  partOf: {
    company: { _id?: string; name: string; profilePhoto?: string; type?: string };
    customCompany?: { _id?: string; name: string; profilePhoto?: string; type?: string };
  };
}
export interface AcquiredDetails {
  acquiredBy: {
    _id?: string;
    name: string;
    profilePhoto?: string;
    type?: string;
  };
  acquiredOn: Date | null;
  customAcquiredBy?: {
    _id?: string;
    name: string;
    profilePhoto?: string;
    type?: string;
  };
}
export interface Firms {
  isActive: boolean;
  _id: string;
  firmName: string;
}

export interface DimensionData {
  behavior: number;
  commercial: number;
  delivery: number;
  impact: number;
  overAllScore: number;
  projectName: Array<string>;
  talentExpertise: number;
}

export interface DimensionValues {
  data: Array<number>;
  label: string;
  borderWidth: number;
  pointRadius: number;
  pointHoverRadius: number;
  pointHoverBorderWidth: number;
}

export interface BubbleChartValues {
  data: Array<object>;
  label: string;
  backgroundColor: string;
  borderColor: string;
}

export interface ConsultingSpendValues {
  projects: number;
  providers: number;
  totalSpend: number;
  spendByCapabilitiesGraph: [];
  spendByEvolutionGraph: [];
  spendByOrganisationTagGraph: [];
}
export interface PerformanceFilter {
  projectName: string;
  organisationTag: [];
  capability: [];
  isPaid?: number;
  brand: [];
  industry: [];
  partner: [];
  client?: [];
  //for consource
  consultingFirm?: [];
  segment?: [];
  functions?: [];
  regions?: [];
  // thirdDimension: [];
  costCenter?: [];
  marketplace?: string;
}

export interface SpendChartsFilters {
  projectName: string;
  organisationTag: [];
  capability: [];
  brand: [];
  industry: [];
  consultingFirm?: [];
  regions?: [];
  startDate: string;
  endDate: string;
  offset: string;
}

export interface SpendByOrganisationTagGraph {
  capabilityName: string;
  revenue: number;
}

export interface SpendByBusinessUnitGraph {
  capabilityName: string;
  revenue: number;
}

export interface SpendByRegionGraph {
  regionName: string;
  revenue: number;
}

export interface SpendEvolutionGraph {
  Date: string;
  paid: number;
  notPaid: number;
  realised?: number;
  revenue?: string;
}

export interface ConsultingPanelValues {
  AvgBudgetPerProvider: number;
  AvgLengthOfProject: number;
  AvgProjectPerProvider: number;
  CompetitionOrganised: number;
  ReferenceChecked: number;
  averageCostByProviders: ProvidersValue[];
  spendByProviders: ProvidersValue[];
}

export interface ProvidersValue {
  firmName: string;
  revenue?: number;
  paymentTerm?: number;
}

export interface HighLowPerformerValue {
  ScoreGraph: ScoreGraph[];
  performanceGraph: PerformanceEvolutionGraph[];
  flagData: FlagDataValue[];
}

export interface SpendAnalysisPanelViewData {
  ScoreGraph: ScoreGraph[];
  performanceGraph: PerformanceEvolutionGraph[];
  flagData: FlagDataValue[];
  averageCostByProviders: ProvidersValue[];
  averageDailyCostByProviders: ProvidersValue[];
  averageWeeklyCostByProviders: ProvidersValue[];
  averagePaymentTermByProviders: ProvidersValue[];
}

export interface FlagDataValue {
  name?: string;
  description?: string;
  _id: string;
  surveySentDate?: string;
  isSurveyAnswered?: number;
  incomplete?: number;
  dateDifference?: number;
  behavior?: number;
  commercial?: number;
  delivery?: number;
  impact?: number;
  npsScore?: number;
  overAllScore?: number;
  roi?: number;
  talentExpertise?: number;
  result?: boolean;
  others?: boolean;
  incompleteProject?: boolean;
  surveyNotAnswered?: boolean;
  isRescheduled?: boolean;
}

export interface ScoreGraph {
  firmName: string;
  overAllScore: number;
}

export interface VariabilityGraph {
  name: string;
  avgValue: number;
  maxValue: number;
  minValue: number;
}

export interface PerformanceEvolutionGraph {
  firmName: string;
  score: number;
  name?: string;
}

export interface AdoptionDetails {
  activeManager: number;
  activeUser: number;
  averageProjectPerUser: number;
}

export interface DashboardDetails {
  UsersCreated: number;
  UsersDisabledCount: number;
  avgScore: number;
  capabilityCount: number;
  clientCount: number;
  industryCount: number;
  organisationTagCount: number;
  percentageOfSurveyCompleted: number;
  projectCount: number;
  projectId: [{ _id: string }];
  surveySent: number;
  totalBudget: number;
  userCount: number;
  firmCount: number;
  partnerCount?: number;
}

export interface PerformanceDetails {
  averageTimeToSendSurvey: number;
  bouncedCount: number;
  capabilityUsed: number;
  clientDescription?: string;
  companyName?: string;
  incompleteProjectCount: number;
  industryUsed: number;
  firmName?: string;
  firmDescription: string;
}

export interface ClientManagerDashboardData {
  StrategicPoints: PerformanceEvolutionGraph[];
  bottomProviders: ScoreGraph[];
  graphValues: DimensionData[];
  overAllScore: number;
  projects: number;
  spend: number;
  spendRegion: SpendByRegionGraph[];
  spendTrend: SpendEvolutionGraph[];
  topCapability: TrendingConsultingFirms[];
  topProviders: ScoreGraph[];
  topTrendingConsultingFirms: TrendingConsultingFirms[];
  topTrendingSpender: TrendingSpender[];
  topUsers: industryListBasedOnFirm[];
}

export interface TrendingConsultingFirms {
  isNegative: boolean;
  count: number;
  name: string;
}

export interface Currency {
  _id?: string;
  isDeleted?: boolean;
  clientId?: string;
  currency?: string;
  equivalentCompanyCurrency?: object | number;
  validity?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  conversionCurrencyId?: string;
  currencyDetails?: {
    _id?: string;
    name?: string;
    code?: string;
    symbol?: string;
  };
}

export interface ProjectCurrency {
  _id?: string;
  name?: string;
  code?: string;
  symbol?: string;
  referenceConversionId: string;
  currencyId: string;
}

export interface TrendingSpender {
  name: string;
  topRevenuePast: number;
  topRevenuePresent: number;
  _id: string;
}

export interface FirmDetails {
  firmName?: string;
  clientName?: string; // For client
  firstName?: string;
  lastName?: string;
  currencyId?: any;
  address?: string;
  city?: string;
  email?: string;
  province?: string;
  postalCode?: number;
  _id?: string;
}

export interface BusinessOverview {
  spendRegion: SpendByRegionGraph[];
  spendTrend: SpendEvolutionGraph[];
  totalRevenue: number;
  projects: number;
  clientCount: number;
  regionsCount: number;
  capabilityCount: number;
  projectsPerUser: number;
  AvgLengthOfProject: number;
  turnoverPerPartner: number;
  averageRevenueOfProjects: number;
}

export interface EmailTemplateData {
  _id: string;
  templateName: string;
  subject: string;
  language: string;
  htmlContent: string;
  isDeleted: boolean;
  updatedAt: string;
  updatedBy: string;
  customHtml: boolean;
  templateId: string;
}

export interface NotificationTemplateData {
  _id: string;
  templateName: string;
  templateId: string;
  language: string;
  notificationContent: string;
  navigateFromNotificationButtonText?: string;
  notificationType: string;
  iconName?: string;
  updatedAt?: Date;
  updatedBy?: string;
  categoryId?: string;
}

export interface FirmContactData {
  _id?: string;
  name: string;
  type?: number;
  email: string;
  language: string;
  jobTitle: string;
  isMainContact: boolean;
}

export interface SurveyQuestionList {
  question: string;
  languageId: string;
  languageName: string;
}

export interface SurveyLocalizationData {
  languageId: string;
  question: string;
  options: string[];
  subQuestion: SubQuestion[];
  otherLabel: string;
  otherText: string;
}

export interface SurveyQuestionData {
  _id: string;
  languageId: string;
  question: string;
  options: string[];
  questionType: number;
  subQuestion: SubQuestion[];
  localization: SurveyLocalizationData;
  otherLabel: string;
  otherText: string;
}

export interface SandboxDataSocketMessage {
  message: string;
  progress: number;
  stepsCompleted: number;
}

export interface workflowData {
  approvers: {
    _id: string;
    name: string;
  };
  conditions: {
    budget?: object;
    businessUnits?: [];
    capabilities?: [];
    country?: [];
    functions?: [];
    industries?: [];
    organisationTags?: [];
    region?: [];
    costCenter?: [];
  };
  _id: string;
  title: string;
  description: string;
  isAutoApprove: boolean;
  previousSibling: string;
  nextSibling: string;
  updatedBy: any;
  createdBy: any;
  updatedAt: Date;
  createdAt: Date;
  index?: number;
  organizationTerms?: OrganisationalAttributes;
}
export interface AccordionData {
  data: workflowData[];
  isLoading: boolean;
  currency: string;
  moduleId: string;
}

export interface SelectionData {
  data: ProposalCriteriaAndEvaluator[];
  isLoading: boolean;
  isRfpLead: boolean;
  isFreezed: boolean;
  footerColumns: string[][];
  isFooter: boolean;
}

export interface MileStoneData {
  data: milestone[];
  isLoading: boolean;
  isRfpLead: boolean;
  isFreezed: boolean;
}

export interface milestone {
  _id?: string;
  name?: string;
  date: Date;
  time?: Date;
  timeString?: string;
  session?: string;
}

export interface WorkflowDetails {
  title: string;
  description: string;
  budgetMin: number;
  budgetMax: number;
  budgetSymbol: string;
  industry: [];
  region: [];
  country: [];
  capability: [];
  approvers: [];
  organisationTags: [];
  id?: string;
  businessUnitTags: [];
  costCenter: [];
  functions: [];
}

export interface AccordianDrop {
  toMove: string;
  existingInplace: string;
  position: number;
  direction: number;
}

/* ConSource - Spend Analysis */
export interface SpendAnalysisCompanyViewData {
  spendByEvolutionGraph: [];
  topSectionCards: SpendAnalysisTopSectionCards;
}

export interface SpendAnalysisTopSectionCards {
  totalSpend: string | number;
  providers: string | number;
  projects: string | number;
  averageScore: string | number;
  nps: string | number;
}

export interface RfpSelectionCriteria {
  selectionCriterionId?: MilestoneOrSelectionCriterion;
  weight?: number;
  name?: string;
  index?: number;
  isTechnical?: boolean;
}

export interface CustomRfpSelectionCriteria {
  name?: string;
  weight?: number;
  index?: number;
}

export interface RfpValue {
  value: string;
  isActive: boolean;
}
export interface RfpInformation {
  _id?: string;
  projectId: string;
  // Overview
  projectOverview: RfpValue;
  // Background
  companyOverview: RfpValue;
  level2Overview: RfpValue;
  strategicAmbition: RfpValue;
  // Context
  rationale: RfpValue;
  whatHasBeenDone: RfpValue;
  supportRequired: RfpValue;
  keyQuestions: RfpValue;
  expectedBenefits: RfpValue;
  // Scope & Deliverables
  objectives: RfpValue;
  scope: RfpValue;
  expectedDeliverables: RfpValue;
  // Schedule & Budget
  projectExpectedStartDate: Date;
  projectExpectedEndDate: Date;
  projectExpectedDuration: string;
  projectTargetBudget: number;
  isProjectTargetBudgetActive?: boolean;
  isProjectExpectedDurationActive?: boolean;
  // Other Requirements
  languageId: string | { _id: string; name: string };
  language: RfpValue;
  teamSetup: RfpValue;
  location: RfpValue;
  other: RfpValue;
  // RFP Process
  milestones: {
    milestoneId: MilestoneOrSelectionCriterion;
    date: Date;
    time?: Date;
    timeString?: string;
    session?: string;
    isInvalidTime?: boolean;
  }[];
  eligibilityAndQualifications: RfpValue;
  expectedProposalContent: RfpValue;
  referenceAndExpertise: RfpValue;
  feesAndWorkload: RfpValue;
  selectionCriteria: RfpSelectionCriteria[];
  customCriteria?: CustomRfpSelectionCriteria[];
  questionsAndAnswers: RfpValue;
  otherQuestions: RfpValue;
  keyContacts: string;
  // Associated Documents
  associatedDocuments: {
    fileName?: string;
    fileSize?: number;
    fileKey: string;
    shouldUpload?: boolean;
    file?: File;
    type?: number;
  }[];
  driveLinks: string[];
  exportHistory: {
    fileKey: string;
    exportedAt: Date;
    exportedBy: Object;
  }[];
  team?: Team[];
  layoutId: string;
  layoutName: string;
  layout: LayoutData;
  templateLibraryId?: string;
  templateLibraryName?: string;
  template?: object;
  isFreezed?: boolean;
  allSelectionCriteriaList?: RfpSelectionCriteria[];
  allCommercialCriteria?: RfpSelectionCriteria[];
  allTechnicalCriteria?: RfpSelectionCriteria[];
  companyId?: {
    companyName: 1;
    companyPicture: 1;
  };
  demandBudget?: number;
  isRfpTeam?: boolean;
  isSelectionCriteriaActive?: boolean;
}

export interface Language {
  _id: string;
  name: string;
}

export interface MilestoneOrSelectionCriterion {
  _id: string;
  name: string;
  isDeleted?: boolean;
  isMandatory?: boolean;
  isDefault?: boolean;
}

export type SpendProfileGraphMode = 'top-20' | 'bottom-20' | 'all';

export interface TargetProfileMarkerData {
  industry: industryListBasedOnFirm[];
  capability: capabilityListBasedOnFirm[];
  region: regionListBasedOnFirm[];
  country: countryListBasedOnFirm[];
  city?: cityBasedOnCountry[];
  filteredCountry: countryListBasedOnFirm[];
  languages: LanguageListItem[];
  companySize: string[];
  diversity: string[];
  companyOwnership: Record<string, string>;
  consultingFirmTiers: tiersListBasedOnFirm[];
  providerLists: tiersListBasedOnFirm[];
}

export interface SourcingTargetProfile {
  projectId: string;
  // Expertise & Impact
  industry: string[];
  capability: string[];
  // Ability to Deliver
  region: string[];
  country: string[];
  // Firm Specificities
  companySizes: string[];
  diversity: string[];
  companyOwnership: string;
  tiers: string[];
  // Other markers
  otherMarkers: string[];
  providerLists: string[];

  city?: string[];
}

export interface SourcingSelection {
  firmId?: null | {
    _id: string;
    firmName: string;
    partners: FirmContactData[];
  };
  contractId?: string;
  firmComment?: string;
  firmActionDate?: Date;
  isRfpVisible?: boolean;
  isShortListed?: boolean;
  partnerComment?: string;
  matchingPercent?: number;
  invitationStatus?: number;
  invitationSentOn?: Date[];
  ndaId?: string;
  ndaFileKey?: string;
  ndaFileName?: string;
  partner?: FirmContactData;
  clientId?: string;
  providerId?: string;
}

export interface SourcingConfidentiality {
  ndaRequired: boolean;
  conflictRequired: boolean;
  includedElements: {
    projectOverview: boolean;
    projectCapability: boolean;
    projectIndustry: boolean;
    projectLocation: boolean;
    projectDates: boolean;
  };
}

export interface Sourcing {
  _id?: string;
  targetProfile?: SourcingTargetProfile;
  selection?: SourcingSelection;
}
export interface VisibliltyLevel {
  next: number;
  level: number;
  isReject: boolean;
}

export interface SourcingMatchProfile {
  matched?: number;
  firmName?: string;
  profilePhoto?: string;
  totalLength?: number;
  matchingPercent?: number;
  region?: { matchingPercent?: number; values?: { name?: string; matched?: boolean } };
  country?: { matchingPercent?: number; values?: { name?: string; matched?: boolean } };
  industry?: { matchingPercent?: number; values?: { name?: string; matched?: boolean; isParent?: boolean }[] };
  capability?: { matchingPercent?: number; values?: { name?: string; matched?: boolean; isParent?: boolean }[] };
  city?: { matchingPercent?: number; values?: { name?: string; matched?: boolean; isParent?: boolean }[] };
  tier?: { matchingPercent?: number; values?: { name?: string; matched?: boolean } };
}

export interface InvitedProjectUserResponse {
  firmId: string;
  projectId: string;
  partnerId: string;
  isAccepted: boolean;
  isConflict: boolean;
}

export interface ProjectQuestion {
  _id?: string;
  firmId?: string;
  createdAt?: Date;
  projectId?: string;
  visibility?: number;
  createdBy?: string | user;
  clientId?: string;
  question?: string;
  answer?: {
    answer: string;
    answerby?: {
      _id?: string;
      name?: string;
    };
    answerOn?: Date;
    isRecent?: boolean;
    isDeleted?: boolean;
  };
  isExpanded?: boolean;
}

export interface ProposalCriteriaAndEvaluator {
  _id?: string;
  name?: string;
  weight?: number;
  isDeleted?: boolean;
  isDefault?: boolean;
  isFromRfp?: boolean;
  isMandatory?: boolean;
  index?: number;
}
export interface ProposalMyScore {
  _id: string;
  firmId: Firms;
  weight: number;
  updatedAt: Date;
  createdAt: Date;
  createdBy: string;
  projectId: string;
  companyId: string;
  comment: string | null;
  scoreVisibility?: boolean,
  scoring: {
    selectionCriterionId: ProposalCriteriaAndEvaluator;
    weight: number;
    isFromRfp: boolean;
    _id: string;
  }[];
}
export interface ProposalFeedBack {
  firmDetail: Firms;
  feedBack: {
    generalComment: string;
    bestPerformance: string;
    leastPerformance: string;
  };
  bestCriteria: ProposalCriteriaAndEvaluator[];
  worstCriteria: ProposalCriteriaAndEvaluator[];
}

export interface Team {
  memberId: string & { _id: string; name: string };
  role: number;
}

export interface SowDocument {
  fileName: string;
  fileSize: number;
  fileKey: string;
  file?: File;
  uploadedOn?: Date;
  uploadStep?: number;
}
export interface Sow {
  legalEntityPanelStatus: boolean;
  providerPanelActiveStatus: boolean;
  _id: string;
  firmId: Firms;
  firmTeam: Team[];
  projectId: string;
  companyId: string;
  clientTeam: Team[];
  legalEntityId?: LegalEntity;
  demandBudget?: number;
  rfpBudget?: number;
  msaId?: string;
  contractId?: string;
  layout?: any;
  template?: any;
  preliminary: {
    clientInformation: SowSinglePartyDetail;
    firmInformation: SowSinglePartyDetail;
    introduction: SowSinglePartyDetail;
  };
  projectDescription: {
    name: SowSinglePartyDetail;
  };
  termsAndCondition: {
    costs: { table: SowProjectTermCosts[] };
    paymentTerms: SowTwoPartyDetail;
  };
  signatureOfFirm: boolean;
  signatureOfClient: boolean;
  isWon: boolean;
  exportHistory: {
    fileKey: string;
    exportedAt: Date;
    exportedBy: Object;
    isFinal?: boolean;
  }[];
  clientFinalDocument?: SowDocument;
  firmFinalDocument?: SowDocument;
  firmFinalLink?: SowUpload;
  clientFinalLink?: SowUpload;
  isFinalOnStep2?: boolean;
  poNumber?: string;
  signatureMode?: number;
  signatories?: { userId?: string; envelopeStatus?: string }[];
}

export interface SowUpload {
  link: string;
  step: number;
  uploadedOn: Date;
}

export interface PdfSettings {
  exportHistory: number;
}

export interface SowTwoPartyDetail {
  isShowing?: boolean;
  isValidated?: number;
  isStarted?: boolean;
  isFirm?: boolean;
  clientData?: {
    currency?: string;
    text?: string;
    updatedAt?: string;
    updatedBy?: string;
    isStarted?: boolean;
  };
  firmData?: {
    currency?: string;
    text?: string;
    updatedAt?: string;
    updatedBy?: string;
    isStarted?: boolean;
  };
  comment?: string[];
  commonData?: {
    currency?: string;
    text?: string;
    isFirm?: boolean;
    updatedAt?: string;
    updatedBy?: string;
  };
}

export interface SowDynamicFormsValue {
  isActive?: boolean;
  isValidated?: number;
  index?: number;
  clientData?: {
    text?: string;
    document?: DocumentOrLink[];
    template?: DocumentOrLink[];
    updatedAt?: string;
    updatedBy?: string;
  };
  firmData?: {
    text?: string;
    document?: DocumentOrLink[];
    template?: DocumentOrLink[];
    updatedAt?: string;
    updatedBy?: string;
  };
  comment?: string[];
  commonData?: {
    text?: string;
    isFirm?: boolean;
    document?: DocumentOrLink[];
    template?: DocumentOrLink[];
    updatedAt?: string;
    updatedBy?: string;
  };
  type?: DynamicFieldDataTypes;
  number?: string | number;
  isNew?: boolean;
  title?: string;
  _id?: string;
  isEdited?: boolean;
}
export interface DynamicFormsValue {
  index?: number;
  isActive?: boolean;
  commonData?: {
    text?: string;
    document?: DocumentOrLink;
    isFirm?: boolean;
    updatedAt?: string;
    updatedBy?: string;
    upload?: {
      name: string;
      key: string;
      type?: number;
      createdBy?: string;
      file?: File | null;
      isNewUpload?: boolean;
    };
    template?: {
      templateReq: {
        name: string;
        key: string;
        type?: number;
        createdBy?: string;
        file?: File | null;
        isNewUpload?: boolean;
      };
      templateRes: {
        name: string;
        key: string;
        type?: number;
        createdBy?: string;
        file?: File | null;
        isNewUpload?: boolean;
      };
    };
  };
  selectedDoc?: fileInputDocument[];
  type: DynamicFieldDataTypes;
  number?: string;
  isNew?: boolean;
  title?: string;
  _id?: string;
  id: string;
}
export interface SowSinglePartyDetail {
  text?: string;
  currentText?: string;
  comment?: string[];
  updatedAt?: string;
  updatedBy?: string;
  isShowing?: boolean;
}

export interface SowPreliminary {
  _id?: string;
  clientInformation?: SowSinglePartyDetail;
  firmInformation?: SowSinglePartyDetail;
  introduction?: SowSinglePartyDetail;
  terminology?: {
    isShowing?: boolean;
    clientData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      text?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
    terminologyTerm?: {
      firmValidationStatus?: number;
      clientValidationStatus?: number;
    };
    isValidated?: number;
  };
  recitals?: {
    clientData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      text?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
    isValidated?: number;
  };
}

export interface SowProjectDescription {
  _id?: string;
  name?: SowSinglePartyDetail;
  projectContext?: SowSinglePartyDetail;
  projectObjective?: SowSinglePartyDetail;
  expectedDeliverables?: SowTwoPartyDetail;
  projectDuration?: {
    isShowing?: boolean;
    clientData?: {
      duration?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      duration?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      duration?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  expectedServicesContext?: SowTwoPartyDetail;
  projectStartDate?: {
    isShowing?: boolean;
    clientData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      date?: Date | string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  projectEndDate?: {
    isShowing?: boolean;
    clientData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      date?: Date | string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  projectScope?: SowTwoPartyDetail;
}

export interface NdaCommonData {
  text?: string;
  comment?: string[];
  updatedAt?: string;
  updatedBy?: string;
  isShowing?: boolean;
}

export interface NdaConfidential {
  definition: ndaTwoPartyDetail;
  exclusions: ndaTwoPartyDetail;
}

export interface NdaObligations {
  disclosingParty: ndaTwoPartyDetail;
  receivingParty: ndaTwoPartyDetail;
}

export interface NdaPreliminaryTerminology {
  _id?: string;
  clientData: {
    term?: string;
    definition?: string;
    termDate?: Date | string;
  };
  firmData: {
    term?: string;
    definition?: string;
    termDate?: Date | string;
  };
  commonData: {
    term?: string;
    definition?: string;
    termDate?: Date | string;
  };
  isDeleted?: boolean;
}

export interface ndaSinglePartyDetail {
  text?: string;
  comment?: string[];
  updatedAt?: string;
  updatedBy?: string;
  isShowing?: boolean;
}

export interface ndaTwoPartyDetail {
  isShowing?: boolean;
  clientData?: {
    text?: string;
    updatedAt?: string;
    updatedBy?: string;
  };
  firmData?: {
    text?: string;
    updatedAt?: string;
    updatedBy?: string;
  };
  comment?: string[];
  commonData?: {
    text?: string;
    isFirm?: boolean;
    updatedAt?: string;
    updatedBy?: string;
  };
  isValidated?: number;
}

export interface NdaPreliminary {
  _id?: string;
  clientInformation?: ndaSinglePartyDetail;
  firmInformation?: ndaSinglePartyDetail;
  introduction?: ndaSinglePartyDetail;
  ndaStartDate?: {
    isShowing?: boolean;
    clientData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      date?: Date | string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  ndaEndDate?: {
    isShowing?: boolean;
    clientData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      date?: Date;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      date?: Date | string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  terminology?: {
    isShowing?: boolean;
    clientData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      text?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
    terminologyTerm?: {
      firmValidationStatus?: number;
      clientValidationStatus?: number;
    };
    isValidated?: number;
  };
  recitals?: {
    isShowing?: boolean;
    clientData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      text?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
    terminologyTerm?: {
      firmValidationStatus?: number;
      clientValidationStatus?: number;
    };
    isValidated?: number;
  };
}

export interface ProjectManagementObjectives {
  projectObjective?: SowSinglePartyDetail;
  expectedDeliverables?: SowSinglePartyDetail;
  expectedServicesContext?: SowSinglePartyDetail;
  projectScope?: SowSinglePartyDetail;
}
export interface SowProjectManagement {
  _id?: string;
  language?: SowTwoPartyDetail;
  acceptanceCriteria?: SowTwoPartyDetail;
  location?: SowTwoPartyDetail;
  methodology?: SowTwoPartyDetail;
  milestones?: {
    isFirmStarted?: boolean;
    isClientStarted?: boolean;
    firmValidationStatus: number;
    clientValidationStatus: number;
    table?: SowProjectManagementGovernance[];
  };
  organization?: SowTwoPartyDetail;
  staffing?: SowTwoPartyDetail;
  statusReports?: SowTwoPartyDetail;
  assumptions?: SowTwoPartyDetail;
  projectScope?: {
    isShowing?: boolean;
    clientData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      text?: string;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      text?: string;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  governance?: {
    comment?: string[];
    isShowing?: boolean;
    isFirmStarted?: boolean;
    isClientStarted?: boolean;
    firmValidationStatus: number;
    clientValidationStatus: number;
    table?: SowProjectManagementGovernance[];
  };
}

export interface SowCommentDetails {
  sowId: string;
  fieldId?: string;
  sowTabName: string;
  sowFieldName: string;
  isInternal?: boolean;
  isReadOnly?: boolean;
}

export interface NDACommentDetails {
  ndaId: string;
  ndaTabName: string;
  ndaFieldName?: string;
  ndaTabLabel?: string;
  ndaFieldLabel?: string;
  ndaFieldId?: string;
  isInternal?: boolean;
}

export interface ProjectEditCondition {
  isEdit: boolean;
  isUpdate: boolean;
  type?: number;
}

export interface FeesInterface {
  isValidated?: number;
  clientData?: {
    amount?: number;
    amountInInvoiceCurrency?: number;
    updatedAt?: string;
    updatedBy?: string;
  };
  firmData?: {
    amount?: number;
    amountInInvoiceCurrency?: number;
    updatedAt?: string;
    updatedBy?: string;
  };
  comment?: string[];
  commonData?: {
    amount?: number;
    amountInInvoiceCurrency?: number;
    isFirm?: boolean;
    updatedAt?: string;
    updatedBy?: string;
  };
}

export interface discountInterface {
  isValidated?: number;
  clientData?: {
    discount?: number;
    updatedAt?: string;
    updatedBy?: string;
  };
  firmData?: {
    discount?: number;
    updatedAt?: string;
    updatedBy?: string;
  };
  comment?: string[];
  commonData?: {
    discount?: number;
    isFirm?: boolean;
    updatedAt?: string;
    updatedBy?: string;
  };
}

export interface FeesArray {
  key?: string;
  fieldKey?: string;
  commentKey?: string;
  isEdit?: boolean;
  isShowDiff?: boolean;
  isValidated?: number;
  clientData?: any;
  firmData?: any;
  comment?: string[];
  commonData?: any;
  total?: number;
  companyCurrency?: number;
  totalInProjectCurrency?: number;
  amountInInvoiceCurrency?: number;
}
export interface SowTermsAndCondition {
  _id?: string;
  budget?: number;
  isMarketplace?: boolean;
  marketPlaceTemplate?: {
    marketPlaceInfo: string;
    feesAndExpenses: string;
    paymentTerms: string;
    invoicingContact: string;
    contractAdministration: string;
  };
  costs?: {
    isFirmStarted?: boolean;
    isClientStarted?: boolean;
    firmValidationStatus: number;
    clientValidationStatus: number;
  };
  termination?: SowTwoPartyDetail;
  poNumber?: SowSinglePartyDetail;
  paymentTerms?: SowTwoPartyDetail;
  invoiceCurrency?: SowTwoPartyDetail;
  managementOfChanges?: SowTwoPartyDetail;
  invoicingContact?: SowSinglePartyDetail;
  contractAdministration?: SowSinglePartyDetail;
  invoicingMilestone?: {
    isFirmStarted?: boolean;
    isClientStarted?: boolean;
    firmValidationStatus: number;
    clientValidationStatus: number;
  };
  fixedFees?: FeesInterface;
  feesPrecision?: SowTwoPartyDetail;
  variableFees?: FeesInterface;
  travelAndExpenses?: FeesInterface;
  expensePrecision?: SowTwoPartyDetail;
  othersFees?: FeesInterface;
  discount?: {
    isValidated?: number;
    clientData?: {
      discount?: number;
      amountInInvoiceCurrency?: number;
      updatedAt?: string;
      updatedBy?: string;
    };
    firmData?: {
      discount?: number;
      amountInInvoiceCurrency?: number;
      updatedAt?: string;
      updatedBy?: string;
    };
    comment?: string[];
    commonData?: {
      discount?: number;
      amountInInvoiceCurrency?: number;
      isFirm?: boolean;
      updatedAt?: string;
      updatedBy?: string;
    };
  };
  detailedFees?: {
    isFirmStarted?: boolean;
    isClientStarted?: boolean;
    firmValidationStatus: number;
    clientValidationStatus: number;
    isShowing: boolean;
  };
  currency?: {
    conversionDetails: Conversion;
    currencyDetails: ProjectCurrency;
    invoiceCurrencyDetails?: ProjectCurrency;
    companyCurrencyDetails?: ProjectCurrency;
  };
}

export interface SowProjectGeneralProvsions {
  _id?: string;
  notices: SowTwoPartyDetail;
  liability: SowTwoPartyDetail;
  indemnity: SowTwoPartyDetail;
  onboarding: SowTwoPartyDetail;
  assignment: SowTwoPartyDetail;
  enforcement: SowTwoPartyDetail;
  forceMajeure: SowTwoPartyDetail;
  announcements: SowTwoPartyDetail;
  dataProtection: SowTwoPartyDetail;
  confidentiality: SowTwoPartyDetail;
  entitreAgreement: SowTwoPartyDetail;
  repAndWarranties: SowTwoPartyDetail;
  intellectualProperty: SowTwoPartyDetail;
  additionalProvisions: SowTwoPartyDetail;
  independentContractor: SowTwoPartyDetail;
  new?: SowTwoPartyDetail;
}

export interface SoWCommentAuthor {
  _id: string;
  companyId: string;
  email: string;
  name: string;
  role?: number;
  firmInternal?: boolean;
  clientInternal?: boolean;
}

export interface SoWComment {
  _id: string;
  sowId: string;
  sowTab: string;
  sowField: string;
  comment: string;
  sowFieldId?: string;
  isInternal?: boolean;
  isClient?: boolean;
  replies: {
    _id: string;
    createdAt: Date;
    createdBy: SoWCommentAuthor;
    updatedAt: Date;
    updatedBy: SoWCommentAuthor;
    comment: string;
    isDeleted: boolean;
  }[];
  isResolved: boolean;
  createdAt: Date;
  createdBy: SoWCommentAuthor;
  updatedAt: Date;
  updatedBy: SoWCommentAuthor;
  isExpanded: boolean;
}

export interface SowProjectRefDocument {
  firmId: Firms | string;
  companyId: clients | string;
  projectId: string | project;
  updatedAt: Date;
  updatedBy: string | user;
  document: {
    documentType: number;
    documentKey: String;
  }[];
}

export interface SowProjectRefTypeDocument {
  file?: File;
  _id?: string;
  type?: number;
  documentKey?: string;
  documentType?: number;
  documentStep?: number;
  documentName?: string;
  isUploaded?: boolean;
  finalExecutedDocument?: {
    fileName: string;
    fileKey: string;
  };
  updatedBy?: string;
  documents?: { documentType?: number; documentKey: string; documentName: string }[];
}

export interface SowProjectTerminology {
  _id?: string;
  clientData: {
    term?: string;
    definition?: string;
  };
  firmData: {
    term?: string;
    definition?: string;
  };
  commonData: {
    term?: string;
    definition?: string;
  };
  isDeleted?: boolean;
}

export interface SowProjectManagementMilestone {
  _id?: string;
  clientData: {
    updatedAt?: Date;
    milestone: string;
    updatedBy?: string;
    description: string;
    estimatedDate?: Date;
    deliverables: string;
  };
  firmData: {
    updatedAt?: Date;
    milestone: string;
    updatedBy?: string;
    description: string;
    estimatedDate?: Date;
    deliverables: string;
  };
  commonData: {
    updatedAt?: Date;
    milestone: string;
    updatedBy?: string;
    description: string;
    estimatedDate?: Date;
    deliverables: string;
  };
}

export interface ProjectManagementMilestone {
  _id?: string;
  updatedAt?: Date;
  milestone: string;
  updatedBy?: string;
  description: string;
  estimatedDate?: Date | string;
  deliverables: string;
}

export interface SowProjectTermCosts {
  _id?: string;
  clientData: {
    cost: number;
    nature: string;
    updatedAt?: Date;
    updatedBy?: string;
  };
  firmData: {
    cost: number;
    nature: string;
    updatedAt?: Date;
    updatedBy?: string;
  };
  commonData: {
    cost: number;
    nature: string;
    updatedAt?: Date;
    updatedBy?: string;
  };
}

export interface SowProjectTermMilestone {
  _id?: string;
  clientData: {
    amount: number;
    amountInInvoiceCurrency?: number;
    invoice: string;
    updatedAt: Date;
    updatedBy: string;
    estimatedDate: Date;
  };
  firmData: {
    amount: number;
    amountInInvoiceCurrency?: number;
    invoice: string;
    updatedAt: Date;
    updatedBy: string;
    estimatedDate: Date;
  };
  commonData: {
    amount: number;
    amountInInvoiceCurrency?: number;
    invoice: string;
    updatedAt: Date;
    updatedBy: string;
    estimatedDate: Date;
  };
}

export interface SowProjectTermDetailedFee {
  _id?: string;
  clientData: {
    milestone: string;
    role: string;
    location: string;
    name: string;
    dailyRate: number;
    amountInInvoiceCurrency: number;
    days: number;
    updatedAt?: Date;
    updatedBy?: string;
  };
  firmData: {
    milestone: string;
    role: string;
    location: string;
    name: string;
    dailyRate: number;
    amountInInvoiceCurrency: number;
    days: number;
    updatedAt?: Date;
    updatedBy?: string;
  };
  commonData: {
    milestone: string;
    role: string;
    location: string;
    name: string;
    dailyRate: number;
    amountInInvoiceCurrency: number;
    days: number;
    updatedAt?: Date;
    updatedBy?: string;
  };
  isDeleted?: boolean;
}

export interface InvoicingMilestone {
  _id?: string;
  amount: number;
  paidAmount?: number;
  paymentDoneOn?: Date;
  amountInInvoiceCurrency?: number;
  invoice: string;
  updatedAt: Date;
  updatedBy: string;
  estimatedDate: Date | string;
}

export interface SowProjectManagementGovernance {
  _id?: string;
  clientData: {
    members: string[];
    providerPartners: string[];
    updatedAt: Date;
    meeting: string;
    updatedBy: string;
    frequency: string;
  };
  firmData: {
    members: string[];
    providerPartners: string[];
    updatedAt: Date;
    meeting: string;
    updatedBy: string;
    frequency: string;
  };
  commonData: {
    members: string[];
    providerPartners: string[];
    updatedAt: Date;
    meeting: string;
    updatedBy: string;
    frequency: string;
  };
}

export interface SoWValidationDetails {
  firmId: string;
  tabName: SowTabName;
  fieldName: SowFieldName;
  fieldId?: string;
}

export interface NdaValidationDetails {
  firmId: string;
  tabName: NdaTabName;
  fieldName: string;
  fieldId?: string;
}

export interface NdaFieldValidationData {
  firmData: { [x: string]: ndaTwoPartyDetail };
  clientData: { [x: string]: ndaTwoPartyDetail };
  commonData: { [x: string]: ndaTwoPartyDetail };
  isValidated?: number;
}

export interface SoWFieldValidationData {
  firmData: { [x: string]: SowTwoPartyDetail };
  clientData: { [x: string]: SowTwoPartyDetail };
  commonData: { [x: string]: SowTwoPartyDetail };
  type: string;
  isValidated?: number;
}

export interface SoWTableFieldValidationData {
  firmValidationStatus: number;
  clientValidationStatus: number;
}
export interface TeamFirmId {
  _id: string;
  firmName: string;
  profilePhoto: string;
}

export interface TeamCompanyId {
  _id: string;
  companyName: string;
  companyPicture: string;
}
export interface ProjectTeam {
  _id: string;
  firmId: TeamFirmId;
  firmTeam: Team[];
  projectId: { procurementLeader: string; projectStep?: number; legalEntityId?: string; isMarketplace?: boolean };
  companyId: TeamCompanyId;
  clientTeam: Team[];
}
export interface SowProjectPerformance {
  _id?: string;
  firmId: string;
  canView?: boolean;
  clientId?: string;
  projectId?: string;
  performanceScoreCardVisible?: boolean;
  survey?: SowProjectPerformanceSurvey[];
  firmTeam: {
    memberId: string;
  }[];
  isCompleted?: boolean;
}

export interface SowProjectPerformanceSurvey {
  memberId?: string;
  surveyStatus?: number;
  surveySentDate?: Date;
  surveyCompletedOn?: Date;
}

export interface SoWPurchaseOrderDetails {
  // clientInfo: string;
  // firmInfo: string;
  introduction: string;
  partnerInCharge: string;
  businessOwner: string;
  projectLeader: string;
  procurementLeader: string;
  projectName: string;
  totalCost: number;
  paymentTerms: string;
  discounts: number;
  taxes: number;
  currencySymbol?: string;
  poNumber?: string;
}

export interface StrategicPriority {
  _id?: string;
  year: number;
  entity: string;
  title: string;
  strategicPriority: string;
  priorityType: string;
  expectedReturns: string;
  validationRules: string;
  documents: { documentType?: number; documentKey: string; documentName: string }[];
  link: string[];
  createdBy?: { name?: string; picture?: string };
  updatedBy?: { name?: string; picture?: string };
  createdAt?: Date;
  updatedAt?: Date;
}

export interface DriveList {
  link: string[];
}

export interface MilestonChanges {
  document?: { documentType?: number; documentKey: string; documentName: string }[];
  link?: string[];
  changeId: string;
  sectionId: string;
}

export interface ContractsFilter {
  type: number[];
  procurementLeader: string[];
  projectLeader: string[];
  consultingFirm: string[];
  providerLeader?: string[];
  creationStartDate: string;
  creationEndDate: string;
  validityStartDate: string;
  validityEndDate: string;
  renewalStartDate: string;
  renewalEndDate: string;
  creationSelectField: number;
  validitySelectField: number;
  renewalSelectField: number;
  marketPlace: string;
  client?: string[]
}

export interface Contracts {
  _id?: string;
  projectId?: string;
  type: number;
  status?: string;
  procurementLeader: string;
  providerLeader: string;
  projectLeader: string;
  consultingFirm: string;
  creationDate: Date;
  validityDate: Date;
  renewalDate: Date;
  content: string;
  documents: { documentType?: number; documentKey: string; documentName: string }[];
  link: string[];
  sowId?: string;
  ndaId?: string;
  msaId?: string;
  ndaDetails?: contractListNdaDetails;
  msaDetails?: contractListNdaDetails;
  sowDetails?: contractListNdaDetails;
  projectName?: string;
}

export interface contractListNdaDetails {
  _id: string;
  firmTeam: { memberId: string; role: number };
  clientTeam: { memberId: string; role: number };
  finalExecutedDocument: { fileKey: string; fileName: string };
}

export interface SelectedContractDocuments {
  file?: File;
  documentKey?: string;
  documentType?: number;
  documentName?: string;
  isUploaded: boolean;
}

export interface AddDemandParameter {
  id: string;
  type: string;
}

export interface ConsultingFirmDrawer {
  isFilter?: boolean;
  isEditCompanyInfo?: boolean;
  isEditAdditionalInfo?: boolean;
  isEditCapabilitiesInfo?: boolean;
  isEditIndustriesInfo?: boolean;
  isEditServicedBusinessInfo?: boolean;
  isEditFootPrint?: boolean;
  isEditAboutConsultingFirm?: boolean;
  isEditSpecificInfoOnBoardingInfo?: boolean;
  isSpecificInfoPrimary?: boolean;
  isAddRisk?: boolean;
  drawerType: string;
  selectedId?: string;
  additionalData?: object;
  isIndustries?: boolean;
  owners?: { _id: string; name: string }[];
  firmDetails?: { firmId: string };
  isMeetingDrawer?: boolean;
  filterData?: Object;
  meetingsFilter?: MeetingFilter;
}

export interface MeetingForm {
  _id: string;
  title: string;
  companyAttendees: string[];
  consultingFirmAttendees: string[];
  nature: string;
  meetingNature?: string;
  keyOutcomes: string;
  inviteUrl?: string;
  document: { type: DOCUMENT_TYPE; name?: string; key: string };
  startTime?: Date;
  endTime?: Date;
  startTimeString?: string;
  endTimeString?: string;
  dateString?: string;
  createdBy?: string;
  startDate: Date;
  endDate: Date;
  date?: Date;
  meetingType?: string;
  isFileChanged?: boolean;
}

export interface Briefing {
  providerId: string;
  title: string;
  providerLead: string;
  briefingDate: Date;
  inviteUrl?: string;
  startTime?: Date;
  endTime?: Date;
  startDate?: Date;
  endDate?: Date;
  meetingType?: string;
  eventId?: string;
}

export interface MeetingFilter {
  startDate: Date | string;
  endDate: Date | string;
  nature: number[];
  selectedField: number;
  companyAttendees: string[];
  consultingFirmAttendees: string[];
  isClose?: boolean;
}

export interface RiskFilter {
  riskLevel: number;
  startDate: Date | string;
  endDate: Date | string;
  selectedField: number;
  consultingFirm?: ConsultingFirm[];
}

export interface ConsultingFirmSpecificInformation {
  procurementLead: {
    _id: string;
    name: string;
  };
  tier: {
    _id: string;
    name: string;
  };
  mainContact: {
    _id: string;
    name: string;
  };
  status?: boolean;
  specificInformation: {
    certificationData: Array<SelectedContractDocuments>;
    complianceData: Array<SelectedContractDocuments>;
    legalFiscalData: Array<SelectedContractDocuments>;
    onboardingData: Array<SelectedContractDocuments>;
  };
}

export interface OfficeLocation {
  _id?: string;
  city: string;
  state: string;
  region: string;
  street: string;
  country: {
    _id: string;
    name: string;
  };
  zipCode: string;
  isHeadquarters: boolean;
  geolocation?: {
    latitude: Number;
    longitude: Number;
  };
}
export interface companyProfileDetails {
  about: string;
  websiteUrl: string;
  overview?: string;
  socialIcons?: {
    faceBook?: string;
    twitter?: string;
    linkedIn?: string;
    intagram?: string;
  };
  name: string;
  numberOfEmployess: string;
  tagline: string;
}
export interface Demographics {
  industry: IndustryOrCapability[];
  capability: IndustryOrCapability[];
}
export interface IndustryOrCapability {
  _id: string;
  name: string;
  parentName?: string;
  children?: [
    {
      _id: string;
      name: string;
      parentName: string;
    }
  ];
}
export interface LegalEntityMultiSelectData {
  _id: string;
  name: string;
  isLegalEntity?: boolean;
  parentName?: string;
  children?: [
    {
      _id: string;
      name: string;
      parentName: string;
    }
  ];
}

export interface LegalEntity {
  isActive: boolean;
  _id: string;
  name?: string;
  officeName?: string;
  officeAddress?: string;
  vendorText?: string;
  isLegalEntity?: boolean;
}

export interface BillingAddress {
  city: string;
  country: string;
  line1: string;
  line2: string;
  zipCode: string;
  state: string;
}

export interface EntityDetails {
  _id: string;
  name: string;
  type: String;
  billingInfo: {
    billingContact: string;
    billingAddress: BillingAddress;
    regNumber?: string;
    applicableTax: string;
    stripeCustomerId?: string;
  };
  insuranceProof?: {
    documentType: number;
    documentKey: string;
    documentName: string;
  };
  incorporationProof?: {
    documentType: number;
    documentKey: string;
    documentName: string;
  };
  vatRegNumber: string;
  contract: {
    documentKey: string;
    documentName: string;
    createdAt: Date;
  };
  firmId: string;
  adminContact: {
    name: string;
    _id?: string;
    picture?: string;
  };
  stripeStatus?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  onBoardingStatus: string;
}

export type L_E_Account = Pick<
  EntityDetails,
  'name' | 'createdAt' | 'vatRegNumber' | 'incorporationProof' | 'insuranceProof' | 'onBoardingStatus' | 'adminContact' | 'stripeStatus'
>;
export type L_E_Billing = Pick<EntityDetails, 'name' | 'billingInfo' | 'stripeStatus' | 'adminContact' | 'createdAt' | 'updatedAt'>;

export interface L_E_Contracts {
  contract: { documentType: number; documentKey: string; documentName: string }[];
  cgnContract: { documentType: number; documentKey: string; documentName: string };
  marketPlaceContract: { documentType: number; documentKey: string; documentName: string };
  adminContact?: string;
}

export interface UserFocus {
  capability: CapabilityFocus[];
  industry: IndustryFocus[];
}
export interface IndustryFocus {
  industryId: IndustryOrCapability;
  percent: number;
}
export interface CapabilityFocus {
  capabilityId: IndustryOrCapability;
  capabilityInfo?: IndustryOrCapability;
  percent: number;
  _id?: string;
}

export interface SuggestCompanyDetails {
  _id: string;
  profilePhoto?: string;
  about: string;
  createdBy: string;
  isAdminRequested: boolean;
  createdAt: Date;
  range: string;
  establishedYear: string;
  industry: string;
  diversity: string;
  companyType: CompanyType;
  name: string;
  websiteUrl: string;
  customCompanyId?: string;
  consultingFirmId: string;
  consultingFirmName: string;
}

export interface RegionOrCountry {
  _id: string;
  name: string;
  region?: string;
}

export interface Demographics {
  industries: IndustryOrCapability[];
  capabilities: IndustryOrCapability[];
  regions: RegionOrCountry[];
  countries: RegionOrCountry[];
  filteredCountries: RegionOrCountry[];
  childCapabilities: ChildCapabilitiesOrIndustries[];
  childIndustries: ChildCapabilitiesOrIndustries[];
}

export interface ChildCapabilitiesOrIndustries {
  _id: string;
  name: string;
  parentName: string;
}

export interface DialogDisplayData {
  // General Dialogs
  title: string;
  description: string;
  acceptButtonText: string;
  cancelButtonText: string;
  // Image Crop Dialog
  photoUrl: string;
  photoType: UploadType;
  showDelete: boolean;
  // Post Dialog
  postType: string;
  //wallpostUserId
  wallPostUserId: string;
  currentUserId: string;
  profilePictureUrl: string;
  isMultiple: boolean;
}

export interface SuggestCompanyDetails {
  _id: string;
  profilePhoto?: string;
  about: string;
  createdBy: string;
  isAdminRequested: boolean;
  createdAt: Date;
  range: string;
  establishedYear: string;
  industry: string;
  diversity: string;
  companyType: CompanyType;
  name: string;
  websiteUrl: string;
  customCompanyId?: string;
}

export interface CompanyProfileDetails {
  _id: string;
  name: string;
  profilePhoto: string;
  coverPhoto: string;
}

export interface SpendByRegionAnalytics {
  revenue: number;
  region: string;
  percentage: number;
}

export interface LayoutAndTemplateData {
  _id: string;
  templateName: string;
  style: object;
  companyId: string;
  language: string;
  category: string;
  type: string;
  data: object;
  isDeleted: boolean;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: Object;
}

export interface DynamicFieldModelSpec {
  sectionName: string;
  sectionKey?: string;
  placeHolder?: string;
  fields?: {
    fieldName: string;
    fieldKey: string;
    placeHolder?: string;
  }[];
}

export interface DynamicDesignModelSpec {
  sectionName: string;
  sectionKey?: string;
  fields: {
    fieldName: string;
    fieldKey: string;
    icon: string;
  }[];
}

export interface LayoutData {
  brandingPreferences: {
    coverPageContent: string;
    headerContent: string;
    footerContent: string;
  };
  contentStyle?: ContentStyle;
  pageTitle: object;
  sectionTitle: object;
  subSectionTitle: Object;
  layoutId?: string;
  layoutName?: string;
}

interface PartnerList {
  name: string;
  _id: string;
}
export interface SowFirmListData {
  firmId: {
    firmName: string;
    _id: String;
    partners: PartnerList[];
  };
  invitationStatus?: number;
  partner: string;
}

export interface SowFirmDetails {
  name: string;
  id: string;
  _id?: string;
  email?: string;
  firmName?: string;
  isMainContact?: boolean;
}
export interface SelectedFirmData {
  firm: SowFirmDetails;
  legalEntity?: string;
  partner: SowFirmDetails;
  fromRfp: boolean;
  layout: LayoutAndTemplateData;
  template: LayoutAndTemplateData;
  msaId: string;
  contractId?: string;
  isReset?: boolean;
  isMarketPlace?: boolean;
}

export interface Layout {
  brandingPreferences: {
    coverPageContent: string;
    headerContent: string;
    footerContent: string;
  };
  layoutId: string;
  layoutName: string;
}

export interface PaginationOptions {
  totalCount: number;
  limit: number;
  page: number;
  endPage: number;
  pagesArray: number[];
}

export interface SoWPermissions {
  reset: boolean;
  delete: boolean;
}

export interface MarketExplorationFilter {
  industry?: [];
  region?: [];
  country?: [];
  capability?: [];
  companySize?: string;
  improveoDiversity?: [];
  improveoCommunityImpact?: [];
  city?: [];
}

export interface ConSourceModule {
  moduleId: string;
  code?: string;
  name: string;
  path?: string;
  firmPath?: string;
  readOnly: boolean;
  icon: string;
  roles: number[];
  children?: ConSourceSubModule[];
  linkActive?: boolean;
  visibleInNav: boolean;
}

export interface ConSourceSubModule {
  moduleId: string;
  name: string;
  code?: string;
  path: string;
  roles?: number[];
  readOnly: boolean;
  visibleInNav: boolean;
  firmPath?: string;
}

export interface ConsourceRouteKeys {
  HOME: string;
  STRATEGIC_PRIORITIES: string;
  APPROVAL_WORKFLOWS: string;
  DEMANDS: string;
  PROJECT_LIST: string;
  PROJECT_ANALYTICS: string;
  CONTRACTS_LIST: string;
  INVOICES_AND_PAYMENTS: string;
  PROVIDER_PANEL: string;
  LEGAL_ENTITIES: string;
  PANEL_ANALYTICS: string;
  PERFORMANCE_MANAGEMENT: string;
  C_G_N: string;
  SUGGESTED_PROVIDER: string;
  RISK_MANAGEMENT: string;
  LIBRARY: string;
  SCS_ACADEMY: string;
  COMMUNITY: string;
  COMPANY_ANALYTICS: string;
  OBJECTIVES: string;
  PROCESS_ANALYTICS: string;
  CONSULTING_BUDGETS: string;
  TASK_ANALYTICS: string;
  ALL_TASK: string;
  MY_TASK: string;
  USER_LIST: string;
  USER_INVITE_REQUESTS: string;
  ACCOUNT_BILLING: string;
  ACTIVITY: string;
  CUSTOMISATION: string;
  INVITE_USER: () => void;
  HELP_CENTER: () => void;
  LOG_OUT: () => void;
  PROVIDER_PROFILES: string;
  PROVIDER_PROFILE_COMPANY_PROFILE: string;
  PROVIDER_PROFILE_L_E: string;
  PROVIDER_PROFILE_PARTNER: string;
  PROVIDER_PROFILE_PROJECTS: string;
  PROVIDER_PROFILE_ANALYTICS: string;
  PROVIDER_PROFILE_CONTRACTS: string;
  PROVIDER_PROFILE_MEETINGS: string;
  PROVIDER_PROFILE_TASKS: string;
  PROVIDER_PROFILE_SETTINGS: string;
  ADD_TASK: string;
  MESSAGES: string;
  NOTIFICATIONS: string;
}

export interface ClientModuleManagement {
  moduleId: string;
  childModuleIds: string[];
}

export interface EnabledModuleInfo {
  clientId: { _id: string; companyName: string };
  enabledModules: EnabledModule[];
  isFirm?: boolean;
}

export interface EnabledModule {
  moduleId: string;
  children?: string[];
}

export interface NDACommentAuthor {
  _id: string;
  companyId: string;
  email: string;
  name: string;
  role?: number;
  firmInternal?: boolean;
  clientInternal?: boolean;
}

export interface NDAComment {
  _id: string;
  ndaId: string;
  ndaTab: string;
  ndaField: string;
  comment: string;
  ndaFieldId?: string;
  isInternal?: boolean;
  isClient?: boolean;
  replies: {
    _id: string;
    createdAt: Date;
    createdBy: NDACommentAuthor;
    updatedAt: Date;
    updatedBy: NDACommentAuthor;
    comment: string;
    isDeleted: boolean;
  }[];
  isResolved: boolean;
  createdAt: Date;
  createdBy: NDACommentAuthor;
  updatedAt: Date;
  updatedBy: NDACommentAuthor;
  isExpanded: boolean;
}

export interface LanguageDrawer {
  type: string;
  language?: string;
  drawerType: AdminSettingsDrawerType;
  fileFormat?: string;
  fileList?: Object[];
}

export interface CountryDrawer {
  type: string;
  country?: string;
  drawerType: AdminSettingsDrawerType;
  parentId: string;
}

export interface ConversionDrawer {
  type: string;
  conversion: string;
  drawerType: AdminSettingsDrawerType;
}

export interface UploadFileDetails {
  uploadType?: UploadType | string;
  fileKey?: string;
  mimeType?: string;
  folderPath?: string;
  fileName?: string;
  responseType?: ViewType;
}

export enum ViewType {
  'VIEW' = 'VIEW',
  'DOWNLOAD' = 'DOWNLOAD',
}
export interface DiffInputBox {
  title: string;
  type: string;
  fieldType: string;
  placeholder: string;
  commentHeader: string;
  fieldName: string;
  key: string;
  textPlaceholder?: string;
  textFieldName?: string;
  dateFieldName?: string;
  datePlaceholder?: string;
  value: ndaTwoPartyDetail;
  date?: ndaTwoPartyDetail;
  text?: ndaTwoPartyDetail;
  noDiffText?: string;
}

export interface ChatRoom {
  _id?: string;
  members: string[] | ChatMember[];
  isPrivate: boolean;
  roomName?: string;
  projectId?: string;
  chatProfile: ChatMember;
  isBlocked?: boolean;
}

export interface SignatureDetails {
  isClient: boolean;
  signatureMode?: number;
  envelopeId?: string;
  signatureText: SowTwoPartyDetail;
  contract?: Sow;
  isSigned: boolean;
  isSignedByOther: boolean;
  myDocument?: SowDocument;
  myLink?: { link: string; step: number; uploadedOn?: Date };
  otherDocument?: SowDocument;
  otherLink?: { link: string; step: number; uploadedOn?: Date };
  step2Title?: string;
  isFinalOnStep2?: boolean;
  totalUploadedCount: number;
  exportPDF: string;
  frozen?: boolean;
  isCommunicationMailSent?: boolean;
  isWon?: boolean;
  isPdfLoading?: boolean;
  projectId?: {
    fastTrackTo: number;
    _id: string;
  };
  firmId?: {
    _id: string;
    profilePhoto: string;
    firmName: string;
  };
  companyId?: {
    _id: string;
    companyPicture: string;
    companyName: string;
  };
  exportHistory?: Object[];
  currencySymbol?: string;
  poNumber?: string;
  clientTeam?: { _id: string; name: string; jobtitle: string; picture: string }[];
  firmTeam?: { _id: string; name: string; jobtitle: string; picture: string }[];
  eSignedDocument?: object;
  signatureTextOfClient?: any;
  status?: string;
}

export interface FirmActionSubject {
  firmId: string;
  role: number;
  userId: string;
}
export interface BlogDetails {
  title: string;
  comment: string;
  link: string;
  createdBy?: string;
  createdAt?: string;
  _id?: string;
  catagory: Catagory;
}

export interface RfpMilestone {
  _id?: string;
  name: string;
  isDefault: boolean;
  isMandatory: boolean;
  isSuperAdmin: boolean;
  clientId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  isDeleted: boolean;
  deletedAt: Date;
  deletedBy: string;
}

export interface RfpSelectionCriterion extends RfpMilestone { 
  isTechnical: boolean;
}

export interface DrawerDetails {
  drawerName: string;
  drawerData?: object;
  isChanged?: boolean;
}

export interface Catagory {
  name: string;
  _id: string;
}

export interface CommonComments {
  msaId?: string;
  tabName: string;
  fieldName?: string;
  fieldId?: string;
  isInternal?: boolean;
  changeId?: string;
}

export interface CommonCommentsDrawer {
  fromType: string;
  msaId?: string;
  sowId?: string;
  ndaId?: string;
  changeId?: string;
  tabName: string;
  fieldName?: string;
  fieldId?: string;
  fieldLabel: string;
  tabLabel: string;
}

export interface CommentAuthor {
  _id: string;
  companyId: string;
  email: string;
  name: string;
  role?: number;
  firmInternal?: boolean;
  clientInternal?: boolean;
  picture?: string;
}

export interface noDataContent {
  search?: string;
  btnName: string;
  isSmall: boolean;
  title: string;
  content: string;
  isActionNeed: boolean;
  isIconNeed: boolean;
}

export interface Comments {
  _id: string;
  ndaId?: string;
  sowId?: string;
  msaId: string;
  tabName: string;
  fieldId?: string;
  isInternal?: boolean;
  isClient?: boolean;
  fieldName: string;
  comment: string;
  replies: {
    _id: string;
    createdAt: Date;
    createdBy: CommentAuthor;
    updatedAt: Date;
    updatedBy: CommentAuthor;
    comment: string;
    isDeleted: boolean;
  }[];
  isResolved: boolean;
  createdAt: Date;
  createdBy: CommentAuthor;
  updatedAt: Date;
  updatedBy: CommentAuthor;
  isExpanded: boolean;
}

export interface MsaValidationDetails {
  firmId: string;
  tabName: string;
  fieldName: string;
  fieldId?: string;
}

export interface DynamicFieldValidationData {
  title: string;
  firmData: { [x: string]: SowTwoPartyDetail };
  clientData: { [x: string]: SowTwoPartyDetail };
  commonData: { [x: string]: SowTwoPartyDetail };
  isValidated?: number;
}

export interface ClientFirmLink {
  _id?: string;
  clientId: string;
  firmId: string;
  firmInfo: ConsultingFirm;
  partners: string[];
  procurementLeader?: string;
  tier?: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  isDeleted: boolean;
  deletedAt: Date;
  segment?: string[];
  deletedBy: string;
  relationshipManager?: string;
}

export interface MsaFinalDocument {
  documents: [
    {
      documentKey: string;
      documentName: string;
      documentType: number;
    }
  ];
  finalExecutedDocument: { fileName: string; fileKey: string };
  _id: string;
}

export interface FirmDashboardData {
  latestContractsSigned: {
    projectId: {
      name: string;
    };
    updatedAt: Date;
  }[];
  latestProjects: {
    projectId: {
      name: string;
      projectStep: number;
    };
  }[];
  projectsAboutToEnd: {
    projectId: {
      name: string;
      endDate: Date;
    };
  }[];
}

export interface SuggestCompany {
  name: string;
  websiteUrl: string;
  email: string;
  rationalFields: string;
  comment?: string;
}

export interface CompanyGeneralInfo {
  createdAt: string;
  updatedAt: string;
  createdBy: { name: string };
  conpasRating: number;
  range: string;
  websiteUrl: string;
  demographics: {
    region: { regionId: RegionOrCountry }[];
    country: { countryId: RegionOrCountry }[];
    capability: CapabilityDetails[];
    industry: IndustryDetails[];
  };
  establishedYear: number;
  companyType: string;
  industry: { name: string };
  about: string;
}

export interface CapabilityDetails {
  capabilityInfo: IndustryOrCapability;
  percent: number;
}

export interface IndustryDetails {
  industryInfo: IndustryOrCapability;
  percent: number;
}

export interface ViewAboutSection {
  _id?: string;
  title: string;
  sectionId: string;
  description?: string;
  index?: number;
  contents: ContentsAbout[];
  ellipsisLength?: number;
  ellipsisEnabled?: boolean;
  challenge?: string;
}

export interface ContentsAbout {
  _id: string;
  title: string;
  sectionId: string;
  index: number;
  description: string;
  imageUrl?: string;
  date?: string;
  link?: string;
  ellipsisEnabled: boolean;
  ellipsisLength: number;
  contents?: ContentsAbout[];
  demographics?: Demographics;
}

export interface DemographicsData {
  _id: string;
  name: string;
  parent?: string;
}

export interface AdvanceFilterData {
  industry: string[];
  childIndustry: string[];
  childCapability: string[];
  capability: string[];
}

export interface CommonTableData {
  data: object[];
  page?: number;
  totalCount?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: number;
  pagesArray?: number[];
  endPage?: number;
  displayedColumns: string[];
  search?: string;
  selectedData?: string[];
  isLoading?: boolean;
  type?: string;
  columns?: {
    columnDef: string;
    header?: string;
    cssSelector?: string;
    sort: boolean;
    key?: string;
    type?: string;
  }[];
  filter: { status?: string };
  isFirm?: boolean;
  isEditable?: boolean;
  fastTrackTo: string;
}

export interface InvoicesFilter {
  companies: string[];
  firms: string[];
  projects: string[];
  marketplace: string;
}

export interface ProposalDrawerData {
  _id: string;
  averageFTEs: number;
  canDelete: boolean;
  companyId: string;
  createdAt: string;
  createdBy: string;
  currencyId: { _id: string; currencyCode: string };
  documentKey: string;
  documentName: string;
  documentStep: number;
  documentType: number;
  duration: number;
  projectId: string;
  totalBudget: number;
}

export interface AccountDetails {
  _id?: string;
  clientId?: string;
  firmId?: string;
  plan: PlanDetails;
  contract: ContractDetails[];
  administrativeInfo: AdministrativeInfo;
  isOptedForMarketPlace?: boolean;
  canEnableMarketplace?: boolean;
  applicationFee?: number;
}

export interface PlanDetails {
  _id?: string;
  planType: string;
  poNumber: string;
  startDate: Date;
  endDate: Date;
  timePeriod: number;
  renewalType: string;
  processFee: number;
  planNature: string;
}

export interface ContractDetails {
  documentType: number;
  documentKey: string;
  documentName: string;
}

export interface ContractPayload {
  documents: { documentType: number; documentKey: string; documentName: string }[];
  links: string[];
}

export interface AdministrativeInfo {
  _id?: string;
  accountCreatedDate: Date;
  adminEmail: string;
  billingInfo?: {
    billingContact: string;
    billingAddress: BillingAddress;
  };
  applicableTax?: number;
  clientOption?: number;
  partnerId?: string;
  adminContact?: { _id: string; name: string; email: string };
}

export interface BillingAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface OptionsLists {
  [name: string]: number | string;
}

export interface conversionData {
  currency: {
    _id: string;
    countryId?: string;
    currencyCode: string;
    currencyName: string;
    conversionValue: number;
    validity: Date;
  };
  isEditable: boolean;
}

export interface AccountUsage {
  totalProject: number;
  firmsInPanel: number;
  activeUsers: number;
}

export interface AccountsDrawer {
  open: boolean;
  type: string;
  isClient?: boolean;
  accountDetails?: AccountDetails;
  clientAdminDetails?: { _id: string; name: string; email: string };
  createdAt?: Date;
}

export interface FirmBillingDrawer {
  open: boolean;
  billingDetails?: object;
}

export interface EntityDetails {
  _id: string;
  name: string;
  address: BillingAddress;
  regNo: string;
  taxInformation: string;
  onboardingStatus: string;
}

export interface OnBoardingDrawer {
  type: OnBoardingEditDrawerType;
  drawerDetails: AddSection & EditQuestion & EditSection & onboardingTemplate;
}

export interface Question {
  question: string;
  answerType: string;
}
export interface AddSection {
  sectionName: string;
  isClient: boolean;
  questions: Question[];
  companyName: string;
}

export interface EditQuestion {
  sectionName: string;
  question: Question;
  companyName: string;
}

export interface EditSection {
  sectionName: string;
  companyName: string;
}
export interface onboardingTemplate {
  templateId: string;
  companyName: string;
}

export interface OnBoardingFormVisibility {
  isSectionEditable: boolean;
  isSectionVisible: boolean;
  isQuestionVisible: boolean;
  isMultipleQuestions: boolean;
}

export interface ChatMessage {
  _id?: string;
  createdAt: string;
  createdBy: ChatMember;
  senderInfo: ChatMember;
  roomId: string;
  message: string;
  readByMembers: string[];
  date: string;
  messageScope?: ChatMessageScope;
  isPrivateChat?: boolean;
  document: {
    name: string;
    key: string;
  };
  type: number;
  isEdited: boolean;
  isDeleted: boolean;
}

export interface ChatMember {
  _id?: string;
  name?: string;
  picture?: string;
  role?: number;
  onlineStatus?: boolean;
}

export enum ChatMessageScope {
  FROM = 'from',
  TO = 'to',
}

export enum ChatSoundType {
  INCOMING,
  OUTGOING,
}

export interface Link {
  link: string;
  name?: string;
  description: string;
  _id?: string;
  createdAt?: string;
  createdBy?: string;
}

export interface Task {
  name: string;
  description: string;
  _id: string;
  owner: ChatMember;
  createdAt?: string;
  createdBy?: string;
  comment?: string;
  status?: number;
  isDeleted?: boolean;
  dueDate?: Date;
  projectId?: string;
}

export interface SelectionData {
  firmActionDate: Date | string | [];
  firmComment: string;
  firmId: {
    profilePhoto: string;
    firmName: string;
    _id: string;
  };
  invitationSentOn: [];
  invitationStatus: number;
  isRfpVisible: boolean;
  isShortListed: boolean;
  isWon: boolean;
  ndaFileKey: string;
  ndaFileName: string;
  ndaId: string;
  contractId: string;
  partner: {
    email: string;
    name: string;
    _id: string;
  };
  partnerComment: string;
  _id: string;
  ndaList?: [];
}

export interface RiskData {
  riskId: string;
  comment: string;
  light: number;
  impact?: number;
  likelihood?: number;
  lastUpdated?: Date;
  comments?: string;
  createdAt?: Date;
  name?: string;
  description?: string;
  category?: string;
  _id?: string;
  updatedBy?: {
    _id: string;
    name: string;
    picture?: string;
  };
  createdBy?: {
    _id: string;
    name: string;
    picture: string;
  };
}

export enum DemandTabs {
  'BasicInfo' = 1,
  'Rationale' = 3,
  'Document' = 5,
  'OtherElements' = 4,
  'Taxonomy' = 6,
  'Validation' = 7,
}

export enum DemandRequestType {
  'Document' = 5,
  'Demographics' = 6,
  'OtherElements' = 7,
}

export interface viewInvoice {
  header?: string;
  invoiceDetails: invoiceDetails;
  isProjectManagementClientProjectLeader: boolean;
  isProjectProcurementLeader: boolean;
  isProjectManagementFirmPartner: boolean;
  isAdminIncluded: boolean;
  projectId: string;
  isCommonTable: boolean;
}
export interface invoiceDetails {
  projectName: string;
  amount: number;
  amountInInvoiceCurrency: number;
  targetPaymentDate: Date;
  actualAmount?: number;
  actualAmountInInvoiceCurrency?: number;
  firmName: string;
  estimatedDate: Date;
  paymentStatus: string;
  submittedOn: Date;
  invoice: string;
  actualDate: Date;
  approvedOn: Date;
  paymentDoneBy: string;
  timeSheetDocument: Object;
  invoiceNumber: Number;
  paymentDoneOn: Date;
  currencySymbol?: string;
  invoiceCurrencySymbol?: string;
  applicationFees?: Number;
}

export interface TemplateDocType {
  file: File | null;
  type: number | undefined;
  key: string | undefined;
  name: string | undefined;
  isNewUpload?: boolean;
  index: number;
  isTemplateRes?: boolean;
}
export interface ChipValue {
  type: number;
  name: string;
  oldItem: string;
  newItem: string;
  _id?: string;
}
export interface DocumentOrLink {
  file: File | null;
  type: number | undefined;
  key: string | undefined;
  name: string | undefined;
  isNewUpload?: boolean;
  isUploaded?: boolean;
}

export interface Risk {
  _id?: string;
  name: string;
  isDefault: boolean;
  isMandatory: boolean;
  isSuperAdmin: boolean;
  clientId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  isDeleted: boolean;
  deletedAt: Date;
  deletedBy: string;
}

export interface ContentStyle {
  fontFamily: string;
  fontSize: string;
  fontStyle: string;
  color: string;
}

export interface OrganisationalAttributes {
  businessUnit: string;
  costCenters: string;
  functions: string;
}

export interface DynamicTemplateSections {
  title: string;
  fields: DynamicFormsValue[];
}

export interface taxonomyMandatoryFields {
  capabilities: boolean;
  industries: boolean;
  subCapablities: boolean;
  subIndustries: boolean;
  regions: boolean;
  businessUnit: boolean;
  countries: boolean;
  strategicLever: boolean;
  projectTier: boolean;
}

export interface InvoiceForm {
  amount?: number;
  taxPerc?: number;
  memo?: string;
  currency?: string;
  isUpdate?: boolean;
}

export interface ActionNature {
  _id: string;
  updatedAt: string | null;
  isDeleted: boolean;
  isDefault: boolean;
  text: string;
  createdAt: string;
  __v: number;
}

export interface GetActionNature {
  status: boolean;
  data: ActionNature[];
}

export interface AddActionNature {
  status: boolean;
  data: ActionNature;
}

export interface Announcement {
  title: string;
  content: string;
  _id?: string;
  createdAt?: Date;
  createdBy?: object;
  projectId?: object;
  isContentToggled?: boolean;
}
export interface LibraryFilter {
  businessUnitIds: string[];
  industryIds: string[];
  capabilityIds: string[];
  projectIds: string[];
  confidentiality: boolean;
  consultingFirm?: ConsultingFirm[];
}

export interface Library {
  projectName: string;
  provider?: string;
  deliverableName: string;
  description?: string;
  businessUnitIds: string | string[];
  capabilityIds: string[];
  industryIds?: string[];
  confidentiality: boolean;
  procurementLeaderId?: string;
  projectLeaderId?: string;
  document?: { name?: string; key: string; type: DOCUMENT_TYPE };
}

export interface FileFormat {
  _id: string;
  createdAt: string;
  createdBy: string;
  type: string;
  updatedAt: string | null;
  value: {
    fileFormat: string;
    fileSize: number;
  };
}

export interface FileFormatData {
  data: {
    fileFormat: FileFormat[];
    fileLimit: number;
    status: boolean;
  };
  status: boolean;
}

export interface clientDetails {
  firstName: string;
  lastName: string;
  email: string;
  _id?: string;
  companyName: string;
  role?: number;
}

export interface ApiSuccessResponse {
  status: string;
  data: {
    message: string;
  };
}

export interface fileInputDocument {
  file?: File | null;
  type: DOCUMENT_TYPE | number;
  key?: string | undefined;
  name?: string | undefined;
  isUploaded: boolean;
  size?: number;
}

export interface ActivityCard {
  userCount: number;
  providerCount: number;
  businessUnitCount: number;
  usersDeactivatedCount: number;
}

export interface ActivityTable {
  demandCreatedCount: number;
  validatedDemandCount: number;
  projectCount: number;
  sowSignedCount: number;
  surveySent: number;
  surveyCompletedPercent: number;
  averageSurveyCompletion: number;
}

export interface SowHomeDetails {
  projectLeader: user;
  providerLeader: user;
  projectName: string;
  provider: { firmName: string; profilePhoto: string };
  projectObjective: string;
  milestoneCount: number;
  milestoneLastDate: Date;
  maximumProjectBudget: number;
  currencySymbol: string;
  finalDocument: { fileKey: string; fileName: string; uploadedBy: user; uploadedOn: Date };
  lastExported: { fileKey: string; fileName: string; exportedBy: user; exportedAt: Date };
  companyId: string;
}

export interface RfpHomeDetails {
  projectFraming: {
    projectName: string;
    projectTeam: { _id: string; name: string; picture: string }[];
    rfpCreationDate: Date;
    rfpLeader: { _id: string; name: string; picture: string };
  };
  keyElements: {
    purpose: string;
    expectedStartDate: Date;
    expectedEndDate: Date;
    targetBudget: string;
    currencySymbol: string;
    proposalContent: string;
    milestones: { milestoneId: { name: string }; date: Date; time: Date; timeString: string; session: string }[];
  };
  documentations: {
    latestExport: { fileKey: string; fileName: string; exportedBy: user; exportedAt: Date };
    associatedDocuments: { fileKey: string; fileName: string; uploadedBy: user; uploadedOn: Date }[];
  };
}

export enum MarketplaceFilter {
  MARKETPLACE = 'marketplace',
  NON_MARKETPLACE = 'non-marketplace',
  ALL = 'all',
}

export interface Pipelines {
  _id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  isCurrent: boolean;
  initiativeCount: number;
  client: string;
}

export interface InitiativesCard {
  _id: string;
  name: string;
  status: string;
  order: number;
  pipelineId: string;
  leader: string;
  description: string;
  demandId: string;
  projectId: string;
  isReadWrite: boolean
}

export interface Color {
  _id?: string,
  color: string;
  name: string
}
