import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoContentComponent } from './no-content.component';
import { I18NextModule } from 'angular-i18next';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';



@NgModule({
  declarations: [NoContentComponent],
  imports: [
    CommonModule,
    I18NextModule,
    ConpulseMaterialModule,
    ConpulseModule
  ],
  exports: [NoContentComponent],
})
export class NoContentModule { }
