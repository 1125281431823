import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { TableData, UserService, AdminService, NotificationService, TemplateEditorMode, CONSTANTS, CompanyService } from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { NavigationEnd, Router } from '@angular/router';
import { AmountConversionPipe } from '../../pipes';
import { AddEditInvoiceComponent } from './add-edit-invoice/add-edit-invoice.component';
import { Subscription } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { get, isEmpty, range } from 'lodash-es';

@Component({
  selector: 'conpulse-web-client-billing',
  templateUrl: './client-billing.component.html',
  styleUrls: ['./client-billing.component.scss'],
})
export class ClientBillingComponent implements OnInit {
  invoiceBillingList = {} as TableData;
  applicableTax: number;
  clientId = '';
  isOpenDrawer = false;
  currencySymbol = '';
  minorUnits = 1;
  currencyId: string = '';
  routerSubscription: Subscription | null = null;
  isClient: boolean = false;
  isClientActive: boolean = false;

  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private utilityMethod: UtilityMethodsService,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private route: Router,
    private companyService: CompanyService,
    private amountConversionPipe: AmountConversionPipe,
    private i18Next: I18NextPipe
  ) {
    this.routerSubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        this.isClient = CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role);
        const route = event.url.split('/');
        if (
          ![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.userService.currentUserInformation?.role) ||
          !this.userService.currentUserInformation?.role
        ) {
          this.isClient = route.includes('client');
          this.invoiceBillingList.isAdmin = true;
        }
      }
      this.assignColumnsToDisplay();
    });
    this.clientId = CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role)
      ? this.userService.currentUserInformation?.companyId['_id']
      : CONSTANTS.CF_PROFILE_ROLES.includes(this.userService.currentUserInformation?.role)
      ? this.userService.currentUserInformation?.firmId['_id']
      : this.route.routerState.snapshot.url.split('/')[3];
  }

  ngOnInit() {
    this.invoiceBillingList.isLoading = true;
    this.invoiceBillingList.type = 'invoiceBilling';
    this.invoiceBillingList.selectedData = [];
    this.invoiceBillingList.page = 0;
    this.invoiceBillingList.limit = 10;
    this.invoiceBillingList.search = '';
    this.userService.refreshLanguageComp.subscribe(() => this.getInvoiceBilling());
    this.getInvoiceBilling();
    this.userService.getClientById(this.clientId).subscribe((data) => {
      this.currencyId = data.data.client.currencyId?._id;
      this.currencySymbol = data.data.client.currencyId?.symbol;
      this.minorUnits = data.data.client.currencyId?.minorUnits;
      this.isClientActive = data.data.client?.status;
    });
    this.getAccountDetails();
  }

  getAccountDetails() {
    this.adminService.getAccountDetailsForClients(this.clientId, this.isClient).subscribe({
      next: (response) => {
        this.applicableTax = response?.data.administrativeInfo?.applicableTax;
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to get details'));
      },
    });
  }

  getInvoiceBilling() {
    this.invoiceBillingList.isLoading = true;
    this.adminService
      .getInvoiceBilling(this.clientId, this.invoiceBillingList.page, this.invoiceBillingList.limit, this.invoiceBillingList.search)
      .subscribe((data) => {
        this.invoiceBillingList.data = get(data, 'data.billingInfo', []);
        this.invoiceBillingList.page = get(data, 'data.paginationData[0].page', 0);
        this.invoiceBillingList.limit = get(data, 'data.paginationData[0].limit', 10);
        this.invoiceBillingList.totalCount = get(data, 'data.paginationData[0].total', 0);
        this.invoiceBillingList.isAdmin = get(data, 'data.isSuperAdmin', false);
        this.invoiceBillingList.isLoading = false;
        this.pagination();
      });
  }

  /**Assign columns based user privilege */
  assignColumnsToDisplay() {
    /**Provides edit access for Super Admin,the display fields will be different for client and firm */
    const commonColumns = ['invoiceId', 'issuedOn', 'amount', 'status', 'paymentCompletedDate'];
    const clientColumns = commonColumns;
    clientColumns.splice(3, 0, 'taxPerc', 'amountDue');
    const adminClientColumns = [...clientColumns, 'actions'];
    const adminFirmColumn = [...commonColumns, 'actions'];

    if (!this.invoiceBillingList.isAdmin) {
      this.invoiceBillingList.displayedColumns = this.isClient ? [...clientColumns, 'viewInvoice', 'emptyDiv'] : [...commonColumns, 'viewInvoice', 'emptyDiv'];
    } else {
      this.invoiceBillingList.displayedColumns = this.isClient ? adminClientColumns : adminFirmColumn;
    }
  }

  openCustomerSessionPortal() {
    this.companyService.getStripeCustomerPortalLink().subscribe({
      next: (response) => {
        window.open(response.data, '_blank');
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to redirect to customer portal'));
      },
    });
  }

  pagination() {
    const noOfPages = Math.ceil(this.invoiceBillingList.totalCount / this.invoiceBillingList.limit);
    const page = this.invoiceBillingList.page;
    let startPage, endPage;
    if (noOfPages <= 3) {
      startPage = 1;
      endPage = noOfPages;
    } else {
      if (page <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (page + 3 >= noOfPages) {
        startPage = noOfPages - 2;
        endPage = noOfPages;
      } else {
        startPage = page - 1;
        endPage = page + 1;
      }
    }
    this.invoiceBillingList.endPage = noOfPages;
    this.invoiceBillingList.pagesArray = range(startPage, endPage + 1);
    // check for blank page
    if (isEmpty(this.invoiceBillingList.data) && this.invoiceBillingList.totalCount > 0) {
      this.invoiceBillingList.page--;
      this.onChange();
    }
  }

  onChange() {
    this.invoiceBillingList.data = [];
    this.getInvoiceBilling();
  }

  toggleAddBillingDrawer(type: string, event) {
    const findInvoice = this.invoiceBillingList.data.find((invoice: { _id: string; amount: string }) => invoice._id === event.id);
    if (event?.status) {
      this.invoiceBillingList.isLoading = true;
      this.adminService
        .createOrUpdateInvoiceBilling({
          _id: event.id,
          status: event.status,
          currency: event?.currency,
          clientId: this.clientId,
          isUpdate: true,
          amount: findInvoice['amount'],
          isLegalEntity: !isEmpty(findInvoice.legalEntityId),
          taxPerc: event.applicableTax ?? null
        })
        .subscribe(
          (response) => {
            this.invoiceBillingList.isLoading = false;
            this.notificationService.openSuccessSnackBar(this.i18Next.transform(`Invoice Updated successfully`));
            this.getInvoiceBilling();
          },
          (error) => {
            this.notificationService.openErrorSnackBar(error.message);
            this.invoiceBillingList.isLoading = false;
          }
        );
    } else {
      const dialogRef = this.dialog.open(AddEditInvoiceComponent, {
        width: '500px',
        disableClose: true,
        data: {
          clientId: this.clientId,
          defaultCurrency: this.currencyId,
          buttonName: type === TemplateEditorMode.ADD ? 'Add Invoice' : 'Update Invoice',
          title: type === TemplateEditorMode.ADD ? 'Add Invoice' : 'Edit Invoice',
          isEdit: type === TemplateEditorMode.EDIT,
          invoiceDetails: findInvoice,
          minorUnit: event?.['minorUnit'],
          applicableTax: this.applicableTax,
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.getInvoiceBilling();
        }
      });
    }
  }

  clearSearch() {
    if (!isEmpty(this.invoiceBillingList.search)) {
      this.invoiceBillingList.data = [];
      this.invoiceBillingList.search = '';
      this.getInvoiceBilling();
    }
  }
}
