import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[conpulseWebMinValueValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }],
})
export class MinValueValidatorDirective implements Validator {
  @Input('minValue') minValue: number = 1;
  @Input('maxValue') maxValue: number = 1;
  @Input('isMinMaxValidation') isMinMaxValidation: boolean = false;
  @Input('isMin') isMin: boolean = false;
  @Input('isMax') isMax: boolean = false;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value !== null && control.value !== undefined) {
      const value = +control.value; // Convert to number
      if (value < 0) {
        return { invalid: true };
      }

      if (!this.isMinMaxValidation && value < this.minValue) {
        return { minValue: true };
      }

      if (this.isMinMaxValidation) {
        if (this.isMin && value > this.maxValue) {
          return { minError: true };
        }
        if (this.isMax && value < this.minValue) {
          return { maxError: true };
        }
      }
    }
    return null;
  }
}
