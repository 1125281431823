import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UserService } from '../apis/index';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { CONSTANTS } from '../constants';
import { get, isEmpty } from 'lodash-es';

@Injectable()
export class NotVerifiedGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private auth: AuthService, @Inject(DOCUMENT) private document: any) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.userService.checkToken(`validateLoginWithInfo`).pipe(
      map((response) => {
        if (!get(response, 'user.isVerified', false)) {
          if(get(response, 'user.role', -1) ===  CONSTANTS.role.clientAdmin && !isEmpty(get(response, 'user.companyId', ''))) {
            this.router.navigate(['/create-sandbox']);
            return false
          } else {
            return true;
          }
        }
        this.document.location.href = this.document.location.origin + '/signin';
        return false;
      }),
      catchError((err) => {
        this.document.location.href = this.document.location.origin + '/signin';
        return of(false);
      })
    );
  }
}
