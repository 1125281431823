import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { CreateTemplateExistingComponent } from './create-template-existing.component';
import { ConpulseMaterialModule } from '../../../material-module';

@NgModule({
  declarations: [CreateTemplateExistingComponent],
  imports: [CommonModule, ConpulseMaterialModule, FormsModule, I18NextModule],
})
export class CreateTemplateExistingModule {}
