import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform(dropDownData: string[], filter: string): string[] {
    if (!dropDownData || !filter) {
        return dropDownData;
    }
    // filter dropDownData array, dropDownData which match and return true will be
    // kept, false will be filtered out
    return dropDownData.filter(matchedData => matchedData.indexOf(filter) !== -1);
}

}
