import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonAppendixDrawerComponent } from './common-appendix-drawer.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { I18NextModule } from 'angular-i18next';
import { FileInputModule } from '../file-input/file-input.module';

@NgModule({
  declarations: [CommonAppendixDrawerComponent],
  imports: [CommonModule, ConpulseMaterialModule, ConpulseModule, I18NextModule, FileInputModule],
  exports: [CommonAppendixDrawerComponent],
})
export class CommonAppendixDrawerModule {}
