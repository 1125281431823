import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule, ConpulseModule } from 'libs/conpulse/src/index';
import { I18NextModule } from 'angular-i18next';
import { SelectConsultingfirmComponent } from './select-consultingfirm.component';
import { HelpCenterModule } from '../help-center/help-center.module';
import { SupportContactModule } from '../support-contact/support-contact.module';

@NgModule({
  declarations: [SelectConsultingfirmComponent],
  imports: [CommonModule, FormsModule, ConpulseMaterialModule, I18NextModule, ConpulseModule, HelpCenterModule, SupportContactModule],
  exports: [SelectConsultingfirmComponent],
})
export class SelectConsultingfirmModule {}
