import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConpulseMaterialModule } from '../../material-module/index';
import { CommonScatterChartComponent } from './common-scatter-chart.component';
import { ChartsModule } from 'ng2-charts';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ConpulseMaterialModule,
    ChartsModule
  ],
  declarations: [
    CommonScatterChartComponent
  ],
  exports: [
    CommonScatterChartComponent
  ]
})
export class CommonScatterChartModule {
}
