import { EventEmitter, Injectable } from '@angular/core';

import { ClientFirmLink, ConsultingFirm, RfpMilestone, RfpSelectionCriterion, TableData, RiskData, Library, fileInputDocument } from '../interfaces';
import { BaseApiService } from './base.api';
import { DisplayEditableFields, ORG_ATTRIBUTE } from '../constants';
import { BehaviorSubject } from 'rxjs';
import { get, isEmpty, map } from 'lodash-es';

const companyRoutes = {
  linkedFirmsList: 'company/firms',
  allLinkedFirmsList: 'client/all/linked-firm',
  linkedFirmsTable: 'company/consultingFirm',
  linkedFirm: 'company/linkedFirm',
  addPartner: 'company/consultingFirm/add-partner',
  linkedFirmOrCngList: 'company/topConsultingFirm',
  suggestFirm: 'company/market-exploration/suggest-company',
  consultingFirms: 'company/firm/toggle-activation',
  onBoarding: {
    addSection: 'client/customization/onboarding-data',
    deleteSection: 'client/customization/onboarding-data/{sectionId}',
    template: 'client/customization/onboarding-data/template',
    duplicateTemplate: 'client/customization/onboarding-data/duplicate-template',
    templateBasedOnTemplate: 'client/onboarding/template',
    templateLanguage: 'client/onboarding/template-language',
    list: 'company/legal-entity-list',
    linkQuestionnaire: '/client/legal-entity/onboarding/questionnaire',
  },
  onboardingData: 'company/onboarding-question-set',
  validateOnboardingData: 'firm/legal-entities/onboardingstatus',
  deleteLegalEntity: 'company/delete/legal-entity',
  ChangeOnboarding: '/company/legal-entity/change-req',
  risk: {
    getRisk: '/client/risk/{legalEntityId}',
    archiveData: '/client/risk/archive/{legalEntityId}',
  },
  proposalCharts: 'proposal/pricing-chart/{projectId}',
  titleUpdate: 'company/organisation-attribute/title/{titleId}',
  organizationAttributes: 'company/organisation-attribute/terminology',
  taxonomyFieldSettings: '/client/demand/taxonomyMandatoryField',
  pipelinetaxonomyFieldSettings: '/client/pipeline/taxonomyMandatoryField',
  stripeCustomerPortal: '/client/customer-portal',
  adminRequestList: '/client/role-change',
  getAdminCurrecnyList: 'admin-currency',
  ProjectCurrenyList: 'project-currency',
  getClientFirmLinkByClientIdAndFirmId: 'consulting-firm-link',
  riskData: 'risk-data',
  library: 'knowledge-center/library',
  updateDeleteLibrary: 'knowledge-center/library/{libraryId}',
  projectOptionsList: 'company/project/options',
  updateProviderSettings: 'firm/customization/details',
  userRoles: 'roles/company',
  clientProfile: 'client/profile',
  companyRoles: '/roles/based-on-company',
  clintModule: '/admin/permission-module',
  strategicLevers: 'company/strategic-levers',
  clientProfiles: 'company/{companyId}/profile-tabs',
  kysQuestionData: 'kys-question-set',
  clientProfileContract: 'provider/client/contracts',
  clientProfileMeeting: '/provider/client/meetings',
  clientProfileTask: '/provider/client/task',
  clientProviderRelationShipManagers: 'client/provider-panel/relationship-managers',
  addWorkspaceFiles: 'workspace/document',
  deleteWorkspace: 'workspace',
  activity: 'company/activity/{type}',
  providerListBasedOnProject: 'company/project/provider-list',
  providerListBasedOnContract: 'company/contract/provider-list',
  updatePricingVisibility: 'company/pricing-visibility',
  updateScoringDocuments: 'company/project/{projectId}/team-scoring/documents',
};

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public refreshOnBoardingPage$: EventEmitter<{ data: boolean; templateId: string }> = new EventEmitter<{ data: boolean; templateId: string }>();
  public companyOrganisationAttributes$: BehaviorSubject<{ businessUnit: string; functions: string; costCenters: string }> = new BehaviorSubject<{
    businessUnit: string;
    functions: string;
    costCenters: string;
  }>({} as { businessUnit: string; functions: string; costCenters: string });
  existingAttributes: { businessUnit: string; functions: string; costCenters: string } = {} as { businessUnit: string; functions: string; costCenters: string };
  constructor(private baseApi: BaseApiService) {}
  /**
   * Gets a list of client-linked consulting firms
   * @param values Table Data
   * @returns List of client-linked consulting firms
   */
  getClientFirmLinksList(values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('status', get(values, 'status', ''));
    params.append('toggleType', get(values, 'toggleValue', ''));

    if (!isEmpty(get(values, 'filter.industry', []))) {
      params.append('industry', values?.filter?.industry.join(','));
    }
    if (!isEmpty(get(values, 'filter.partner', []))) {
      params.append('partner', values?.filter?.partner.join(','));
    }
    if (!isEmpty(get(values, 'filter.capability', []))) {
      params.append('capability', values?.filter?.capability.join(','));
    }
    if (!isEmpty(get(values, 'filter.region', []))) {
      params.append('region', values?.filter?.region.join(','));
    }
    if (!isEmpty(get(values, 'filter.state', []))) {
      params.append('country', values?.filter?.state.join(','));
    }
    if (!isEmpty(get(values, 'filter.other', []))) {
      params.append('partner', values?.filter?.other.join(','));
    }
    if (!isEmpty(get(values, 'filter.tier', []))) {
      params.append('tier', values?.filter?.tier.join(','));
    }
    if (!isEmpty(get(values, 'filter.segment', []))) {
      params.append('segment', values?.filter?.segment.join(','));
    }
    const url = `${companyRoutes.linkedFirmsTable}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * Gets list of client-linked consulting firms for auto-complete list
   * @param search Search key
   * @param limit Page limit (default 10)
   * @returns List of client-linked consulting firms for auto-complete list
   */
  getConsultingFirmsAutoComplete(search: string, limit: number = 10) {
    const params = new URLSearchParams();
    if (search?.length) params.append('search', search);
    params.append('page', '0');
    params.append('limit', `${limit}`);
    return this.baseApi.httpGet(`${companyRoutes.linkedFirmsList}?${params.toString()}`);
  }

  /**
   * get all linked firm
   */
  getAllLinkedFirm() {
    return this.baseApi.httpGet(companyRoutes.allLinkedFirmsList);
  }

  /**
   * Gets a client-firm link by it's unique ID.
   * @param clientFirmLinkId Unique ID of the client-firm link
   * @returns Client-Firm Link Record
   */
  getLinkedConsultingFirmById(clientFirmLinkId: string) {
    return this.baseApi.httpGet(`${companyRoutes.linkedFirm}/${clientFirmLinkId}`);
  }

  /**
   * Activate or deactivate client linked firm
   */
  activateOrDeactivateClientFirm(consultingFirmId: string, isActive: boolean) {
    return this.baseApi.httpPut(`${companyRoutes.consultingFirms}/${consultingFirmId}`, { isActive });
  }

  /**
   * Creates/updates a client-firm link
   * @param clientFirmLinkId Unique ID of the client-firm link
   * @returns Created/updated client-firm link record
   */
  createOrUpdateClientFirmLink(clientFirmLink: ClientFirmLink) {
    return this.baseApi.httpPost(`${companyRoutes.linkedFirm}`, clientFirmLink);
  }

  /**
   * Deletes client-firm links by it's unique IDs
   * @param clientFirmLinkId Unique IDs of client-firm links
   * @returns Delete response
   */
  deleteClientFirmLinkByIds(clientFirmLinkIds: string[]) {
    const payload = { clientFirmLinkIds };
    return this.baseApi.httpPut(`${companyRoutes.linkedFirm}`, payload);
  }

  /**
   * add clint link partner
   */
  addConsultingFirmPartner(firmId, values) {
    return this.baseApi.httpPost(`${companyRoutes.addPartner}/${firmId}`, values);
  }

  /**
   * delete clint link partner
   */
  deletePartnerFromClintLink(firmId, values) {
    return this.baseApi.httpPut(`${companyRoutes.addPartner}/${firmId}`, values);
  }

  /**
   * get client link partner list
   */
  getConsultingFirmPartnerList(values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    const firmId = values.firmId;
    const url = `${companyRoutes.addPartner}/${firmId}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * get link firm or CNG list
   */
   getLinkedFirmList(values) {
    const params = new URLSearchParams();
    if (values.sortBy) params.append('sortBy', values.sortBy);
    if (values.sortOrder) params.append('sortOrder', values.sortOrder);
    if (values.search) params.append('search', values.search);
    if (values.isLinkedFirm) params.append('isLinkedFirm', values.isLinkedFirm);
    if (values.projectId) params.append('projectId', values.projectId);
    const url = `${companyRoutes.linkedFirmOrCngList}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }
  /*
   * get client suggest list
   */
  getClientSuggestList(values: TableData) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${companyRoutes.suggestFirm}?${params.toString()}`);
  }

  /**
   * Add or Update on Boarding section and questions
   * @param values details of add and update
   * @param isAdd Add or update boolean
   * @returns updated status
   */
  addOrUpdateOnBoardingSection(values: object, isAdd: boolean) {
    if (isAdd) {
      return this.baseApi.httpPost(`${companyRoutes.onBoarding.addSection}`, values);
    }
    return this.baseApi.httpPut(`${companyRoutes.onBoarding.addSection}`, values);
  }

  /**
   * Delete on Boarding section and questions
   * @param sectionId Unique Id of section
   * @param questionId Unique Id of questions
   * @returns Deleted status
   */
  deleteOnBoardingDetails(sectionId: string, questionId?: string) {
    const url = companyRoutes.onBoarding.deleteSection.replace('{sectionId}', sectionId);
    const params = new URLSearchParams();
    if (questionId?.length) params.append('questionId', questionId);
    return this.baseApi.httpDelete(`${url}?${params.toString()}`);
  }

  /**
   * Reorder Onboarding details
   * @param sectionId Unique Id of section
   * @param values reorder PUT details
   * @returns reorder status
   */
  reorderOnBoardingDetails(sectionId: string, values: { isSection: boolean; fromIndex: number; toIndex: number }, templateId) {
    const url = companyRoutes.onBoarding.deleteSection.replace('{sectionId}', sectionId);
    return this.baseApi.httpPut(url, { ...values, templateId: templateId });
  }

  /*
   * get onboarding details for client customization
   */
  getOnBoardingDetails(templateId, isDemand = false, isCustomization = true) {
    const params = new URLSearchParams();
    params.append('templateId', templateId);
    params.append('isDemand', isDemand.toString());
    params.append('isCustomization', isCustomization.toString());
    return this.baseApi.httpGet(`${companyRoutes.onBoarding.addSection}?${params.toString()}`);
  }
  /*
   * get client onboarding data list
   */
  getClientOnboardingData(values) {
    const params = new URLSearchParams();
    params.append('entityId', `${values?.legalEntityId}`);
    params.append('companyId', `${values?.companyId}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${companyRoutes.onboardingData}?${params.toString()}`);
  }

  /**
   * validate onboarding data from client side
   */
  validateOnboardingData(legalEntityId, firmId, values) {
    return this.baseApi.httpPost(`${companyRoutes.validateOnboardingData}/${firmId}/${legalEntityId}`, values);
  }

  deleteLegalEntity({ firmId, entityId }: { firmId: string; entityId: string }) {
    const url = `${companyRoutes.deleteLegalEntity}/${entityId}/${firmId}`;
    return this.baseApi.httpDelete(url);
  }
  /**
   * Send request to client to change the onboarding data
   * @param legalEntityId
   * @param clientId
   * @param onBoardingData
   * @param firmId
   * @returns
   */
  saveRequestForOnboardingData(legalEntityId, clientId, onBoardingData, firmId) {
    const url = `${companyRoutes.ChangeOnboarding}/${legalEntityId}/${firmId}`;
    return this.baseApi.httpPut(url, { onBoardingData: onBoardingData, clientId: clientId });
  }

  /**
   * get onboarding template
   * @returns
   */
  getLegalEntityTemplate(isDemand: boolean) {
    const params = new URLSearchParams();
    params.append('isDemand', JSON.stringify(isDemand));
    return this.baseApi.httpGet(`${companyRoutes.onBoarding.template}?${params.toString()}`);
  }

  /**
   * add onboarding template
   * @param {templateName, language}
   */
  addOnboardingTemplate(values: { templateName: string; languageId: string; isDemand: boolean }) {
    return this.baseApi.httpPost(companyRoutes.onBoarding.template, values);
  }

  /**
   * update onboarding template
   * @param {templateName, language}
   */
  updateOnboardingTemplate(values: { templateName: string; languageId: string; templateId: string; isDemand: boolean }) {
    return this.baseApi.httpPut(companyRoutes.onBoarding.template, values);
  }

  /**
   * delete onboarding template
   * @param {templateId}
   */
  deleteOnboardingTemplate(values: { templateId: string }) {
    return this.baseApi.httpDelete(companyRoutes.onBoarding.template, values);
  }

  /**
   * clone onboarding template
   * @param values {templateId}
   * @returns
   */
  duplicateOnboardingTemplate(values: { templateId: string }) {
    return this.baseApi.httpPost(companyRoutes.onBoarding.duplicateTemplate, values);
  }

  /**
   * get onboarding template based on the language
   */
  getTemplateBasedOnLanguage(languageId: string, isDemand = false) {
    return this.baseApi.httpGet(`${companyRoutes.onBoarding.templateBasedOnTemplate}/${languageId}?isDemand=${isDemand}`);
  }

  /**
   * get language existing in on boarding template
   */
  getOnboardingTemplateLanguage(isDemand = false) {
    return this.baseApi.httpGet(`${companyRoutes.onBoarding.templateLanguage}?isDemand=${isDemand}`);
  }

  /**
   * Gets the data for risk
   * @returns
   */
  getRiskForLegalEntity(legalEntityId: string, isArchived: boolean, paginationData?: { page: number; limit: number }) {
    const url = companyRoutes.risk.getRisk.replace('{legalEntityId}', legalEntityId);
    const params = new URLSearchParams();
    if (paginationData?.page >= 0) params.append('page', JSON.stringify(paginationData?.page));
    if (paginationData?.limit) params.append('limit', JSON.stringify(paginationData?.limit));
    params.append('isArchived', isArchived.toString());
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * add the data for risk
   * @returns
   */
  addRiskForLegalEntity(legalEntityId: string, value: RiskData) {
    const url = companyRoutes.risk.getRisk.replace('{legalEntityId}', legalEntityId);
    const params = new URLSearchParams();
    return this.baseApi.httpPost(`${url}?${params.toString()}`, value);
  }

  /**
   * update the data for risk
   * @returns
   */
  editRiskForLegalEntity(legalEntityId: string, value: RiskData) {
    const url = companyRoutes.risk.getRisk.replace('{legalEntityId}', legalEntityId);
    return this.baseApi.httpPut(url, value);
  }

  /**
   * delete the data for risk
   * @returns
   */
  deleteRiskForLegalEntity(legalEntityId: string, riskId: string) {
    const url = companyRoutes.risk.getRisk.replace('{legalEntityId}', legalEntityId);
    const params = new URLSearchParams();
    params.append('riskId', riskId);
    return this.baseApi.httpDelete(`${url}?${params.toString()}`);
  }

  /**
   * Move the risk data to archive list
   * @param legalEntityId
   * @param riskId
   * @returns
   */
  archiveRiskForLegalEntity(legalEntityId: string, value: { riskId: string; isArchived: boolean }) {
    const url = companyRoutes.risk.archiveData.replace('{legalEntityId}', legalEntityId);
    return this.baseApi.httpPut(url, value);
  }

  /*
   * get all onboarding list from based on status
   */
  getOnboardingList(values, status = 'all') {
    const params = new URLSearchParams();
    if (values?.page) params.append('page', values.page);
    if (values?.limit) params.append('limit', values.limit);
    if (values?.search) params.append('search', values.search);
    if (status) params.append('status', status);
    return this.baseApi.httpGet(`${companyRoutes.onBoarding.list}?${params.toString()}`);
  }

  getProposalChartDetails(projectId: string) {
    return this.baseApi.httpGet(`${companyRoutes.proposalCharts.replace('{projectId}', projectId)}`);
  }

  /**
   * Update Company Attribute Title
   */
  updateTitle(titleId: DisplayEditableFields, updatedTitle: string) {
    return this.baseApi.httpPut(`${companyRoutes.titleUpdate.replace('{titleId}', titleId)}`, { title: updatedTitle });
  }

  /**
   * Update Company Attribute Title's subject
   */
  updateClientOrganisationTerminology(organisationAttribute: { businessUnit: string; functions: string; costCenters: string }) {
    this.companyOrganisationAttributes$.next(organisationAttribute);
    this.existingAttributes = organisationAttribute;
  }

  /**
   * Update Company Attribute Title by type
   */
  updateOrganisationTitle(type: string, title: string) {
    this.existingAttributes = { ...this.existingAttributes, [ORG_ATTRIBUTE[type]]: title };
    this.updateClientOrganisationTerminology(this.existingAttributes);
  }

  /**
   * Update Company Attribute Title
   */
  getClientOrganisationTerminology() {
    return this.companyOrganisationAttributes$.asObservable();
  }
  /**
   * Update Company Attribute Title
   */
  getClientOrganisationAttribute(projectId?: string) {
    const params = new URLSearchParams();
    if (projectId) params.append('projectId', projectId);
    return this.baseApi.httpGet(`${companyRoutes.organizationAttributes}?${params.toString()}`);
  }

  linkQuestionnaire(values) {
    return this.baseApi.httpPost(`${companyRoutes.onBoarding.linkQuestionnaire}/${values.entityId}`, values);
  }

  /**
   * The function updates the mandatory fields for taxonomy.
   * @param values - The `values` parameter is an object that contains the updated values for the
   * taxonomy mandatory fields. It could include properties such as field names, field types, field
   * labels, field descriptions, etc.
   * @returns the result of the HTTP PUT request made to the specified
   * companyRoutes.taxonomyFieldSettings endpoint with the provided values.
   */
  updateTaxonomyMandatoryFields(values) {
    return this.baseApi.httpPut(companyRoutes.taxonomyFieldSettings, values);
  }

  updatePipelineTaxonomyMandatoryFields(values) {
    return this.baseApi.httpPut(companyRoutes.pipelinetaxonomyFieldSettings, values);
  }

  /**
   * The function `getTaxonomyMandatoryFields()` makes an HTTP GET request to retrieve the taxonomy
   * field settings.
   * @returns the result of an HTTP GET request to the `companyRoutes.taxonomyFieldSettings` endpoint.
   */
  getTaxonomyMandatoryFields() {
    return this.baseApi.httpGet(companyRoutes.taxonomyFieldSettings);
  }

  getPipelineTaxonomyMandatoryFields() {
    return this.baseApi.httpGet(companyRoutes.pipelinetaxonomyFieldSettings);
  }

  getStripeCustomerPortalLink() {
    return this.baseApi.httpGet(companyRoutes.stripeCustomerPortal);
  }

  getAdminRequestList(status: string, isFirm = false) {
    const params = new URLSearchParams();
    params.append('status', status);
    params.append('isFirm', isFirm.toString());
    return this.baseApi.httpGet(`${companyRoutes.adminRequestList}?${params.toString()}`);
  }

  getAdminCurrecnyList() {
    return this.baseApi.httpGet(companyRoutes.getAdminCurrecnyList);
  }

  addProjectCurreny(selectedCurrencyList) {
    return this.baseApi.httpPost(companyRoutes.ProjectCurrenyList, selectedCurrencyList);
  }

  getProjectCurrenyList(currencyTable: TableData) {
    const params = new URLSearchParams();
    params.append('search', currencyTable.search);
    const url = `${companyRoutes.ProjectCurrenyList}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * The function `deleteProjectCurrency` deletes a project currency by making an HTTP DELETE request
   * to a specific URL.
   * @param {string} currencyId - The `currencyId` parameter is a string that represents the unique
   * identifier of a project currency.
   * @returns the result of the `httpDelete` method call.
   */
  deleteProjectCurrency(currencyId: string) {
    const url = `${companyRoutes.ProjectCurrenyList}/${currencyId}`;
    return this.baseApi.httpDelete(url);
  }

  getClientFirmLinkByClientIdAndFirmId(firmId: string) {
    const params = new URLSearchParams();
    params.append('firmId', firmId);

    const url = `${companyRoutes.getClientFirmLinkByClientIdAndFirmId}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /*
   * get all risk list for all linked provider
   */
  getRiskList(values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('search', values.search);
    params.append('sortOrder', values.sortOrder);
    if (!isEmpty(get(values, 'filter.endDate', null))) {
      params.append('endDate', values.filter.endDate);
    }
    if (!isEmpty(get(values, 'filter.startDate', null))) {
      params.append('startDate', values.filter.startDate);
    }
    if (get(values, 'filter.riskLevel', 0)) {
      params.append('riskLevel', get(values, 'filter.riskLevel', 0).toString());
    }
    if (!isEmpty(get(values, 'filter.consultingFirm', []))) {
      map(values.filter.consultingFirm, (firm) => {
        params.append('consultingFirm', firm);
      });
    }
    return this.baseApi.httpGet(`${companyRoutes.riskData}?${params.toString()}`);
  }

  addLibraryDocument(values: Library) {
    return this.baseApi.httpPost(companyRoutes.library, values);
  }

  /*
   * get all projects linked to user
   */
  getProjectOptionsList(values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('search', values.search);

    if (!isEmpty(get(values, 'selectedProjects', []))) {
      values.selectedProjects.map((project) => {
        params.append('projectIds', project);
      });
    }
    return this.baseApi.httpGet(`${companyRoutes.projectOptionsList}?${params.toString()}`);
  }

  updateConfidentiality(libraryId: string) {
    const url = companyRoutes.updateDeleteLibrary.replace('{libraryId}', libraryId);
    return this.baseApi.httpPut(url);
  }

  deleteLibraryDocument(libraryId: string) {
    const url = companyRoutes.updateDeleteLibrary.replace('{libraryId}', libraryId);
    return this.baseApi.httpDelete(url);
  }

  updateProviderSettings(languageId: string) {
    return this.baseApi.httpPost(companyRoutes.updateProviderSettings, { languageId });
  }

  getCompanyUserRoles(query: { isEdit: boolean }) {
    const params = new URLSearchParams();
    params.append('isEdit', JSON.stringify(query.isEdit));
    return this.baseApi.httpGet(`${companyRoutes.userRoles}?${params.toString()}`);
  }

  updateCompanyProfile(query: { type: string }, body: object) {
    const params = new URLSearchParams();
    params.append('type', query.type);
    return this.baseApi.httpPut(`${companyRoutes.clientProfile}?${params.toString()}`, body);
  }

  getUserRoleBasedCompany(query: { isEdit: boolean }) {
    const params = new URLSearchParams();
    params.append('isEdit', JSON.stringify(query.isEdit));
    return this.baseApi.httpGet(`${companyRoutes.companyRoles}?${params.toString()}`);
  }

  getClientModule(companyId) {
    const params = new URLSearchParams();
    params.append('clientId', companyId);
    return this.baseApi.httpGet(`${companyRoutes.clintModule}?${params.toString()}`);
  }

  updateClientModule(companyId, enabledModule) {
    const params = new URLSearchParams();
    params.append('clientId', companyId);
    return this.baseApi.httpPut(`${companyRoutes.clintModule}?${params.toString()}`, { enabledModule });
  }

  getStrategicLevers() {
    return this.baseApi.httpGet(companyRoutes.strategicLevers);
  }

  createOrUpdateStrategicLevers(data) {
    return this.baseApi.httpPost(companyRoutes.strategicLevers, data);
  }

  deleteStrategicLevers(data) {
    return this.baseApi.httpPut(companyRoutes.strategicLevers, data);
  }

  getCompanyContract(clientId: string, values) {
    const params = new URLSearchParams();
    params.append('companyId', clientId);
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('search', values.search);
    params.append('sortOrder', values.sortOrder);
    params.append('status', values.contractStatus);
    if (values?.filter?.providerLeader?.length) {
      values.filter.providerLeader.map((leader) => {
        params.append('providerLeaderId', leader);
      });
    }
    if (values?.filter?.type?.length) {
      values.filter.type.map((type) => {
        params.append('type', type);
      });
    }
    if (values?.filter?.creationStartDate) {
      params.append('creationStartDate', values.filter.creationStartDate);
    }
    if (values?.filter?.creationEndDate) {
      params.append('creationEndDate', values.filter.creationEndDate);
    }
    if (values?.filter?.validityEndDate) {
      params.append('validityEndDate', values.filter.validityEndDate);
    }
    if (values?.filter?.validityStartDate) {
      params.append('validityStartDate', values.filter.validityStartDate);
    }
    if (values?.filter?.renewalStartDate) {
      params.append('renewalStartDate', values.filter.renewalStartDate);
    }
    if (values?.filter?.renewalEndDate) {
      params.append('renewalEndDate', values.filter.renewalEndDate);
    }
    return this.baseApi.httpGet(`${companyRoutes.clientProfileContract}?${params.toString()}`);
  }

  getMeetingsList(companyId, values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('isClientProfile', 'true');
    params.append('clientId', companyId);

    if (values?.filter?.startDate) {
      params.append('startDate', values.filter.startDate);
    }
    if (values?.filter?.endDate) {
      params.append('endDate', values.filter.endDate);
    }
    if (Array.isArray(values?.filter?.nature) && values.filter.nature.length > 0) {
      values.filter.nature.forEach((nature) => {
        params.append('nature', nature);
      });
    }
    if (Array.isArray(values?.filter?.companyAttendees) && values.filter.companyAttendees.length > 0) {
      values.filter.companyAttendees.forEach((attendees) => {
        params.append('companyAttendees', attendees);
      });
    }
    if (Array.isArray(values?.filter?.consultingFirmAttendees) && values.filter.consultingFirmAttendees.length > 0) {
      values.filter.consultingFirmAttendees.forEach((attendees) => {
        params.append('consultingFirmAttendees', attendees);
      });
    }
    return this.baseApi.httpGet(`${companyRoutes.clientProfileMeeting}?${params.toString()}`);
  }

  getCompanyActionList(companyId, values) {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('companyId', companyId);

    if (Array.isArray(values?.filter?.category) && values.filter.category.length > 0) {
      values.filter.category.forEach((category) => {
        params.append('category', category);
      });
    }

    if (Array.isArray(values?.filter?.owner) && values.filter.owner.length > 0) {
      values.filter.owner.forEach((owner) => {
        params.append('owner', owner);
      });
    }

    if (typeof values?.filter?.status === 'number' && values?.filter.isArchived !== 1) {
      params.append('status', values.filter.status);
    }

    if (Array.isArray(values?.filter?.createdBy) && values.filter.createdBy.length > 0) {
      values.filter.createdBy.forEach((createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (values?.filter?.assignedDateStart) {
      params.append('assignedDate.start', values.filter.assignedDateStart);
    }

    if (values?.filter?.assignedDateEnd) {
      params.append('assignedDate.end', values.filter.assignedDateEnd);
    }

    if (values?.filter?.dueDateStart) {
      params.append('dueDate.start', values.filter.dueDateStart);
    }

    if (values?.filter?.dueDateEnd) {
      params.append('dueDate.end', values.filter.dueDateEnd);
    }

    if (typeof values?.isArchived === 'number') {
      params.append('isArchived', values.isArchived);
    }
    return this.baseApi.httpGet(`${companyRoutes.clientProfileTask}?${params.toString()}`);
  }

  getCompanyProfileTabs(companyId: string) {
    const url = companyRoutes.clientProfiles.replace('{companyId}', companyId);
    return this.baseApi.httpGet(url);
  }

  /*
   * get client onboarding data list
   */
  getKysQuestionData(legalEntityId: string, firmId: string) {
    const params = new URLSearchParams();
    params.append('entityId', legalEntityId);
    params.append('firmId', firmId);
    return this.baseApi.httpGet(`${companyRoutes.kysQuestionData}?${params.toString()}`);
  }

  getClientProviderRelationshipManagers() {
    return this.baseApi.httpGet(companyRoutes.clientProviderRelationShipManagers);
  }

  addWorkspaceDocuments(details: { projectId: string; documents: { name: string; key: string }[] }) {
    return this.baseApi.httpPost(companyRoutes.addWorkspaceFiles, details);
  }

  deleteWorkspaceDocument(documentId: string) {
    return this.baseApi.httpDelete(`${companyRoutes.deleteWorkspace}/${documentId}`);
  }

  getCompanyActivity(options: object) {
    const params = new URLSearchParams();
    params.append('startDate', options?.['startDate'] ?? '');
    params.append('endDate', options?.['endDate'] ?? '');
    const url = companyRoutes.activity.replace('{type}', options['type']);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  getProviderAssociatedWithProject() {
    return this.baseApi.httpGet(companyRoutes.providerListBasedOnProject);
  }

  getProviderAssociatedWithContract() {
    return this.baseApi.httpGet(companyRoutes.providerListBasedOnContract);
  }

  updatePricingVisibility() {
    return this.baseApi.httpPut(companyRoutes.updatePricingVisibility);
  }

  updateScoringDocuments(projectId: string, documents: fileInputDocument[]) {   
    return this.baseApi.httpPut(companyRoutes.updateScoringDocuments.replace('{projectId}', projectId), {documents});
  }
}
