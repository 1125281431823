import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityMethodsService } from '../../services/utility-methods.service';
import { UserService } from '@conpulse-web/core';
import { concat, difference, filter, find, intersection, isEmpty, map, remove } from 'lodash-es';

@Component({
  selector: 'app-mat-assets',
  templateUrl: './mat-assets.component.html',
  styleUrls: ['./mat-assets.component.scss']
})
export class MatAssetsComponent implements OnInit {
  originalItems: any;
  flagUrl: string;
  dropDownItems: object[];
  matSelectedItems: string[];
  selectedDisplayItems: any;
  selectedItems: string[];
  @Input() type: string;
  @Input() loading = true;
  @Input() isDisabled = false;
  @Input() isSelectMandatory: boolean;
  @Input() matInputPlaceHolder: string;
  @Input() matSelectPlaceHolder: string;
  @Input() matSearchText: string;
  @Output() selectComplete = new EventEmitter();
  @Input() isIndustryCapability = false;

  @Input()
  set items(value: object[]) {
    if (value) {
      this.dropDownItems = this.originalItems = value;
    }
  }

  @Input()
  set markedItems(value: string[]) {
    if (value) {
      this.selectedItems = this.matSelectedItems = value;
      this.doneSelect(value, true);
    }
  }

  activeClassIndex: number;
  constructor(
    private utilityMethod: UtilityMethodsService,
    private userService: UserService
  ) {
    this.flagUrl = `${this.userService.s3PublicUrl}flags/`
   }

  ngOnInit() {
  }

  isSelectedAll(id: string) {
    if (!this.isIndustryCapability) {
      const stateIds = map(find(this.originalItems, ['_id', id]).states, '_id');
      return intersection(stateIds, this.matSelectedItems).length === stateIds.length;
    } else {
      const childIds = map(find(this.originalItems, ['_id', id]).children, '_id');
      if (!isEmpty(childIds)) {
        return intersection(childIds, this.matSelectedItems).length === childIds.length && intersection(childIds, this.matSelectedItems).length > 0;
      } else {
        return intersection([ id ], this.matSelectedItems).length;
      }
    }
  }

  isIntermediate(id: string) {
    if (!this.isIndustryCapability) {
      const stateIds = map(find(this.originalItems, ['_id', id]).states, '_id');
      return difference(stateIds, this.matSelectedItems).length > 0;
    } else {
      const childIds = map(find(this.originalItems, ['_id', id]).children, '_id');
      return intersection(childIds, this.matSelectedItems).length > 0 && intersection(childIds, this.matSelectedItems).length < childIds.length;
    }
  }


  activeToggler(selectedIndex) {
    this.activeClassIndex =
    (this.activeClassIndex === selectedIndex) ? null : selectedIndex;
  }

  doneSelect(matSelectedItems, isFirstChange) {
    let customFilterResponse;
    if (!this.isIndustryCapability) {
      customFilterResponse = this.utilityMethod.filterStateSelect(
        this.selectedItems,
        this.originalItems,
        this.dropDownItems,
        matSelectedItems
      );
      this.selectedDisplayItems = customFilterResponse.selectedItemWithIsoCode;
    } else {
      customFilterResponse = this.utilityMethod.filterItemSelect(
        this.selectedItems,
        this.originalItems,
        this.dropDownItems,
        matSelectedItems
      );
      this.selectedDisplayItems = customFilterResponse.selectedItemWithParentName;
    }
    this.selectedItems = customFilterResponse.selectedItemArray;
    this.dropDownItems = customFilterResponse.visibleItems;
    this.originalItems = customFilterResponse.items;
    if (!isFirstChange) {
      this.selectComplete.emit({type: this.type, items: this.selectedItems});
    }
  }

  search(searchText) {
    this.dropDownItems = [];
    this.matSearchText = searchText;
    const response = this.utilityMethod.stateSearchList(searchText, this.originalItems);
    this.dropDownItems = response.filteredItems;
    this.originalItems = response.clonedItems;
  }

  clearSearch() {
    this.dropDownItems = this.originalItems;
    this.selectedItems = [];
    this.selectedDisplayItems = [];
    this.doneSelect(this.selectedItems, false);
  }

  handleChangeCheckbox(checked, id ) {
    if (!this.isIndustryCapability) {
      const stateIds = map(find(this.originalItems, ['_id', id]).states, '_id');
      if (checked) {
        this.selectedItems = concat(this.selectedItems, stateIds);
      } else {
        this.selectedItems = difference(this.selectedItems, stateIds);
      }
    } else {
      const childIds = map(find(this.originalItems, ['_id', id]).children, '_id');
      if (childIds.length) {
        if (checked) {
          this.selectedItems = concat(this.selectedItems, childIds);
        } else {
          this.selectedItems = difference(this.selectedItems, childIds);
        }
      } else {
        if (checked) {
          this.selectedItems = concat(this.selectedItems, [id]);
        } else {
          this.selectedItems = difference(this.selectedItems, [id]);
        }
      }
    }
    this.doneSelect(this.selectedItems, false);
  }

  removeSelected(id): void {
    remove(this.selectedDisplayItems, { _id: id });
    this.selectedItems = filter(this.selectedItems, (singleId) => singleId !== id);
    this.doneSelect(this.selectedItems, false);
  }

  check() {
    return (
      isEmpty(this.selectedDisplayItems) &&
      isEmpty(this.matSearchText)
    );
  }
}
