import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../apis';
import { get } from 'lodash-es';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './industry-capability-move.component.html',
  styleUrls: ['./industry-capability-move.component.scss'],
})
export class IndustryCapabilityMoveComponent implements OnInit {
  moveType: 'Sub Industry' | 'Sub Capability';
  selectorCaption: 'Main Industry' | 'Main Capability';
  isLoading: boolean = false;
  industryCapabilityList = [];
  selectedIndustryCapability: string = '';
  currentParentId: string = '';

  constructor(private dialogRef: MatDialogRef<IndustryCapabilityMoveComponent>, private userService: UserService) {}

  ngOnInit() {
    switch (this.moveType) {
      case 'Sub Industry':
        this.selectorCaption = 'Main Industry';
        this.loadIndustries();
        break;
      case 'Sub Capability':
        this.selectorCaption = 'Main Capability';
        this.loadCapabilities();
        break;
    }
  }

  loadIndustries() {
    this.isLoading = true;
    this.industryCapabilityList = [];
    this.userService.getIndustries().subscribe((data) => {
      this.industryCapabilityList = get(data, 'data', []);
      this.isLoading = false;
    });
  }

  loadCapabilities() {
    this.isLoading = true;
    this.industryCapabilityList = [];
    this.userService.getCapabilities().subscribe((data) => {
      this.industryCapabilityList = get(data, 'data', []);
      this.isLoading = false;
    });
  }

  handleClick(value) {
    this.dialogRef.close(value);
  }
}
