import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAssetsComponent } from './mat-assets.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { CountrySelectComponent } from './country-select.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [MatAssetsComponent, CountrySelectComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    I18NextModule
  ],
  exports: [MatAssetsComponent, CountrySelectComponent]
})
export class MatAssetsModule { }
