import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingAddressRoutingModule } from './billing-address-routing.module';
import { BillingAddressComponent } from './billing-address.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { I18NextModule } from 'angular-i18next';


@NgModule({
  declarations: [BillingAddressComponent],
  imports: [
    CommonModule,
    BillingAddressRoutingModule,
    ConpulseMaterialModule,
    ConpulseModule,
    I18NextModule
  ]
})
export class BillingAddressModule { }
