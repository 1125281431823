import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { ConpulseMaterialModule } from '../../../material-module/conpulse-material.module';
import { RfpMilestoneCriteriaManagementComponent } from './rfp-milestone-criteria-management.component';
import { AddMilestoneCriteriaModule } from '../add-milestone-criteria/add-milestone-criteria.module';

@NgModule({
  declarations: [RfpMilestoneCriteriaManagementComponent],
  imports: [CommonModule, ConpulseMaterialModule, AddMilestoneCriteriaModule, I18NextModule],
  exports: [RfpMilestoneCriteriaManagementComponent],
})
export class RfpMilestoneCriteriaManagementModule {}
