import { Component, Input } from '@angular/core';
import * as htmldiff from '../../services/htmldiff.js';

@Component({
  selector: 'conpulse-web-text-diff',
  templateUrl: './text-diff.component.html',
  styleUrls: ['./text-diff.component.scss'],
})
export class TextDiffComponent {
  @Input() set leftHandSide(value: string) {
    this.leftSideText = value || '';
    this.computeDiff();
  }
  @Input() set rightHandSide(value: string) {
    this.rightSideText = value || '';
    this.computeDiff();
  }
  private leftSideText = '';
  private rightSideText = '';
  diffHtml: string = '';

  /**
   * Computes diff between left hand and right hand side
   */
  computeDiff() {
    this.diffHtml = htmldiff(this.leftSideText, this.rightSideText);
  }
}
