import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  basicFormValues,
  List,
  UserService,
  ClientsListFilter,
  country,
  MarketExplorationCompanySizeFilterValues,
  ImproveoCompanyDiversityList,
  ImproveoCompanyCommunityImpactList,
  industryListBasedOnFirm,
  capabilityListBasedOnFirm,
  UserV2Service,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { cloneDeep, get, isEmpty } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'conpulse-web-demographics-filter',
  templateUrl: './demographics-filter.component.html',
  styleUrls: ['./demographics-filter.component.scss'],
})
export class DemographicsFilterComponent implements OnInit {
  MarketExplorationCompanySizeFilterValues = MarketExplorationCompanySizeFilterValues;
  ImproveoCompanyDiversity = ImproveoCompanyDiversityList;
  ImproveoCompanyCommunityImpactList = ImproveoCompanyCommunityImpactList;
  @ViewChild('form', { static: true }) form: NgForm;
  @Input() existingFilter = {} as ClientsListFilter;
  @Input() industryCapabilitySingleLevel: boolean = false;
  @Input() companySizeFilterEnabled: boolean = false;
  @Input() improveoDiversityFilterEnabled: boolean = false;
  @Input() improveoCommunityImpactFilterEnabled: boolean = false;
  @Output() back = new EventEmitter();
  basicFormValues = {} as basicFormValues;
  clientsListFilter = {} as ClientsListFilter;
  demographyList = {} as List;
  filteredCountryList: country[];
  filteredCity = [];
  selectedCities = []
  industryChildList: industryListBasedOnFirm[];
  capabilityChildList: capabilityListBasedOnFirm[];
  searchTextSubscription: Subscription = null;
  cityPage = { page: 0, limit: 10, totalCount: 0 };
  searchCity = '';
  showMoreChips: boolean = false;

  constructor(private userService: UserService, private utilityMethod: UtilityMethodsService, private userV2Service: UserV2Service) {}

  ngOnInit() {
    this.basicFormValues.loading = true;
    this.loadDropdownValues();
    this.getCity();

    this.searchTextSubscription = this.form.valueChanges
      .pipe(
        map((changes: any) => {
          return changes?.cityName || '';
        }),
        debounceTime(500),
        distinctUntilChanged((current: string, previous: string) => {
          return current?.toLowerCase() === previous?.toLowerCase();
        })
      )
      .subscribe((searchKey) => {
        this.getCity();
      });
  }

  clearSearch() {
    this.searchCity = '';
  }

  /**
   * Loads necessary filter dropdown values
   */
  loadDropdownValues() {
    this.userService.getCommonListForCompany().subscribe((dataOf) => {
      this.demographyList.regionList = dataOf.data.region;
      this.demographyList.industryList = dataOf.data.industry;
      this.demographyList.countryList = dataOf.data.country;
      this.demographyList.capabilityList = dataOf.data.capability;
      let data = {};
      if (!isEmpty(this.existingFilter)) {
        data = cloneDeep(this.existingFilter);
      }
      this.clientsListFilter.region = get(data, 'region', []);
      this.clientsListFilter.industry = get(data, 'industry', []);
      this.clientsListFilter.capability = get(data, 'capability', []);
      this.capabilityChildList = this.utilityMethod.filterIndustryCapabilities(this.clientsListFilter, 'capability', this.demographyList.capabilityList);
      this.industryChildList = this.utilityMethod.filterIndustryCapabilities(this.clientsListFilter, 'industry', this.demographyList.industryList);
      this.filterCountries();
      this.basicFormValues.loading = false;
    });
  }

  getCity(reloadLists = true) {
    if (reloadLists) {
      this.cityPage = { page: 0, limit: 10, totalCount: 0 };
    }
    const apiData = {
      ...this.cityPage,
      search: this.searchCity,
      country: this.clientsListFilter.country,
    };
    if(this.filteredCity?.length && this?.clientsListFilter?.city?.length) this.selectedCities = this.filteredCity.filter(city => this.clientsListFilter.city.includes(city._id));
    this.userV2Service.getCgnCities(apiData).subscribe(
      (response) => {
        this.demographyList.city = reloadLists ? response?.data?.cityList : [...this.demographyList.city, ...response?.data?.cityList];
        this.cityPage.page = response?.data?.paginationData.page;
        this.cityPage.totalCount = response?.data?.paginationData.total;
        this.cityPage.limit = response?.data?.paginationData.limit;
        this.filterCities();
      },
      (err) => {}
    );
  }

  removeSelected(id: string) {
    const cities = this.clientsListFilter.city.filter((cityId) => cityId !== id);
    this.clientsListFilter.city = cloneDeep(cities);
  }

  /**
   * Helper function for saving filter
   */
  onFormSubmit() {
    this.scrollToTop();
    this.back.emit(this.clientsListFilter);
  }

  disableFilter() {
    const filters = ['capability', 'companySize', 'country', 'improveoCommunityImpact', 'improveoDiversity', 'industry', 'region', 'other', 'city'];

    return filters.every((filter) => !this.clientsListFilter[filter]?.length && !this.existingFilter[filter]?.length);
  }

  /**
   * Navigates back to the list
   */
  navigateToList() {
    this.scrollToTop();
    this.clientsListFilter = cloneDeep(this.existingFilter);
    if (isEmpty(this.existingFilter) || this.searchCity) {
      this.searchCity = '';
      this.reset();
    }
    this.back.emit(null);
  }

  /**
   * Scrolls drawer to top
   */
  scrollToTop() {
    document.querySelector('#clientsListFilter')?.scrollTo(0, 0);
  }

  /**
   * Helper function to handle multi-select dropdowns
   */
  selectComplete({ type, items, id }): void {
    const isParentRemoved = id ? id : '';

    switch (type) {
      case 1:
        this.clientsListFilter.industry = items;
        this.industryChildList = this.utilityMethod.filterIndustryCapabilities(
          this.clientsListFilter,
          'industry',
          this.demographyList.industryList,
          isParentRemoved
        );
        break;
      case 2:
        this.clientsListFilter.region = items;
        this.filterCountries();
        break;
      case 3:
        this.clientsListFilter.country = items;
        this.getCity(true);
        break;
      case 4:
        this.clientsListFilter.capability = items;
        this.capabilityChildList = this.utilityMethod.filterIndustryCapabilities(
          this.clientsListFilter,
          'capability',
          this.demographyList.capabilityList,
          isParentRemoved
        );

        break;
      case 5:
        this.clientsListFilter.improveoDiversity = items;
        break;
      case 6:
        this.clientsListFilter.improveoCommunityImpact = items;
        break;
    }
  }

  onProviderScrolled() {
    if (this.cityPage.page < Math.floor(this.cityPage.totalCount / this.cityPage.limit)) {
      this.cityPage.page++;
      this.getCity(false);
    }
  }

  /**
   * Resets the filter
   */
  reset() {
    this.clientsListFilter.country = [];
    this.clientsListFilter.region = [];
    this.clientsListFilter.industry = [];
    this.clientsListFilter.capability = [];
    this.clientsListFilter.companySize = '';
    this.clientsListFilter.improveoCommunityImpact = [];
    this.clientsListFilter.improveoDiversity = [];
    this.clientsListFilter.city = [];
    this.industryChildList = [];
    this.capabilityChildList = [];
    this.searchCity = '';
    this.getCity();
  }

  /**
   * Checks if save button is disabled or not
   * @returns Boolean value indicating whether the button is disabled
   */
  checkButtonDisabled() {
    return (
      isEmpty(this.clientsListFilter.country) &&
      isEmpty(this.clientsListFilter.region) &&
      isEmpty(this.clientsListFilter.industry) &&
      isEmpty(this.clientsListFilter.capability) &&
      isEmpty(this.clientsListFilter.companySize) &&
      isEmpty(this.clientsListFilter.improveoCommunityImpact) &&
      isEmpty(this.clientsListFilter.improveoDiversity) &&
      isEmpty(this.clientsListFilter.city)
    );
  }

  /**
   * Filter countries based on selected region
   */
  async filterCountries() {
    if (!isEmpty(this.clientsListFilter.region)) {
      this.filteredCountryList = [];
      this.clientsListFilter.region.map((regionId) => {
        (this.demographyList.countryList as country[]).map((country) => {
          if (regionId === country?.region?.['_id']) {
            this.filteredCountryList.push(country);
          }
        });
      });
    } else {
      this.filteredCountryList = this.demographyList.countryList as country[];
    }
  }

  /**
   * Filter countries based on selected region
   */
  async filterCities() {
    if (!isEmpty(this.clientsListFilter.country)) {
      const data = cloneDeep(this.filteredCity);
      this.filteredCity = [];
      const ides = [];
      this.clientsListFilter.country.map((id) => {
        [...this.demographyList.city, ...data].map((city) => {
          if (id === city?.country && !ides.includes(city._id)) {
            this.filteredCity.push(city);
            ides.push(city._id);
          }
        });
      });
      if(this.clientsListFilter?.city?.length) this.clientsListFilter.city = this.clientsListFilter.city.filter((id) => ides.includes(id));
    } else {
      if (!isEmpty(this.clientsListFilter.city)) {
        if(!this.demographyList?.city?.length && this.searchCity) {
          this.filteredCity = [];
          return;
        }
        const cities = this.demographyList?.city?.filter(city => !this.clientsListFilter.city.includes(city._id));
        this.filteredCity = [...this.selectedCities, ...cities].sort((a,b) => a.name.localeCompare(b.name))
      } else {
        this.filteredCity = cloneDeep(this?.demographyList?.city || []);
      }
    }
  }
}
