<div class="template-container" cdkDropList (cdkDropListDropped)="dropSection($event)">
  <ng-container *ngFor="let values of displayList; let i = index; let last = last; trackBy: trackByFn">
    <!-- [cdkDropListDisabled]="disableDragAndDrop || !isEditedOrAdded()" -->
    <!--  -->
    <div class="dynamic-card" class="col-12 px-0 bg-white" cdkDrag cdkDragBoundary=".template-container"
      [cdkDragDisabled]="displayList.length < 2 || false">
      <div class="d-flex align-items-center">
        <div *ngIf="true" class="d-flex align-items-center mb-2 mr-1" cdkDragHandle>
          <mat-icon class="cursor-move txt-secondary">drag_indicator</mat-icon>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1 flex-1">
          <div class="d-flex align-items-center" [ngClass]="{ 'flex-1': values?.isNew || !viewList[i] }">
            <div class="w-100">
              <form class="flex-1" #editNameForm="ngForm" name="editNameForm">
                <mat-form-field style="width: 100%" class="pr-4" floatLabel="never">
                  <input matInput [(ngModel)]="displayList[i].title" name="{{ 'title' + i }}"
                    (ngModelChange)="onValueChanges(i)" (blur)="values?.title && (values.title = trim(values.title))" />
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="values.isActive">
        <div class="d-flex justify-content-between pl-4">
          <div class="w-100">
            <ng-container [ngSwitch]="values?.type">
              <div class="mb-2">
                <form #editDescForm="ngForm" name="editDescForm">
                  <ng-container *ngSwitchDefault>
                    <div class="quill-height-200 ">
                      <editor  [init]="initEditor(('Please provide details on ' | i18next) + values.title)" [readOnly]="!displayList[i].title" (ngModelChange)="onValueChanges(i)" [(ngModel)]="values.commonData.text" name="{{ 'quillEditor' + i }}"> </editor>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="MSAFieldType.TEMPLATE">
                    <div>
                      <conpulse-web-file-input [onlyDocumentUpload]="false" [accept]="formatList"
                        (download)="downloadDocument($event)" [disabled]="isLoading" [fileLimit]="fileList"
                        [maxCount]="fileLimit" [multiple]="false" [document]="displayList[i].selectedDoc"
                        (docUploaded)="uploadDocument($event, i, MSAFieldType.TEMPLATE, false)" [showDocViewBtn]="true"
                        (view)="viewDocument($event)"></conpulse-web-file-input>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="MSAFieldType.UPLOAD">
                    <div class="">
                      <conpulse-web-file-input [onlyDocumentUpload]="false" [accept]="formatList"
                        (download)="downloadDocument($event)" [disabled]="isLoading" [fileLimit]="fileList"
                        [maxCount]="fileLimit" [multiple]="false" [document]="displayList[i].selectedDoc"
                        (docUploaded)="uploadDocument($event, i, MSAFieldType.UPLOAD)" [showDocViewBtn]="true"
                        (view)="viewDocument($event)"></conpulse-web-file-input>
                    </div>
                  </ng-container>
                </form>
              </div>
            </ng-container>
          </div>
          <div>
            <a [matMenuTriggerFor]="kebabMenu" class="pointer" #isMenuOpen="matMenuTrigger">
              <i class="material-icons project-option-icon">more_vert</i>
            </a>
            <mat-menu #kebabMenu="matMenu" class="sow-intro-menu">
              <button mat-menu-item (click)="onDelete(i)">{{ 'Remove' | i18next }}</button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="tabName === appendix; else otherTabsDiv">
      <div *ngIf="!last" class="h-24 add-section pointer d-flex align-items-center py-2"
        [ngClass]="viewList[i] || !values.isActive ? 'col-8' : 'col-12'" [matMenuTriggerFor]="menuList">
        <mat-menu #menuList="matMenu">
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.TEMPLATE)">{{ 'Template' | i18next }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.UPLOAD)">{{ 'Upload' | i18next }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.QUILL)">{{ 'Rich Text' | i18next }}</button>
        </mat-menu>
        <div class="h-2 width-100 add"></div>
        <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </ng-container>
    <ng-template #otherTabsDiv>
      <div *ngIf="!last" class="h-24 add-section pointer d-flex align-items-center py-2"
        [ngClass]="viewList[i] || !values.isActive ? 'col-8' : 'col-12'" (click)="addNewField(i + 1)">
        <div class="h-2 width-100 add"></div>
        <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
<ng-container *ngIf="tabName === appendix; else otherTabs">
  <button [hidden]="!sectionTitle?.length" [matMenuTriggerFor]="menu" type="button" mat-stroked-button color="primary"
    class="icon-button mb-3" [ngClass]="{ 'mt-3': displayList?.length }">
    <mat-icon class="mat-20 mr-2">add</mat-icon> {{ 'Add Field' | i18next }}
    <mat-menu #menu="matMenu">
      <button mat-menu-item
        (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.TEMPLATE)">{{ 'Template' |
        i18next }}</button>
      <button mat-menu-item (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.UPLOAD)">{{
        'Upload' | i18next }}</button>
      <button mat-menu-item (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.QUILL)">{{
        'Rich Text' | i18next }}</button>
    </mat-menu>
  </button>
</ng-container>
<ng-template #otherTabs>
  <div class="custom-btn">
    <button type="button" mat-stroked-button color="primary"
      (click)="addNewField(displayList?.length ? displayList.length : 0)" class="icon-button mb-3"
      [ngClass]="{ 'mt-3': displayList?.length }">
      <mat-icon class="mat-20 mr-2">add</mat-icon> {{ 'Add Field' | i18next }}
    </button>
  </div>
</ng-template>