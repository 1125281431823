import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagePopupComponent } from './message-popup.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { MdePopoverModule } from '@material-extended/mde';

@NgModule({
  declarations: [MessagePopupComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    MdePopoverModule
  ],
  exports: [MessagePopupComponent]
})
export class MessagePopupModule { }
