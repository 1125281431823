import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { CompanyProfileRoutingModule } from './company-profile-routing.module';
import { CompanyProfileComponent } from './company-profile.component';
import { ConpulseMaterialModule } from '../../material-module';

@NgModule({
  declarations: [CompanyProfileComponent],
  imports: [CommonModule, CompanyProfileRoutingModule, I18NextModule, ConpulseMaterialModule],
})
export class CompanyProfileModule {}
