import { Pipe, PipeTransform } from '@angular/core';
/**
 * Ellipsis Pipe
 * *************
 * Used to truncate long text into a specific length and pad them with full stops on the right.
 * Example: {{ stringValue | ellipsis: 100 }} truncates the text to 100 characters and pads with full stops on the right.
 */
@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
	transform(target: string, maxLength: number = 300) {
		const htmlStripped = target.replace(/(<([^>]+)>)/gi, '');

		if (htmlStripped.length > maxLength) {
			return `${htmlStripped.slice(0, maxLength)}...`;
		}
		return htmlStripped;
	}
}
