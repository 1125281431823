import { Pipe, PipeTransform } from '@angular/core';
import { UtilityMethodsService } from '../services';
import { NOTATION_PIPE_RESPONSE_TYPE } from '@conpulse-web/core';

/**
 * This pipe is to convert amount values from its minor units(Cents) to Units(Dollars)
 */
@Pipe({
  name: 'notation'
})
export class AmountNotationPipe implements PipeTransform {

    constructor(private utilityService: UtilityMethodsService){}

  transform(value: number, type: NOTATION_PIPE_RESPONSE_TYPE): string {
    switch(type){
        case NOTATION_PIPE_RESPONSE_TYPE.A:
            return this.utilityService.getFormattedAmount(value)
        case NOTATION_PIPE_RESPONSE_TYPE.N:
            return this.utilityService.getSuffixNotation(value)
        default:
            return this.utilityService.setSuffixNotations(value)
    }
  }
}
