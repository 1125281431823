import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { TinyMceService } from '../services';

@Directive({
  selector: '[quillToggleShow]'
})
export class QuillShowHideDirective {

  isImageUploading = false
  @Input() isTextDisabled = false;
  @Output() updateEditorStatus = new EventEmitter<boolean>();
  constructor(private el: ElementRef, private renderer: Renderer2, private tinyMceService: TinyMceService) {
    this.tinyMceService.isImageUploading$.subscribe((data) => {
      this.isImageUploading = data
    })
  }

  @HostListener('click') onClick() {
    if (!this.isTextDisabled && !this.isImageUploading) this.showHideDiv();
  }

  private showHideDiv() {
    const clickedDiv = this.el.nativeElement;
    const textNode = clickedDiv.querySelector('div.text');
    const editorNode = clickedDiv.querySelector('div.quill-editor-display-status');

    if (textNode && editorNode) {
      this.renderer.setStyle(textNode, 'display', 'none');
      this.renderer.setStyle(editorNode, 'display', 'block');
      this.updateEditorStatus.emit(true)
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target) && !this.isImageUploading) {
      const textNode = this.el.nativeElement.querySelector('.text');
      const editorNode = this.el.nativeElement.querySelector('.quill-editor-display-status');
      if (textNode && editorNode) {
        if (editorNode.style?.display === 'block')
          this.updateEditorStatus.emit(false)
        this.renderer.setStyle(textNode, 'display', 'block');
        this.renderer.setStyle(editorNode, 'display', 'none');
      }
    }
  }
}
