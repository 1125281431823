import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from 'libs/conpulse/src/index';
import { I18NextModule } from 'angular-i18next';
import { HelpCenterComponent } from './help-center.component';

@NgModule({
  declarations: [HelpCenterComponent],
  imports: [CommonModule, FormsModule, ConpulseMaterialModule, I18NextModule],
  exports: [HelpCenterComponent],
})
export class HelpCenterModule {}
