<div class="p-12 bg-white border-radius-6 mb-12">
  <div class="d-flex align-items-center justify-content-between">
    <h3 class="page-title font-Jakarta">{{ 'Billing Info' | i18next }}</h3>
    <button mat-stroked-button color="secondary" class="medium" *ngIf="isSuperAdmin" [disabled]="!isAdminChangeEnabled" [matTooltipDisabled]="isAdminChangeEnabled" matTooltip="{{ 'No active users exist' }}" (click)="openClientAdminDrawer()">Change Admin</button>
  </div>
</div>

<div class="billing-address-container p-20 bg-white border-radius-6">
<ng-container *ngIf="isLoading || isCompanyLoading; else administrativeContainerRef">
  <div class="d-flex loader-container">
    <div class="flex-1" *ngFor="let j of [1, 2]">
      <div class="mb-3" *ngFor="let i of [1, 2, 3]">
        <p class="f-12 f-w-500 l-h-16 txt-grey-secondary7 skeleton-loader skeleton-title h-auto">{{ 'Type' | i18next }}</p>
        <p class="f-14 f-w-600 l-h-20 skeleton-loader skeleton-title h-auto">{{ 'Subscription' | i18next }}</p>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #administrativeContainerRef>
  <ng-container *ngIf="!isEmpty(accountDetails?.administrativeInfo); else emptyInfoContainer">
      <div class="d-flex">
        <div class="flex-one billing-address-panel p-20 border-radius-6">
          <div class="top-profile-container d-flex">
            <div class="profile-container d-flex justify-content-between align-items-start w-100">
              <div class="d-flex align-items-start">
                <div class="profile-img border-radius-xlarge bg-white-smoke d-flex align-items-center justify-content-center">
                  <ng-container *ngIf="accountDetails?.clientId?.userId?.picture; else showName">
                    <img src="{{ accountDetails?.clientId?.userId?.picture }}" alt="profile" class="object-fit-cover border-radius-xlarge" />
                  </ng-container>
                  <ng-template #showName>
                    <p class="f-16 f-w-700 txt-shuttle-grey">
                      {{ ((accountDetails?.clientId?.userId?.firstName)[0] || '') + ((accountDetails?.clientId?.userId?.lastName)[0] || '') }}
                    </p>
                  </ng-template>
                </div>
                <div class="profile-content ml-12 py-2">
                  <h3 class="f-16 f-w-700 l-h-20 txt-primary-01 text-truncate w-100">
                    {{ (accountDetails?.clientId?.userId?.firstName || '') + ' ' + (accountDetails?.clientId?.userId?.lastName || '') }}
                  </h3>
                  <p class="f-14 f-w-400 l-h-20 txt-shuttle-grey m-t-12 w-100 threeline-truncate">
                    {{
                      accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.line1
                        ? accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.line1 + ', '
                        : ''
                    }}{{
                      accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.line2
                        ? accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.line2 + ', '
                        : ''
                    }}{{
                      accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.city
                        ? accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.city + ', '
                        : ''
                    }}{{ accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.country?.name || ''
                    }}{{
                      accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.zipCode
                        ? ' - ' + accountDetails?.administrativeInfo?.billingInfo?.billingAddress?.zipCode + '.'
                        : '.'
                    }}
                  </p>
                </div>
              </div>
              <div class="pointer menu-container">
                <span
                  class="material-icons f-20 txt-hint"
                  [matMenuTriggerFor]="billingMenu"
                  #isMenuOpen="matMenuTrigger"
                  *ngIf="
                    (!isEmpty(accountDetails?.administrativeInfo) && isSuperAdmin) ||
                    (!isEmpty(accountDetails?.administrativeInfo) && isSuperAdmin && isClient) ||
                    (!isEmpty(accountDetails?.administrativeInfo) && !isSuperAdmin && isClient)
                  "
                  >more_vert</span
                >
                <mat-menu #billingMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="openAddEditDrawer(accountTypes.AdministrativeInfo)"
                    class="d-flex align-items-center menu-icon-button"
                    *ngIf="!isEmpty(accountDetails?.administrativeInfo) && isSuperAdmin"
                  >
                    <i class="material-icons delete">edit</i>{{ 'Edit' | i18next }}
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="!isEmpty(accountDetails?.administrativeInfo) && isSuperAdmin && isClient && !accountDetails?.administrativeInfo?.stripeCustomerId"
                    (click)="createStripeCustomer()"
                    class="d-flex align-items-center menu-icon-button"
                  >
                    <i class="material-icons delete">add_circle</i>{{ 'Create Client in stripe' | i18next }}
                  </button>
                  <button
                    mat-menu-item
                    (click)="openCustomerSessionPortal()"
                    class="d-flex align-items-center menu-icon-button"
                    *ngIf="!isEmpty(accountDetails?.administrativeInfo) && !isSuperAdmin && isClient"
                  >
                    <i class="material-icons delete">open_in_new</i>{{ 'Open Stripe Portal' | i18next }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="menu"></div>
          </div>
          <div class="detail-container d-flex justify-content-between m-t-12">
            <div class="w-100">
              <p class="p-title">{{ 'Billing Contact' | i18next }}</p>
              <p
                class="p-info m-t-2 text-truncate"
                matTooltip="{{ accountDetails?.administrativeInfo?.billingInfo?.billingContact }}"
                matTooltipPosition="above"
                [matTooltipDisabled]="disableTooltip(billingContact)"
                #billingContact
              >
                {{ accountDetails?.administrativeInfo?.billingInfo?.billingContact || '-' }}
              </p>
            </div>
            <div class="w-100">
              <p class="p-title">{{ 'Admin Email' | i18next }}</p>
              <p
                class="p-info m-t-2 text-truncate"
                matTooltip="{{ accountDetails?.administrativeInfo?.adminEmail }}"
                matTooltipPosition="above"
                [matTooltipDisabled]="disableTooltip(billingContact)"
                #adminContact
              >
                {{ accountDetails?.administrativeInfo?.adminEmail || '-' }}
              </p>
            </div>
          </div>

          <div class="detail-container d-flex justify-content-between m-t-12">
            <div class="w-100">
              <p class="p-title">{{ 'Applicable Tax' | i18next }}</p>
              <p class="p-info m-t-2">{{ accountDetails?.administrativeInfo?.applicableTax }}%</p>
            </div>
            <div class="w-100">
              <p class="p-title">{{ 'Client Option' | i18next }}</p>
              <p class="p-info m-t-2">
                {{ accountDetails?.administrativeInfo?.clientOption || '-' }}{{ accountDetails?.administrativeInfo?.clientOption ? '%' : '' }}
              </p>
            </div>
          </div>

          <div class="detail-container d-flex justify-content-between m-t-12">
            <div class="w-100">
              <p class="p-title">{{ 'Stripe Status' | i18next }}</p>
              <p class="p-info m-t-2" [ngClass]="accountDetails?.administrativeInfo?.stripeCustomerId ? 'active-status' : 'inactive-status'">
                {{ accountDetails?.administrativeInfo?.stripeCustomerId ? 'Active' : 'InActive' }}
              </p>
            </div>
            <div class="w-100">
              <p class="p-title">{{ 'Consource' | i18next }}</p>
              <p class="p-info m-t-2" [ngClass]="accountDetails?.clientId?.status ? 'active-status' : 'inactive-status'">
                {{ (accountDetails?.clientId?.status ? 'Active' : 'InActive') | i18next }}
              </p>
            </div>
          </div>

          <div class="detail-container d-flex justify-content-between m-t-12">
            <div class="w-100">
              <p class="p-title">{{ 'Created' | i18next }}</p>
              <p class="p-info m-t-2">{{ (accountDetails?.administrativeInfo?.accountCreatedDate | date) || '-' }}</p>
            </div>
            <div class="w-100">
              <p class="p-title">{{ 'Last Updated' | i18next }}</p>
              <p class="p-info m-t-2">{{ (accountDetails?.clientId?.updatedAt | date) || '-' }}</p>
            </div>
          </div>
        </div>
        <div class="flex-one"></div>
      </div>
    </ng-container>

    <ng-template #emptyInfoContainer>
      <div class="align-items-center d-flex empty-container h-100 justify-content-center">
        <div class="p-3 d-flex align-items-center justify-content-center flex-column">
          <img class="vector-image" [src]="notFoundImage" alt="no data image" />
          <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
          <p class="nodata-summary mw-400 m-t-12">{{ 'SORRY! There Isn\'t Any Records To Display!' | i18next }}</p>
          <button color="secondary" type="button" class="medium mt-2" mat-stroked-button [hidden]="!isSuperAdmin" (click)="openAddEditDrawer(accountTypes.AdministrativeInfo)">
            {{'Add Billing Info' | i18next}}
          </button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>

<ng-template #emptyData>
  <p class="f-14 f-w-600 l-h-20">-</p>
</ng-template>
