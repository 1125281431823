import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileInputComponent } from './file-input.component';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '@conpulse-web/conpulse';
import { FileCardModule } from '../file-card/file-card.module';



@NgModule({
  declarations: [FileInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ConpulseMaterialModule,
    ConpulseModule,
    FileCardModule
  ],
  exports: [FileInputComponent]
})
export class FileInputModule { }
