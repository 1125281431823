import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { AddCountryComponent } from './add-milestone-criteria.component';
import { ConpulseMaterialModule } from '../../../material-module/conpulse-material.module';

@NgModule({
  declarations: [AddCountryComponent],
  imports: [CommonModule, ConpulseMaterialModule, I18NextModule],
  exports: [AddCountryComponent],
})
export class AddMilestoneCriteriaModule {}
