import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[conpulseWebMaxValueValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }],
})
export class MaxValueValidatorDirective {
  @Input('maxValue') maxValue: number = 1;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value !== null && control.value !== undefined && control.value !== '') {
      const value = parseInt(control.value?.toString()?.replace(/,/g, '')); // Convert to number
      if (value > this.maxValue) {
        return { maxValue: true };
      }
    }
    return null;
  }
}
