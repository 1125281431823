<div class="settings-sidenav-section section-content" [ngClass]="{ 'skeleton-loader': isChatListLoading }">
  <ng-container *ngIf="isPrivate">
    <div class="d-flex align-items-center border-bottom-grey-2 justify-content-between p-x-12 p-y-14"
      *ngIf="!isNewChatOpen; else newChatHeaderContainerRef">
      <div>
        <h3 class="f-16 f-w-800 txt-black-primary font-jakarta">{{ 'Messages' | i18next }}</h3>
      </div>
      <div class="d-flex align-items-center pointer" [matTooltip]="'Start Conversation' | i18next" matTooltipPosition="below"
        matTooltipClass="below" (click)="openNewChatListing()">
        <mat-icon
          class="material-icons txt-grey-hint f-20 d-flex align-items-center justify-content-center w-20px h-20px">add_comment</mat-icon>
      </div>
    </div>
    <ng-template #newChatHeaderContainerRef>
      <div class="d-flex align-items-center border-bottom-grey-2 justify-content-start p-x-12 p-y-14">
        <div class="d-flex align-items-center pointer" (click)="closeNewChat()">
          <mat-icon class="material-icons material-symbols-outlined f-20 txt-grey-hint">arrow_back</mat-icon>
        </div>
        <div class="ml-1">
          <h3 class="f-16 f-w-800 txt-black-primary font-jakarta">{{ 'New Chat' | i18next }}</h3>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <div class="d-flex align-items-center justify-content-between search border-bottom-grey-2 p-3">
    <mat-form-field class="full-width mb-0" floatLabel="never" *ngIf="conversationList.length || isSearchEnabled">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput placeholder="{{ 'Search' | i18next }}" [(ngModel)]="search" (keyup.enter)="applySearch()" />
      <mat-icon [hidden]="search.length == 0" class="txt-shuttle-grey pointer" matSuffix mat-icon-button
        (click)="clearSearch()">close</mat-icon>
    </mat-form-field>
    <div *ngIf="!isPrivate" class="pointer text-right ml-2 mr-2 color-primary" [matTooltip]="'New Chat' | i18next"
      matTooltipPosition="below" matTooltipClass="below" (click)="openNewChatListing(true)">
      <mat-icon><i class="material-icons material-symbols-outlined">add</i></mat-icon>
    </div>
  </div>
  <div [ngClass]="{ 'skeleton-loader': isChatListLoading }">
    <div class="bg-white">
      <div [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" infinite-scroll
        (scrolled)="onScroll()" class="pointer setting-list"
        [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isChatListLoading, 'h-550': !isPrivate, '': isPrivate }">
        <ng-container *ngIf="!isNewChatOpen; else newChatListContainerRef">
          <ng-container *ngFor="let conversation of conversationList; let index = index">
            <div class="w-100 chat-card border-bottom-grey-2 d-flex p-x-12 py-2"
              [class.active]="conversation._id === currentChatRoom._id" (click)="onConversationClick(conversation)">
              <div class="w-100">
                <div class="d-flex mr-2 justify-content-between align-items-center">
                  <div class="position-relative">
                    <div class="profile-image d-flex align-items-center mr-2">
                      <img *ngIf="isPrivate; else projectProfile" class="w-100 border-radius-full img-fit-cover"
                        [src]="conversation.chatProfile.picture || defaultProfilePhoto" />
                      <ng-template #projectProfile>
                        <div mat-button-wrapper class="alphabet mr-2">
                          <span class="alphabet mr-2" [ngClass]="getClass(index)">
                            {{ conversation.chatProfile?.name?.charAt(0)?.toUpperCase() }}
                          </span>
                        </div>
                      </ng-template>
                    </div>
                    <div class="border-radius-full"
                      [ngClass]="{ 'workspace-chat-user-status': !isPrivate, 'chat-user-status': isPrivate }"
                      *ngIf="conversation.chatProfile.onlineStatus"></div>
                  </div>
                  <div class="d-flex flex-1 justify-content-between">
                    <div class="d-flex flex-column justify-content-between">
                      <h3 class="f-14 f-w-600 l-h-18 text-truncate mw-100 txt-grey-darken text-left">{{
                        conversation.chatProfile.name }}</h3>
                      <div class="d-flex align-items-center" *ngIf="conversation?.messagesInfo?.latestMessage?._id">
                        <p class="f-14 mr-2 f-w-400 txt-grey-hint text-truncate mw-120 l-h-25"
                          *ngIf="conversation.messagesInfo.latestMessage?.createdBy === currentUser?._id && !conversation.messagesInfo.latestMessage?.isDeleted">
                          {{ 'You' | i18next }}:
                        </p>
                        <p class="f-14 f-w-400 txt-grey-hint text-truncate mw-120 l-h-25"
                          *ngIf="!conversation.messagesInfo.latestMessage?.isDeleted; else deletedMessageRef">
                          {{ conversation.messagesInfo.latestMessage?.message }}
                        </p>
                        <ng-template #deletedMessageRef>
                          <p class="f-12 f-w-400 txt-grey-hint text-truncate mw-160 l-h-25">{{ 'The message was deleted.' }}
                          </p>
                        </ng-template>
                      </div>
                    </div>
                    <div class="">
                      <p class="f-10 f-w-500 txt-grey-secondary7 text-truncate mw-300">{{
                        conversation.messagesInfo?.latestMessage?.createdAt | timeAgo }}</p>
                      <div class="m-t-6 d-flex align-items-center justify-content-end">
                        <p *ngIf="conversation.unreadCount > 0"
                          class="chat-badge f-10 f-w-700 text-white d-inline-block">{{conversation.unreadCount}}</p>
                        <div *ngIf="!isPrivate">
                          <mat-icon class="f-20 txt-grey-secondary9" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="triggerEditDeleteRoom($event, 'Edit')">{{ 'Edit' | i18next
                              }}</button>
                            <button mat-menu-item (click)="triggerEditDeleteRoom($event, 'Delete')">{{ 'Delete' |
                              i18next }}</button>
                          </mat-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!conversationList.length">
            <div class="no-data mt-5 ml-1" *ngIf="!isLoading">
              <img [src]="notFoundImage" class="no-data" alt="" />
              <h4 class="heading-normal mb-2 text-center">{{ 'No Records' | i18next }}</h4>
              <p class="subtitle text-center">{{ "SORRY! There Isn't Any Record To Display!" | i18next }}</p>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #newChatListContainerRef>
          <ng-container *ngFor="let member of newChatList">
            <div class="mb-1 mt-1 w-100 chat-card border-bottom-grey-2 d-flex" (click)="onConversationClick(member)">
              <div class="d-flex mr-2 justify-content-between align-items-center">
                <div class="profile-image p-2">
                  <img class="w-100 border-radius-full img-fit-cover" [src]="member.picture || defaultProfilePhoto" />
                </div>
                <div class="flex-1 align-items-center">
                  <p class="f-14 f-w-700 l-h-18 text-truncate mw-200px">{{ member.name }}</p>
                  <!-- TODO: add company/Firm name -->
                  <p class="f-13">{{ member?.jobtitle }}</p>
                  <p class="f-13">{{ member?.companyId?.companyName }}</p>
                  <p class="f-13">{{ member?.firmId?.firmName }}</p>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!newChatList.length">
            <div class="no-data mt-5 ml-1" *ngIf="!isLoading">
              <img [src]="notFoundImage" class="no-data" alt="" />
              <h4 class="heading-normal mb-2 text-center">{{ 'No Records' | i18next }}</h4>
              <p class="subtitle text-center">{{ "Look's like you don't have users" | i18next }}</p>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>
