import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RescheduleComponent } from './reschedule.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ConpulseMaterialModule } from 'libs/conpulse/src/index';

@NgModule({
  declarations: [RescheduleComponent],
  imports: [
    CommonModule,
    FormsModule,
    ConpulseMaterialModule
  ],
  exports: [RescheduleComponent],
  entryComponents: [RescheduleComponent]

})
export class RescheduleModule { }
