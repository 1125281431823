import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientModuleManagementRoutingModule } from './client-module-management-routing.module';
import { ClientModuleManagementComponent } from './client-module-management.component';
import { ConpulseModule } from '../../conpulse.module';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';


@NgModule({
  declarations: [ClientModuleManagementComponent],
  imports: [
    CommonModule,
    ClientModuleManagementRoutingModule,
    ConpulseModule,
    ConpulseMaterialModule,
    I18NextModule
  ],
  exports: [ClientModuleManagementComponent]
})
export class ClientModuleManagementModule { }
