import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { AbstractControl, ValidationErrors, AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'libs/core/src/lib/apis';
import { get } from 'lodash-es';

export function existingEmailValidator(apiService: UserService, element: boolean, userId: String): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return apiService
      .checkEmail({ email: control.value, isConsulting: element, userId: userId })
      .pipe(
        map((data) => {
          return get(data, 'data.isExist')
            ? {
                isEmailExist: {
                  valid: true,
                },
              }
            : null;
        })
      )
      .toPromise();
  };
}

// validator for password match
@Directive({
  selector: '[isEmailExist][formControlName],[isEmailExist][formControl],[isEmailExist][ngModel]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => EmailExistanceValidatorDirective), multi: true }],
})
export class EmailExistanceValidatorDirective implements AsyncValidator {
  @Input() userId: '';
  constructor(private apiService: UserService, @Attribute('isEmailExist') public isEmailExist: string) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const element = get(control.root.get(this.isEmailExist), 'value', true);
    return existingEmailValidator(this.apiService, element, this.userId)(control);
  }
}
