import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { CommonTableComponent } from './common-table.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { ConpulseModule } from '../../conpulse.module';
import { MessagePopupModule } from '../message-popup/message-popup.module';



@NgModule({
  declarations: [CommonTableComponent],
  imports: [
    CommonModule,
    ConpulseModule,
    ConpulseMaterialModule,
    I18NextModule,
    MessagePopupModule
  ],
  exports: [CommonTableComponent]
})
export class CommonTableModule { }
