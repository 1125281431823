import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatRoomListComponent } from './chat-room-list.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { ConpulseModule } from '../../conpulse.module';
import { I18NextModule } from 'angular-i18next';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddThreadDialogComponent } from './add-thread-dialog/add-thread-dialog.component';



@NgModule({
  declarations: [ChatRoomListComponent, AddThreadDialogComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    I18NextModule,
    InfiniteScrollModule
  ],
  exports: [
    ChatRoomListComponent
  ]
})
export class ChatRoomListModule { }
