import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChatContext, ChatService, defaultProfilePhoto, DefaultGroupPhoto, UserService } from '@conpulse-web/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'conpulse-web-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrls: ['./floating-chat.component.scss'],
})
export class FloatingChatComponent implements OnInit, OnDestroy {
  activeChatWindows: ChatContext[] = [];
  defaultProfilePhoto = defaultProfilePhoto;
  incomingMessageSubscription: Subscription | null = null;
  triggerMessagePopupSubscription: Subscription | null = null;
  countSubscription: Subscription | null = null;
  onlineStatusSubscription: Subscription | null = null;
  defaultGroupPhoto = DefaultGroupPhoto;

  constructor(private chatService: ChatService, private userService: UserService) { }

  ngOnInit(): void {
    this.incomingMessageSubscription = this.chatService.incomingMessage$.subscribe({
      next: (chatMessage) => {
        const chatContext = this.activeChatWindows?.findIndex((chatContext) => chatContext.roomId === chatMessage.roomId);
        if (chatContext <= -1) {
          this.getChatRoom(chatMessage.roomId);
        } else {
          this.chatService.refreshRoomUnreadCount(chatMessage.roomId)
          this.chatService.refreshUnreadCount()
        }
      },
    });
    this.triggerMessagePopupSubscription = this.userService.triggerChatWidget$.subscribe({
      next: (roomId) => {
        const chatContext = this.activeChatWindows?.findIndex((chatContext) => chatContext.roomId === roomId);
        if (chatContext <= -1) {
          this.getChatRoom(roomId, true);
        } else {
          this.activeChatWindows[chatContext]['isWindowActive'] = true;
          this.chatService.refreshRoomUnreadCount(roomId)
          this.chatService.refreshUnreadCount()
        }
      },
    });
    this.countSubscription = this.chatService.roomUnreadCount$.subscribe({
      next: (response) => {
        const foundIndex = this.activeChatWindows?.findIndex((chatContext) => chatContext.roomId === response.roomId);
        if (foundIndex >= 0) this.activeChatWindows[foundIndex].unreadCount = response.count;
      }
    })

    this.onlineStatusSubscription = this.chatService.onlineStatus$.subscribe({
      next: ({ userId, status }) => {
        const index = this.activeChatWindows.findIndex(conversation => conversation.fromUser._id === userId);
        if (index > -1) {
          this.activeChatWindows[index].fromUser.onlineStatus = status
        }
      }
    });
  }

  /**
   * Get chat room details
   * @param roomId Unique ID of the chat room
   */
  getChatRoom(roomId: string, isWindowActive = false) {
    this.chatService.getChatRoom(roomId).subscribe({
      next: (data) => {
        const roomDetails = data?.data;
        if(!this.activeChatWindows.some((chatMessage) => chatMessage.roomId == roomDetails?.['_id'])){
          this.activeChatWindows.push({
            roomId: roomDetails?.['_id'],
            isPrivate: roomDetails?.['isPrivate'],
            isWindowActive: isWindowActive,
            fromUser: roomDetails?.['fromUser'],
            unreadCount: roomDetails?.['unreadCount'],
            name: roomDetails?.name,
            projectName: roomDetails?.projectDetails?.[0]?.name
          });
        }
      },
    });
  }

  /**
   * Leaves from a joined chat room
   * @param chatRoomId Unique ID of the chat room
   */
  leavePrivateChat(chatRoomId: string) {
    const chatContextIndex = this.activeChatWindows.findIndex((chatContext) => chatContext.roomId === chatRoomId);
    if (chatContextIndex >= 0) this.activeChatWindows.splice(chatContextIndex, 1);
  }

  ngOnDestroy(): void {
    this.countSubscription?.unsubscribe()
    this.incomingMessageSubscription?.unsubscribe()
    this.onlineStatusSubscription?.unsubscribe()
    this.triggerMessagePopupSubscription.unsubscribe()
  }
}
