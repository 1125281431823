import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import {
  AdminService,
  ChatSoundType,
  DynamicFieldModelSpec,
  UploadFileDetails,
  UploadType,
  UploaderService,
  DynamicDesignModelSpec,
  ContentStyle,
  UserService,
  WebsiteRegex,
} from '@conpulse-web/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Howl } from 'howler';
import { v4 as uuidv4 } from 'uuid';
import {
  cloneDeep,
  compact,
  concat,
  difference,
  find,
  forEach,
  get,
  groupBy,
  includes,
  isEmpty,
  isUndefined,
  keys,
  map,
  omit,
  orderBy,
  pick,
  toLower,
  uniq,
  uniqBy,
} from 'lodash-es';
import { PermissionService } from 'libs/core/src/lib/services/permission.service';

const quillImageAssetsUploadApiUrl = 'user/upload/company-profile-url';
@Injectable({
  providedIn: 'root',
})
export class UtilityMethodsService {
  quillUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  deleteUploadedFile$: Subject<UploadFileDetails> = new Subject<UploadFileDetails>();
  bucketRootHost: string = '';
  youtubeUrlPatterns = [
    /youtu\.be\/([^#&?]{11})/, // youtu.be/<id>
    /\?v=([^#&?]{11})/, // ?v=<id>
    /&v=([^#&?]{11})/, // &v=<id>
    /embed\/([^#&?]{11})/, // embed/<id>
    /\/v\/([^#&?]{11})/, // /v/<id>
  ];
  // audio
  incomingChatSound = new Howl({ src: '../../../assets/sounds/incoming_chat.mp3', volume: 0.4 });
  outgoingChatSound = new Howl({ src: '../../../assets/sounds/outgoing_chat.mp3', volume: 0.4 });

  private amountSuffixNotation = ['k', 'M', 'B', 'T'];

  constructor(
    private readonly uploaderService: UploaderService,
    private readonly adminService: AdminService,
    private permissionService: PermissionService,
    private userService: UserService,
    @Inject('AppEnvironment') private readonly environmentConfig
  ) {
    this.bucketRootHost = `${environmentConfig.bucketName}.s3.${environmentConfig.s3Region}.amazonaws.com`;
    this.deleteUploadedFile$.subscribe(async (fileDetails) => {
      await this.uploaderService.deleteFile(fileDetails).toPromise();
    });
  }

  get suffixNotation() {
    return this.amountSuffixNotation;
  }

  setSuffixNotations(amount, suffixNotation = this.amountSuffixNotation) {
    if (amount < 1000) return amount.toFixed(2);
    const exp = Math.floor(Math.log(amount) / Math.log(1000));
    const finalNumber = +(amount / Math.pow(1000, exp)).toFixed(2);
    return finalNumber.toString() + suffixNotation[exp - 1];
  }

  getSuffixNotation(amount: number, suffixNotation = this.amountSuffixNotation) {
    if (amount < 1000) return '';
    const exp = Math.floor(Math.log(amount) / Math.log(1000));
    return suffixNotation[exp - 1];
  }

  getFormattedAmount(amount: number) {
    if (amount < 1000) return amount.toFixed(2);
    const exp = Math.floor(Math.log(amount) / Math.log(1000));
    const finalNumber = +(amount / Math.pow(1000, exp)).toFixed(2);
    return finalNumber.toString();
  }

  filterSelect(selectedItemArray, items, visibleItems, visibleSelectedItems, displayField = 'name', uniqueField = '_id') {
    const dropDownItems = [];
    let selectedDisplayItems = [];
    forEach(visibleItems, (element) => {
      dropDownItems.push(element[uniqueField]);
    });
    selectedItemArray = compact(difference(uniq(concat(selectedItemArray, visibleSelectedItems)), difference(dropDownItems, visibleSelectedItems)));
    map(selectedItemArray, (val) => {
      if (!isUndefined(val)) {
        const data = pick(find(items, { [uniqueField]: val }), ['_id', displayField, uniqueField, 'parentName']);
        if (!isEmpty(data)) {
          selectedDisplayItems.push(data);
        }
      }
    });
    selectedDisplayItems = uniqBy(selectedDisplayItems, uniqueField);
    return {
      displayItems: selectedDisplayItems,
      selectedItemArray,
    };
  }

  filterStateSelect(selectedItemArray, items, visibleItems, visibleSelectedItems) {
    const clonedContryStates = cloneDeep(items);
    const clonedvisibleItems = cloneDeep(visibleItems);
    const dropDownItems = [];
    let selectedDisplayItems = [];
    let selectedStatesDisplayItems = [];
    map(clonedvisibleItems, (element) => {
      map(element.states, (state) => {
        dropDownItems.push(state._id);
      });
    });
    selectedItemArray = difference(uniq(concat(selectedItemArray, visibleSelectedItems)), difference(dropDownItems, visibleSelectedItems));
    selectedDisplayItems = map(clonedContryStates, (country) => {
      country.states = compact(
        map(country.states, (state) => {
          return includes(selectedItemArray, state._id) ? state : null;
        })
      );
      return country;
    });
    let statesWithIsoCode = {};
    forEach(selectedDisplayItems, (country) => {
      if (!isEmpty(country.states)) {
        statesWithIsoCode = map(country.states, (state) => {
          return { ...state, isoCode: country.isoCode, countryName: country.name };
        });
        selectedStatesDisplayItems = concat(selectedStatesDisplayItems, statesWithIsoCode);
      }
    });
    const selectedItemWithIsoCode = [];
    const isoCodeArray = keys(groupBy(selectedStatesDisplayItems, 'isoCode'));
    forEach(isoCodeArray, (iso) => {
      const selectedStates = [];
      forEach(selectedStatesDisplayItems, (state) => {
        if (state.isoCode === iso) {
          selectedStates.push(state);
        }
      });
      selectedItemWithIsoCode.push({ countryIso: iso, states: selectedStates });
    });
    return {
      displayItems: selectedDisplayItems,
      selectedItemArray,
      visibleItems,
      items,
      selectedStatesDisplayItems,
      selectedItemWithIsoCode,
    };
  }

  // Filters items of two-level dropdown list based on selected item ids to help display in chip list.
  filterItemSelect(selectedItemArray, items, visibleItems, visibleSelectedItems) {
    const clonedItems = cloneDeep(items);
    const clonedvisibleItems = cloneDeep(visibleItems);
    const dropDownItems = [];
    let selectedDisplayItems = [];
    let selectedChildrenDisplayItems = [];
    map(clonedvisibleItems, (element) => {
      if (!isEmpty(element.children)) {
        map(element.children, (child) => {
          dropDownItems.push(child._id);
        });
      } else {
        dropDownItems.push(element._id);
      }
    });
    selectedItemArray = difference(uniq(concat(selectedItemArray, visibleSelectedItems)), difference(dropDownItems, visibleSelectedItems));
    selectedDisplayItems = map(clonedItems, (item) => {
      if (!isEmpty(item.children)) {
        item.children = compact(
          map(item.children, (item) => {
            return includes(selectedItemArray, item._id) ? item : null;
          })
        );
      } else {
        if (includes(selectedItemArray, item._id)) {
          const filteredItem = omit(item, ['children']);
          item.children = [filteredItem];
        }
      }
      return item;
    });
    let childrenWithParentName = [];
    forEach(selectedDisplayItems, (item) => {
      if (!isEmpty(item.children)) {
        childrenWithParentName = map(item.children, (child) => {
          return { ...child, parentName: item.name };
        });
        selectedChildrenDisplayItems = concat(selectedChildrenDisplayItems, childrenWithParentName);
      } else {
        if (includes(selectedItemArray, item._id)) {
          childrenWithParentName = [{ ...item, parentName: '' }];
          selectedChildrenDisplayItems = concat(selectedChildrenDisplayItems, childrenWithParentName);
        }
      }
    });
    const selectedItemWithParentName = [];
    const parentItemArray = keys(groupBy(selectedChildrenDisplayItems, 'parentName'));
    forEach(parentItemArray, (parentName) => {
      const selectedChildren = [];
      forEach(selectedChildrenDisplayItems, (child) => {
        if (child.parentName === parentName) {
          selectedChildren.push(child);
        }
      });
      selectedItemWithParentName.push({ parentName: parentName, children: selectedChildren });
    });
    return {
      displayItems: selectedDisplayItems,
      selectedItemArray,
      visibleItems,
      items,
      selectedChildrenDisplayItems,
      selectedItemWithParentName,
    };
  }

  searchList(searchText, items, searchField) {
    let filteredItems = [];
    const regex = new RegExp(`${searchText}`, 'i');
    filteredItems = items.filter((item) => regex.test(item[searchField]));
    return filteredItems;
  }

  constructArray(objectArray, key) {
    const idArray = [];
    forEach(objectArray, (singleElement) => {
      const val = get(singleElement, key);
      if (val) {
        idArray.push(val);
      }
    });
    return idArray;
  }

  /**
   *
   * @param projectDetails Details of the specific project
   * @param fieldName name of the field
   * @param fieldList entire list of the field
   * @returns child list for selected industries
   */
  filterIndustryCapabilities = (projectDetails, fieldName, fieldList, isParentRemoved = false) => {
    if (isParentRemoved) {
      this.removeChildValues(projectDetails, fieldName, fieldList, isParentRemoved);
    }
    const childList = [];
    projectDetails[fieldName]?.filter((industry) => {
      fieldList.map((parentIndustry) => {
        if (parentIndustry?._id === industry) {
          parentIndustry?.children?.map((child) => {
            childList.push(Object.assign({ ...child, parentName: parentIndustry.name }));
          });
        }
      });
    });
    return orderBy([...childList], ['name'], ['asc']);
  };

  /**
   * removes child from Project Details
   * @param fieldName name of the field
   * @param fieldList entire list of the field
   * @param id parent id
   */
  removeChildValues = (projectDetails, fieldName, fieldList, id) => {
    let childIds = [];
    fieldList?.map((field) => {
      if (field?._id === id) {
        childIds = field?.children?.map((child) => child._id);
      }
    });
    projectDetails[fieldName] = projectDetails[fieldName]?.filter((id) => childIds.indexOf(id) === -1);
  };

  stateSearchList(searchText, items) {
    const clonedItems = cloneDeep(items);
    const filteredItems = [];
    forEach(items, (item) => {
      if (includes(toLower(item.name), toLower(searchText))) {
        filteredItems.push(item);
      }
    });
    return {
      filteredItems,
      clonedItems,
    };
  }


  /**
   * Returns a colour class name for given index number
   * @param index Index number
   * @returns Class name for given index number
   */
  getClass(index: number) {
    const modvalue = index % 4;
    switch (modvalue) {
      case 0:
      default:
        return 'orange';
      case 1:
        return 'blue';
      case 2:
        return 'dark-green';
      case 3:
        return 'red';
    }
  }

  /**
   * Builds dynamic field model from dynamic field specification
   */
  buildDynamicFieldModel(dynamicFieldModelSpec: DynamicFieldModelSpec[]) {
    const dynamicFieldModel: object = {};
    dynamicFieldModelSpec.forEach((section) => {
      dynamicFieldModel[section.sectionKey] = {};
      section.fields.forEach((field) => {
        dynamicFieldModel[section.sectionKey][field.fieldKey] = '';
      });
    });
    return dynamicFieldModel;
  }

  /**
   * Builds dynamic field model from dynamic field specification
   */
  buildRfpDynamicFieldModel(dynamicFieldModelSpec: DynamicFieldModelSpec[]) {
    const dynamicFieldModel: object = {};
    dynamicFieldModelSpec.forEach((section) => {
      dynamicFieldModel[section.sectionKey] = {};
      section.fields.forEach((field) => {
        dynamicFieldModel[section.sectionKey][field.fieldKey] = {
          value: '',
          isActive: false,
        };
      });
    });
    return dynamicFieldModel;
  }

  /**
   * Builds dynamic design model from dynamic field specification
   */
  buildDynamicDesignModel(dynamicDesignModelSpec: DynamicDesignModelSpec[]) {
    const dynamicDesignModel: object = {};
    dynamicDesignModelSpec.forEach((section) => {
      section.fields.forEach((field) => {
        dynamicDesignModel[field.fieldKey] = {};
      });
    });
    return dynamicDesignModel;
  }

  /**
   * Assigned dynamic field data to the dynamic field model
   * @param fieldModel The target field model to assign data to
   * @param data The field data to be assigned
   * @returns Resultant data
   */
  assignDataToDynamicFieldModel(fieldModel: object, data: object) {
    return Object.assign(fieldModel, data || {});
  }

  /**
   * Gets the YouTube Video's UID from given URL
   * @param url YouTube Video URL
   * @returns Video ID of the YouTube video
   */
  getYouTubeVideoIdFromUrl(url: string) {
    let videoId = '';
    this.youtubeUrlPatterns.forEach((pattern) => {
      if (pattern.test(url)) {
        const executedPattern = pattern.exec(url);
        if (executedPattern) {
          videoId = executedPattern[1];
        }
      }
    });
    return videoId;
  }

  /**
   * Finds and converts link text into HTML hyperlinks
   * @param message The message in which URL should be found and hyperlink conversion to be made
   * @returns Parsed/transformed message
   */
  parseUrlWithinString = (message: string) => {
    if (!message) return;
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
  };

  /**
   * Plays chat sound
   * @param type Chat sound type
   */
  playChatSound(type: ChatSoundType) {
    switch (type) {
      case ChatSoundType.INCOMING:
        this.incomingChatSound.play();
        break;
      case ChatSoundType.OUTGOING:
        this.outgoingChatSound.play();
        break;
    }
  }

  /**Get image location */
  getAssetsPath(fileName) {
    const extension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
    switch (extension) {
      case '.doc':
      case '.docx':
        return 'assets/images/doc.svg';
      case '.xls':
      case '.xlsx':
        return 'assets/images/excel.svg';
      case '.jpeg':
      case '.png':
      case '.jpg':
        return 'assets/images/image.svg';
      case 'url':
        return 'assets/images/Website.svg';
      default:
        return 'assets/images/pdf.svg';
    }
  }

  /**
   * Adds numbering to the fields
   */
  getFieldsWithNumber(switchStates, sectionNumbers, startNumber) {
    const fieldsInView = [];
    const keys = Object.keys(switchStates);
    keys.map((key) => {
      if (switchStates[key]) {
        fieldsInView.push(key);
      } else {
        sectionNumbers[key] = '';
      }
    });
    fieldsInView.map((obj, index) => {
      sectionNumbers[obj] = `${startNumber}.${index + 1} `;
    });
  }

  /**
   * To fetch file format List
   * @returns list of file formats
   */
  async getFileFormat(projectId = '') {
    let fileSettings = [];
    fileSettings = get(await this.adminService.getFileFormat(projectId).toPromise(), 'data', null);
    return { fileList: fileSettings['fileFormat'], fileLimit: fileSettings['fileLimit'] };
  }

  /**
   * Loads allowed file formats from super Admin
   */

  async loadAllowedFileFormats(projectId?) {
    const { fileList, fileLimit } = await this.getFileFormat(projectId);
    let allowedFiles = '',
      formatList = [];
    if (fileList?.length) {
      formatList = [];
      fileList.map((data) => {
        formatList.push(`.${data.value.fileFormat}`);
      });
      allowedFiles = formatList.join(',');
    }
    return { fileList, formatList, allowedFiles, fileLimit };
  }

  getChatAssetsPath(fileName) {
    const extension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
    switch (extension) {
      case '.doc':
      case '.docx':
        return 'assets/chat-doc.svg';
      case '.xls':
      case '.xlsx':
        return 'assets/chat-xls.svg';
      default:
        return 'assets/chat-pdf.svg';
    }
  }

  /**
   * Finds the maximum file size
   */
  findMaxFileSize(fileList, fileExtension) {
    return fileList.find((ele) => ele.value.fileFormat === fileExtension.split('.')[1])?.value?.fileSize;
  }

  collectAllRouteParams(routerObject: Router): Record<string, string> {
    let params: Record<string, string> = {};
    const stack: ActivatedRouteSnapshot[] = [routerObject.routerState.snapshot.root];
    while (stack.length > 0) {
      const route = stack.pop();
      if (route === undefined) {
        continue;
      }

      params = { ...params, ...route.params };

      stack.push(...route.children);
    }
    return params;
  }

  /**
   * Constructs a date object with given date and time separately
   * @param date date object
   * @param time HH:MM format
   * @returns
   */
  constructDateTimeObject(date: Date | string, time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    // Ensure the provided values are within valid ranges
    const validHours = Math.min(23, Math.max(0, hours));
    const validMinutes = Math.min(59, Math.max(0, minutes));
    const newDate = new Date(date);
    newDate.setHours(validHours, validMinutes, 0, 0); // Set seconds and milliseconds to 0
    return newDate;
  }

  /**
   * Generate time list in 15 min interval
   * @returns time array in 15 min interval
   */
  generateTime() {
    const options: { label: string; hrs: number; min: number }[] = [];
    for (let hours = 1; hours <= 12; hours++) {
      let minutes = 0;
      for (let j = 0; j < 4; j++) {
        options.push({
          label: `${hours}:${minutes < 10 ? '0' : ''}${minutes}`,
          hrs: hours,
          min: minutes,
        });
        minutes = minutes + 15;
      }
    }
    return options;
  }

  /**
   * get time label and time session
   */
  getTimeLabel(times) {
    const currentDateTime = new Date();
    const roundedMinutes = Math.ceil(currentDateTime.getMinutes() / 15) * 15;
    currentDateTime.setMinutes(roundedMinutes, 0, 0);
    const time = new Date(currentDateTime.getTime() + 0 * 15 * 60 * 1000);
    const currentHours = time.getHours() % 12 || 12;
    let currentMinutes = time.getMinutes();
    const selectedTime = times.find((value) => value.hrs === currentHours && value.min === currentMinutes);
    const index = times.findIndex((value) => value.hrs === currentHours && value.min === currentMinutes);
    let timeValue = {};
    timeValue['startTime'] = selectedTime?.label;
    timeValue['startSession'] = time.getHours() >= 12 ? 'PM' : 'AM';
    timeValue['endTime'] = times[index + 2]?.label;
    timeValue['endSession'] = time.getHours() >= 12 ? 'PM' : 'AM';
    return timeValue;
  }

  /**
   * Compare two objects to determine whether they are equal.
   */
  deepEqual(initialValue, currentValue) {
    if (currentValue && initialValue) {
      let key1 = Object.keys(currentValue);
      let key2 = Object.keys(initialValue);
      if (key1.length !== key2.length) return false;
      for (let key of key1) {
        if (JSON.stringify(currentValue[key]) !== JSON.stringify(initialValue[key])) return false;
      }
    } else if (currentValue || initialValue) return false;
    return true;
  }

  checkAccess(module, access) {
    let canAccess = false;
    this.permissionService.getPermissionsList().subscribe((permissionDetails) => {
      const permissionAccess = permissionDetails[module];
      canAccess = access.some((accessDetails) => permissionAccess?.includes(accessDetails));
    });
    return canAccess;
  }
  // Deep clone object
  cloneDeepObject(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    const clone = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = this.cloneDeepObject(obj[key]);
      }
    }
    return clone;
  }

  /**
   * show tooltip when text truncate
   */
  disableTooltip(event) {
    return event.scrollWidth <= event.clientWidth;
  }

  formatDocInput(documentArr, docNameField, docKeyField, docTypeField, isUploadedField) {
    return documentArr.map((doc) => ({
      key: doc[docKeyField],
      name: doc[docNameField],
      isUploaded: doc[isUploadedField] || true,
      type: doc[docTypeField] || 2,
    }));
  }

  isPreviewAvailable(fileKey: string) {
    const fileArray = fileKey.split('.');
    return ['jpeg', 'png', 'svg', 'txt', 'jpg', 'pdf'].includes(fileArray[fileArray?.length - 1]) || WebsiteRegex.test(fileKey);
  }

  isDownloadSupported(fileKey: string) {
    return !WebsiteRegex.test(fileKey);
  }

  convertNumberToAmt = (value) => {
    if (value) {
      return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };
}
