import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDocsOrLinkComponent } from './upload-docs-or-link.component';
import { I18NextModule } from 'angular-i18next';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module';



@NgModule({
  declarations: [UploadDocsOrLinkComponent],
  imports: [
    CommonModule,
    I18NextModule,
    FormsModule,
    ConpulseMaterialModule,
  ],
  exports: [UploadDocsOrLinkComponent]
})
export class UploadDocsOrLinkModule { }
