import { Injectable } from '@angular/core';
import { BaseApiService } from './base.api';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionService } from '../services/permission.service';
import { SowDynamicFormsValue } from '../interfaces';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserV2Service {
  private permissionDetails$ = new BehaviorSubject<object>({});
  public reloadSelectionTab$ = new BehaviorSubject<object>({});

  route = {
    module: 'module/side-menu',
    providerProfileNav: 'module/provider-profile/tab',
    userProfile: 'user/profile',
    appendix: {
      updateAppendix: 'projects/sow/{sowId}/appendix-sections/section',
      deleteAppendix: 'projects/sow/{sowId}/appendix-sections/section/{sectionId}',
      deleteChildAppendix: 'projects/sow/{sowId}/appendix-sections/{sectionId}/field/{fieldId}',
      reorderChildSection: 'projects/sow/{sowId}/appendix-sections/{sectionId}/fields/reorder',
      reorderParentAppendix: 'projects/sow/{sowId}/appendix-sections/sections/reorder',
      reorderAppendix: 'projects/sow/{sowId}/appendix-sections/reorder',
      validateAppendix: 'projects/sow/{sowId}/appendix-sections/{sectionId}/field/{fieldId}/validate',
      statusUpdate: 'projects/sow/{sowId}/appendix-sections/{sectionId}/fields/status',
      getAppendix: '/company/projects/sow/{sowId}/appendix/fields',
    },
    execution: {
      finalization: 'contract/execution/finalization',
      changeMode: 'signature/change-mode',
      signatureStatus: 'contract/signature/status',
      voidSignature: 'contract/void/signature',
      member: '/contract/member/finalization',
      headerText: 'contract/signature/text',
    },
    changes: {
      getChangesById: 'project-management/changes',
      getTabChanges: 'project-management/{changeId}/latestChanges',
      getTabList: '/project-management/{changeId}/tab-list',
      objectives: 'project-management/changes-preparation/{changeId}/objectives',
      tracingTable: 'project-management/changes-tracing/{changeId}',
      layout: 'project-management/changes-layout/{changeId}',
      validate: 'project-management/changes/{changeId}/section/{sectionId}/validate-field',
      generatePdf: 'project/project-management/changes/export-history/{changeId}',
      sign: 'project-management/changes/signature',
      headerValidate: 'project-management/validate/changes/header',
      markFinalDoc: 'project/project-management/changes/markAsFinal/',
      uploadFinalDoc: 'project-management/upload-executed-doc',
      docDelete: 'project-management/document/changes',
    },
    google: {
      authUrl: '/google/auth',
      updateToken: 'google/update/token',
      integrationSTatus: 'calender/integration/status',
      calendarStatus: 'user/calendar/status',
      revoke: 'user/calendar/revoke',
    },
    microsoft: {
      authUrl: 'microsoft/auth',
    },
    projectHome: {
      contractList: 'project/contracts',
    },
    projectHomeTab: 'project/{projectId}/step/{step}/home',
    projectManagementBaseline: 'project-management/{projectManagementId}/changes/baseline',
    selectionLostEmail: 'company/projects/{projectId}/proposal/selection-tab/lost-email',
    projectLockUnLockUpdate: 'project/step/lock-unlock/{projectId}',
    revokeSowInvite: 'company/projects/proposal/revoke-sow-invite',
    city: 'company/project/sourcing/cities',
    cgnCity: 'company/project/cgn-cities',
    deactivationStatus: '/project/deactivation-status/{projectId}',
    allClientsInProject: 'projects/all-clients',
    marketplaceSettings: 'admin/marketplace/settings',
    projectPriceVisibility: 'project/{projectId}/price-visibility',
    score: 'project/score/visibility',
    teamMemberScoreVisibility: 'company/project/team/member/score/visibility'
  };

  constructor(private baseApi: BaseApiService, private permissionService: PermissionService) {}

  getModulePermission() {
    return this.baseApi.httpGet(this.route.module).pipe(
      map((data) => {
        this.permissionService.setModuleAccessDetails(data.data.mainMenu);
        return data;
      }),
      catchError((error) => {
        return error.message;
      })
    );
  }

  getProviderProfileNav() {
    return this.baseApi.httpGet(this.route.providerProfileNav);
  }

  getProfileDetails(): Observable<any> {
    const params = new URLSearchParams();
    return this.baseApi.httpGet(this.route.userProfile);
  }

  updateUserProfile(profile): Observable<any> {
    // const params = new URLSearchParams();
    return this.baseApi.httpPut(this.route.userProfile, profile);
  }

  getDynamicFields(sowId: string): Observable<any> {
    const url = this.route.appendix.getAppendix.replace('{sowId}', sowId);
    return this.baseApi.httpGet(url);
  }

  updateAppendixFields(sowId: string, body: { addedSections: SowDynamicFormsValue[]; editedSections: SowDynamicFormsValue[] }): Observable<any> {
    const url = this.route.appendix.updateAppendix.replace('{sowId}', sowId);
    return this.baseApi.httpPut(url, body);
  }

  deleteAppendixFields(sowId: string, sectionId: string): Observable<any> {
    const url = this.route.appendix.deleteAppendix.replace('{sowId}', sowId).replace('{sectionId}', sectionId);
    return this.baseApi.httpDelete(url);
  }

  deleteAppendixChildFields(sowId: string, sectionId: string, fieldId: string): Observable<any> {
    const url = this.route.appendix.deleteChildAppendix.replace('{sowId}', sowId).replace('{sectionId}', sectionId).replace('{fieldId}', fieldId);
    return this.baseApi.httpDelete(url);
  }

  reorderAppendixParent(sowId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    const url = this.route.appendix.reorderParentAppendix.replace('{sowId}', sowId);
    return this.baseApi.httpPut(url, body);
  }

  reorderAppendixChild(sowId: string, sectionId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    const url = this.route.appendix.reorderChildSection.replace('{sectionId}', sectionId).replace('{sowId}', sowId);
    return this.baseApi.httpPut(url, body);
  }

  reorderAppendix(sowId: string, body: { sectionId: string; fields: [] }): Observable<any> {
    const url = this.route.appendix.reorderAppendix.replace('{sowId}', sowId);
    return this.baseApi.httpPut(url, body);
  }

  updateAppendixFieldStatus(sowId: string, sectionId: string, body: { fieldId: string; isActive: boolean }) {
    const url = this.route.appendix.statusUpdate.replace('{sowId}', sowId).replace('{sectionId}', sectionId);
    return this.baseApi.httpPut(url, body);
  }

  validateAppendixFields(sowId: string, sectionId: string, fieldId: string): Observable<any> {
    const url = this.route.appendix.validateAppendix.replace('{sowId}', sowId).replace('{fieldId}', fieldId).replace('{sectionId}', sectionId);
    return this.baseApi.httpPost(url);
  }

  getFinalizationData(id, type) {
    const params = new URLSearchParams();
    params.append('type', type);
    const url = `${this.route.execution.finalization}/${id}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  removeFinalizationTeam(id, type, userId) {
    const params = new URLSearchParams();
    params.append('type', type);
    params.append('userId', userId);
    const url = `${this.route.execution.finalization}/${id}?${params.toString()}`;
    return this.baseApi.httpDelete(url);
  }

  addFinalizationTeam(id, type, userIds) {
    const url = `${this.route.execution.finalization}/${id}`;
    return this.baseApi.httpPut(url, { type, userIds });
  }

  changeSignatureMode(contractId, type, mode) {
    const params = new URLSearchParams();
    params.append('type', type);
    const url = `${this.route.execution.changeMode}/${contractId}?${params.toString()}`;
    return this.baseApi.httpPut(url, { mode });
  }

  updateContractSignatoryStatus(contractId, type, status) {
    const url = `${this.route.execution.signatureStatus}/${contractId}`;
    return this.baseApi.httpPut(url, { type, status });
  }

  voidSignaturePdf(contractId, type) {
    const params = new URLSearchParams();
    params.append('type', type);
    const url = `${this.route.execution.voidSignature}/${contractId}?${params.toString()}`;
    return this.baseApi.httpPut(url);
  }

  getMemberForTeam(contractId, firmId, type) {
    const params = new URLSearchParams();
    params.append('type', type);
    params.append('firmId', firmId);
    const url = `${this.route.execution.member}/${contractId}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  getTabList(changeId) {
    const url = this.route.changes.getTabList.replace('{changeId}', changeId);
    return this.baseApi.httpGet(url);
  }
  getTracingChanges(changeId) {
    const url = this.route.changes.getTabChanges.replace('{changeId}', changeId);
    return this.baseApi.httpGet(url);
  }
  getChangesDetails(changeId: string, sectionId: string) {
    const params = new URLSearchParams();
    params.append('sectionId', sectionId);
    const url = this.route.changes.getTabChanges.replace('{changeId}', changeId);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  updateObjectives(changeId: string, body: object[]) {
    const url = this.route.changes.objectives.replace('{changeId}', changeId);
    return this.baseApi.httpPut(url, { payload: body });
  }

  getTracingTable(changeId: string) {
    const url = this.route.changes.tracingTable.replace('{changeId}', changeId);
    return this.baseApi.httpGet(url);
  }

  updateChangesLayout(changeId: string, payload: Object) {
    const url = this.route.changes.layout.replace('{changeId}', changeId);
    return this.baseApi.httpPut(url, payload);
  }

  getChangesLayout(changeId: string) {
    const url = this.route.changes.layout.replace('{changeId}', changeId);
    return this.baseApi.httpGet(url);
  }

  validateField(changeId: string, fieldKey: string, sectionId: string) {
    const params = new URLSearchParams();
    params.append('fieldKey', fieldKey);
    const url = this.route.changes.validate.replace('{changeId}', changeId).replace('{sectionId}', sectionId);
    return this.baseApi.httpPut(`${url}?${params.toString()}`);
  }

  generatePdf(changeId: string, isPreview: boolean) {
    const params = new URLSearchParams();
    params.append('isPreview', isPreview.toString());
    const url = this.route.changes.generatePdf.replace('{changeId}', changeId);
    return this.baseApi.pdfHttpPut(`${url}?${params.toString()}`);
  }

  getExportHistory(changeId: string) {
    const url = this.route.changes.generatePdf.replace('{changeId}', changeId);
    return this.baseApi.httpGet(`${url}`);
  }

  deleteExportHistory(changeId: string, exportId: string) {
    const params = new URLSearchParams();
    params.append('exportId', exportId);
    const url = this.route.changes.generatePdf.replace('{changeId}', changeId);
    return this.baseApi.httpDelete(`${url}?${params.toString()}`);
  }

  getChangesById(changeId) {
    return this.baseApi.httpGet(`${this.route.changes.getChangesById}/${changeId}`);
  }

  signChanges(changeId: string, values): Observable<any> {
    return this.baseApi.httpPost(`${this.route.changes.sign}/${changeId}`, values);
  }

  validateHeader(changeId) {
    return this.baseApi.httpPut(`${this.route.changes.headerValidate}/${changeId}`);
  }

  markExportedVersionAsFinal(changeId: string, exportedId: string, type: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('exportedId', exportedId);
    params.append('type', type);
    return this.baseApi.httpPut(`${this.route.changes.markFinalDoc}${changeId}?${params?.toString()}`);
  }

  uploadChangesFinalDoc(changeId: string, value: object): Observable<any> {
    const requestUrl = `${this.route.changes.uploadFinalDoc}/${changeId}`;
    return this.baseApi.httpPost(requestUrl, value);
  }

  deleteDoc(changeId) {
    const requestUrl = `${this.route.changes.docDelete}/${changeId}`;
    return this.baseApi.httpDelete(requestUrl);
  }

  getAuthUrl(url) {
    const params = new URLSearchParams();
    params.append('url', url);
    return this.baseApi.httpGet(`${this.route.google.authUrl}?${params?.toString()}`);
  }

  updateGoogleToken(code: string) {
    const params = new URLSearchParams();
    params.append('code', code);
    return this.baseApi.httpGet(`${this.route.google.updateToken}?${params?.toString()}`);
  }

  updateCalendarConcent(code: string, isMicrosoft: boolean) {
    const params = new URLSearchParams();
    params.append('code', code);
    return isMicrosoft
      ? this.baseApi.httpPost(`${this.route.microsoft.authUrl}?${params?.toString()}`)
      : this.baseApi.httpGet(`${this.route.google.updateToken}?${params?.toString()}`);
  }

  getIntegrationStatus(state?: string) {
    const params = new URLSearchParams();
    if (state) params.append('state', state);
    return this.baseApi.httpGet(`${this.route.google.integrationSTatus}?${params?.toString()}`);
  }

  getCalendarStatus(state: string) {
    const params = new URLSearchParams();
    params.append('state', state);
    return this.baseApi.httpGet(`${this.route.google.calendarStatus}?${params?.toString()}`);
  }

  updateMeetingDetails(id: string, data: string) {
    localStorage.setItem(id, data);
  }

  getMeetingDetails(id: string) {
    return localStorage.getItem(id);
  }

  getProjectHomeContactList(projectId) {
    const url = `${this.route.projectHome.contractList}/${projectId}`;
    return this.baseApi.httpGet(url);
  }

  revokeCalendarAccess(meetingType) {
    const params = new URLSearchParams();
    params.append('meetingType', meetingType);
    return this.baseApi.httpPut(`${this.route.google.revoke}?${params?.toString()}`);
  }

  updateSignatureText(id, type, text) {
    const url = `${this.route.execution.headerText}/${id}`;
    return this.baseApi.httpPut(url, { type, text });
  }

  getHomeTab(projectId: string, step: string, firmId?: string) {
    const url = this.route.projectHomeTab.replace('{projectId}', projectId).replace('{step}', step);
    const params = new URLSearchParams();
    if (firmId) params.append('firmId', firmId);
    return this.baseApi.httpGet(firmId ? `${url}?${params.toString()}` : url);
  }

  updateProjectManagementBaseline(projectManagementId: string, data: object) {
    const url = this.route.projectManagementBaseline.replace('{projectManagementId}', projectManagementId);
    return this.baseApi.httpPost(url, data);
  }

  getSelectionLostEmail(projectId: string, firmId: string) {
    const url = this.route.selectionLostEmail.replace('{projectId}', projectId);
    const params = new URLSearchParams();
    params.append('firmId', firmId);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  updateSelectionLostEmail(projectId: string, data: object) {
    const url = this.route.selectionLostEmail.replace('{projectId}', projectId);
    return this.baseApi.httpPost(url, data);
  }

  updateProjectLockUnlockStatus(projectId, step) {
    const url = this.route.projectLockUnLockUpdate.replace('{projectId}', projectId);
    const params = new URLSearchParams();
    params.append('step', step);
    return this.baseApi.httpPut(`${url}?${params.toString()}`);
  }

  revokeSowInvite(firmId, projectId) {
    return this.baseApi.httpPut(this.route.revokeSowInvite, { firmId, projectId });
  }

  getCity(country?) {
    const params = new URLSearchParams();
    if (country) {
      const data = Array.isArray(country) ? country.join(',') : country;
      params.append('country', data);
    }
    return this.baseApi.httpGet(`${this.route.city}?${params.toString()}`);
  }

  getCgnCities(data) {
    const params = new URLSearchParams();
    if (data?.country?.length) {
      const country = Array.isArray(data.country) ? data.country.join(',') : data.country;
      params.append('country', country);
    }
    params.append('search', data?.search);
    params.append('page', data?.page);
    params.append('limit', data?.limit);
    return this.baseApi.httpGet(`${this.route.cgnCity}?${params.toString()}`);
  }

  getProjectDeactivationStatus(projectId) {
    return this.baseApi.httpGet(`${this.route.deactivationStatus.replace('{projectId}', projectId)}`);
  }

  getAllClientsInProjects(){
    return this.baseApi.httpGet(this.route.allClientsInProject);
  }
  
  getMarketPlaceSettings() {
    return this.baseApi.httpGet(this.route.marketplaceSettings);
  }

  updateMarketPlaceSettings(data) {
    return this.baseApi.httpPut(this.route.marketplaceSettings, data);
  }

  updateProjectPriceVisibility(projectId: string) {
    const url = this.route.projectPriceVisibility.replace('{projectId}', projectId);
    return this.baseApi.httpPut(url);
  }

  updateProjectScoreVisibility(id, data) {
    return this.baseApi.httpPut(`${this.route.score}/${id}`, data);
  }

  getProjectScoreVisibility(id) {
    return this.baseApi.httpGet(`${this.route.score}/${id}`);
  }

  updateProposalEvaluatorTeamPricingVisibility(data) {
    return this.baseApi.httpPut(this.route.teamMemberScoreVisibility, data)
  }

}
