import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UtilityMethodsService } from '../../services/utility-methods.service';
import { CONSTANTS } from 'libs/core/src/lib/constants';
import { UserService } from '@conpulse-web/core';
import { concat, difference, filter, find, intersection, isEmpty, map, remove } from 'lodash-es';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit {
  originalItems: any;
  flagUrl: string;
  dropDownItems: object[];
  matSelectedItems: string[] = [];
  selectedDisplayItems: any = [];
  selectedItems: string[] = [];
  @Input() type: string;
  @Input() loading = true;
  @Input() isDisabled = false;
  @Input() isSelectMandatory: boolean;
  @Input() matInputPlaceHolder: string;
  @Input() matSelectPlaceHolder: string;
  @Input() matSearchText: string;
  @Input() showEllipisis = false;
  @Output() selectComplete = new EventEmitter();
  showMoreChips: boolean = false;

  @Input()
  set items(value: object[]) {
    if (value) {
      this.dropDownItems = this.originalItems = value;
      this.refreshSelectedItems();
    }
  }

  @Input()
  set markedItems(value: string[]) {
    if (value) {
      //this.selectedItems = this.matSelectedItems = value;
      this.doneSelect(value, true);
    }
  }

  activeClassIndex: number;
  constructor(private utilityMethod: UtilityMethodsService, private userService: UserService) {
    this.flagUrl = `${this.userService.s3PublicUrl}flags/`;
  }

  ngOnInit() {}

  refreshSelectedItems() {
    this.selectedItems.map((countryId) => {
      let found = this.dropDownItems.find((country) => country['_id'] == countryId);
      if (!found) this.removeSelected(countryId);
    });
  }

  isSelectedAll(countryId) {
    const stateIds = map(find(this.originalItems, ['_id', countryId]).states, '_id');
    return intersection(stateIds, this.matSelectedItems).length === stateIds.length;
  }
  isIntermediate(countryId) {
    const stateIds = map(find(this.originalItems, ['_id', countryId]).states, '_id');
    return difference(stateIds, this.matSelectedItems).length > 0;
  }

  activeToggler(selectedIndex) {
    this.activeClassIndex = this.activeClassIndex === selectedIndex ? null : selectedIndex;
  }

  doneSelect(matSelectedItems, isFirstChange) {
    this.selectedDisplayItems = [];

    const customFilterResponse = this.utilityMethod.filterSelect(this.selectedItems, this.originalItems, this.dropDownItems, matSelectedItems);

    this.selectedItems = this.matSelectedItems = customFilterResponse.selectedItemArray;

    this.matSelectedItems.map((countryId) => {
      let country = this.originalItems.find((country) => countryId == country['_id']);
      this.selectedDisplayItems.push(country);
    });
    if (!isFirstChange) {
      this.selectComplete.emit({ type: this.type, items: this.selectedItems });
    }
  }

  search(searchText) {
    this.dropDownItems = [];
    this.matSearchText = searchText;
    const response = this.utilityMethod.stateSearchList(searchText, this.originalItems);
    this.dropDownItems = response.filteredItems;
    this.originalItems = response.clonedItems;
  }

  clearSearch() {
    this.dropDownItems = this.originalItems;
    this.matSearchText = '';
    this.selectedItems = [];
    this.selectedDisplayItems = [];
    this.doneSelect(this.selectedItems, false);
  }

  handleChangeCheckbox(checked, countryId) {
    const stateIds = map(find(this.originalItems, ['_id', countryId]).states, '_id');
    if (checked) {
      this.selectedItems = concat(this.selectedItems, stateIds);
    } else {
      this.selectedItems = difference(this.selectedItems, stateIds);
    }
    this.doneSelect(this.selectedItems, false);
  }

  removeSelected(id): void {
    remove(this.selectedDisplayItems, { _id: id });
    this.selectedItems = filter(this.selectedItems, (singleId) => singleId !== id);
    this.showMoreChips = this.selectedItems.length > 3;
    this.doneSelect(this.selectedItems, false);
  }

  check() {
    return isEmpty(this.selectedDisplayItems) && isEmpty(this.matSearchText);
  }
}
