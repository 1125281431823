import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { InputChipComponent } from './input-chip.component';
import { ConpulseMaterialModule } from '../../material-module/index';

@NgModule({
  declarations: [InputChipComponent],
  imports: [CommonModule, ConpulseMaterialModule, I18NextModule],
  exports: [InputChipComponent],
})
export class InputChipModule {}
