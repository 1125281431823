import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConpulseMaterialModule } from '../../material-module/index';
import { FloatingChatComponent } from './floating-chat.component';
import { ChatMessagesModule } from '../chat-messages/chat-messages.module';
import { ConpulseModule } from '../../conpulse.module';

@NgModule({
  declarations: [FloatingChatComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    ChatMessagesModule
  ],
  exports: [FloatingChatComponent]
})
export class FloatingChatModule {}
