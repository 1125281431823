import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormValidationChipComponent } from './dynamic-form-validation-chip.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [DynamicFormValidationChipComponent],
  imports: [CommonModule, I18NextModule],
  exports: [DynamicFormValidationChipComponent],
})
export class DynamicFormValidationChipModule {}
