<div class="onboarding-drawer">
    <form role="form" #form="ngForm" class="client-user pt-0">
      <div class="drawer-header d-flex align-items-center justify-content-between p-4">
        <h4 class="drawer-title">{{ title | i18next }}</h4>
        <mat-icon class="drawer-close-icon pointer" (click)="checkForUnsavedChanges(false)">close</mat-icon>
      </div>
  
      <div class="client-content drawer-details px-4 pb-4" id="organisationalUnit">
        <div class="inner-client pt-1">
          <div *ngIf="sectionDetails.isSectionEditable || sectionDetails.isQuestionEditable">
            <div class="w-100 px-0">
              <label>{{ 'Section Name' | i18next }}</label>
              <mat-form-field class="full-width" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }" floatLabel="never">
                <input
                  matInput
                  [(ngModel)]="sectionDetails.title"
                  name="sectionName"
                  placeholder="{{ 'Enter section name' | i18next }}"
                  #sectionNameRef="ngModel"
                  [required]="true"
                  [disabled]="sectionDetails.isQuestionEditable"
                  (blur)="sectionDetails.title = trim(sectionDetails.title)"
                />
                <mat-error *ngIf="sectionNameRef.hasError('required')">{{ 'Section Name is required' | i18next }}</mat-error>
              </mat-form-field>
            </div>
          </div>
  
          <ng-container *ngIf="sectionDetails.isQuestionEditable">
            <div class="mb-4 question-section">
              <div>
                <div class="w-100 px-0">
                  <div class="d-flex align-items-center justify-content-between">
                    <label>{{ 'Question' | i18next }}</label>
                  </div>
                  <mat-form-field class="full-width" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }" floatLabel="never">
                    <textarea
                      matInput
                      [(ngModel)]="sectionDetails.question.title"
                      name="question"
                      placeholder="{{ 'Enter Question' | i18next }}"
                      [required]="true"
                      #questions="ngModel"
                      (blur)="sectionDetails.question.title = trim(sectionDetails.question.title)"
                    ></textarea>
                    <mat-error *ngIf="questions.hasError('required')">{{ 'Question is required' | i18next }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <div class="w-100 px-0">
                  <label>{{ 'Answer Type' | i18next }}</label>
                  <mat-radio-group
                    color="secondary"
                    class="full-width"
                    [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }"
                    floatLabel="never"
                    [disabled]="isLoading"
                    [required]="true"
                    #ansType="ngModel"
                    [(ngModel)]="sectionDetails.question.type"
                    (ngModelChange)="resetDocumentDetails(i)"
                    name="answer"
                  >
                    <div class="d-flex display-column">
                      <mat-radio-button class="mat-secondary flex-1" [value]="answerType.Input">{{ 'Input' | i18next }}</mat-radio-button>
                      <mat-radio-button class="mat-secondary flex-1" [value]="answerType.Template">{{ 'Template' | i18next }}</mat-radio-button>
                    </div>
                    <div class="d-flex display-column">
                      <mat-radio-button class="mat-secondary flex-1" [value]="answerType.Upload">{{ 'Upload' | i18next }}</mat-radio-button>
                    </div>
                  </mat-radio-group>
                  <mat-error *ngIf="ansType.hasError('required')">{{ 'Question is required' | i18next }}</mat-error>
                </div>
              </div>
              <ng-container *ngIf="sectionDetails.question.type === answerType.Template">
                <div>
                  <div class="mt-3 flex-1">
                    <ng-container>
                      <p class="form-label mb-1">{{ 'Template Format' | i18next }}</p>
                      <conpulse-web-file-input
                        [onlyDocumentUpload]="false"
                        [accept]="formatList"
                        [fileLimit]="fileList"
                        [maxCount]="1"
                        [multiple]="false"
                        [document]="selectedDocument"
                        (docUploaded)="onValueChanges($event)"
                        (download)="downloadDocument($event)"
                        [showDocViewBtn]="true"
                        (view)="viewDoc($event)"
                        [showDelete]="!isInvitedFirm"
                      ></conpulse-web-file-input>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
  
      <div class="drawer-footer d-flex align-items-center justify-content-start p-4">
        <button class="mr-12" color="accent" mat-stroked-button (click)="checkForUnsavedChanges(false)" type="button" back-button>
          {{ 'Cancel' | i18next }}
        </button>
        <button
          color="primary"
          type="button"
          mat-flat-button
          [disabled]="isLoading || isEqual(sectionDetails, initialSectionDetails) || form.invalid"
          (click)="submitForm()"
        >
          {{ sectionDetails.isEdit ? ('Update' | i18next) : ('Add' | i18next) }}
        </button>
      </div>
    </form>
  </div>
  