import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientBillingRoutingModule } from './client-billing-routing.module';
import { ClientBillingComponent } from './client-billing.component';
import { AddInvoiceBillingComponent } from './add-invoice-billing/add-invoice-billing.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { CommonDialogModule } from '../common-dialog/common-dialog.module';
import { BillingTableComponent } from './billing-table/billing-table.component';
import { AmountConversionPipe } from '../../pipes';
import { AddEditInvoiceComponent } from './add-edit-invoice/add-edit-invoice.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [ClientBillingComponent, AddInvoiceBillingComponent, BillingTableComponent, AddEditInvoiceComponent],
  imports: [CommonModule, ClientBillingRoutingModule, ConpulseMaterialModule, ConpulseModule, CommonDialogModule, I18NextModule],
  exports: [BillingTableComponent, ClientBillingComponent],
  providers: [AmountConversionPipe],
})
export class ClientBillingModule {}
