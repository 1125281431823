<ng-container *ngIf="showUploadSection && (multiple || (!multiple && !documents?.length))">
  <div
    class="file-upload-container d-flex flex-column align-items-center justify-content-center"
    fileDrop
    (fileDropped)="onFileUpload($event)"
    [pauseDrop]="disabled"
  >
    <div class="file-upload-content d-flex flex-column align-items-center justify-content-center">
      <img src="assets/images/fileUpload.png" />
      <p class="m-t-8 txt-grey-lighten13 f-13">Drag or <b class="txt-dark-green pointer" (click)="!disabled && fileUpload.click()">Upload</b> your file here</p>
      <p class="mt-1 txt-grey-lighten12 f-13">{{ formatDisplayText?.join(', ') }}</p>
      <input
        [multiple]="multiple"
        hidden="true"
        accept="{{ allowedFormats }}"
        type="file"
        #fileUpload
        value=""
        id="fileRef"
        name="DocumentUpload"
        [disabled]="disabled"
        (input)="onFileInput($event)"
      />
    </div>
    <ng-container *ngIf="!onlyDocumentUpload">
      <div class="p-y-12">
        <p class="txt-grey-lighten-7 f-14 hr-lines">{{ 'Or' }}</p>
      </div>
      <div class="w-100">
        <mat-form-field class="full-width mb-0" floatLabel="never">
          <input
            matInput
            [(ngModel)]="driveLink"
            placeholder="{{ 'Paste drive link here' }}"
            (ngModelChange)="updateLinkAlreadyExists()"
            [pattern]="websiteRegex"
            [disabled]="disabled"
            #newDriveLink="ngModel"
            name="driveLink"
            (keyup.enter)="driveLink?.trim() && !isLinkAlreadyExists && !newDriveLink.hasError('pattern') && addLink()"
          />
          <mat-error *ngIf="newDriveLink.hasError('pattern')">{{ 'Invalid Drive Link' }}</mat-error>
          <span matSuffix class="add">
            <button
              class="small mr-1"
              type="button"
              mat-flat-button
              color="secondary"
              [disabled]="disabled || isLinkAlreadyExists || !driveLink || !newDriveLink.valid"
              (click)="addLink()"
            >
              {{ 'Add' }}
            </button></span
          >
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</ng-container>
<div class="mt-3">
  <ng-container *ngFor="let doc of documents; let i = index">
    <div class="mb-2">
      <conpulse-web-file-card [document]="doc" (delete)="onRemoveDocument(i)" (view)="viewDoc($event)" [disabled]="disabled" [showDelete]="showDelete" [showDocViewBtn]="showDocViewBtn" (download)="onDownload($event)" [nameMaxWidth]="cardMaxWithSelector"></conpulse-web-file-card>
    </div>
  </ng-container>
</div>
