import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
//TODO: Need to use commonDialog from here and remove from the client-portal shared folder
@Component({
  selector: 'conpulse-web-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit, OnDestroy {

  title?: string;
  body?: any;
  isSubmitButtonDisabled?: boolean = true;
  isLoading?: boolean = false;
  showFooterButtons: boolean = false;
  cancelButtonName: string;
  submitButtonName: string;
  submitButtonColor: string = 'primary';
  parentData: unknown = '';
  componentRef: ComponentRef<any>;
  @ViewChild('dialogBody', { read: ViewContainerRef }) dialogBody: ViewContainerRef;
  disableButtonSubscriber: Subscription | null = null;
  dialogLoadingSubscription: Subscription | null = null;
  closeDialogSubscription: Subscription | null = null;
  closeDialogSubscriptionWithInformation: Subscription | null = null;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver, 
    private viewContainerRef: ViewContainerRef,
    private cdref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.showFooterButtons = data.showFooterButtons;
      this.cancelButtonName = data.cancelButtonName;
      this.submitButtonName = data.submitButtonName;
      if(data.submitButtonColor){
        this.submitButtonColor = data.submitButtonColor;
      }
      this.title = data.dialogTitle;
      this.body = data.dialogBody;
      this.parentData = data.parentData;
    }

  ngAfterViewInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.body);
    this.componentRef = this.dialogBody.createComponent(componentFactory);
    this.componentRef.instance.parentData = this.parentData;
    this.disableButtonSubscriber = this.componentRef.instance.isSubmitButtonDisabled.subscribe((value: boolean) => {
      this.isSubmitButtonDisabled = value;
    });
    this.dialogLoadingSubscription = this.componentRef.instance.isDialogLoading.subscribe((value: boolean) => {
      this.isLoading = value;
    });
  /**
   * Closes the dialog box. When true is emitted closes after submit button else cancel action
   */
    this.closeDialogSubscription = this.componentRef.instance.closeDialog.subscribe((onSubmit: boolean = false) => {
        this.closeDialog(onSubmit)
    })

    /**
     * Closes the dialog with whatever data has been passed to the parent component
     */
    this.closeDialogSubscriptionWithInformation = this.componentRef.instance.closeDialogWithData?.subscribe((data: any) => {
      this.closeDialogWithData(data);
    })
    
    this.componentRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.disableButtonSubscriber?.unsubscribe();
    this.dialogLoadingSubscription?.unsubscribe();
    this.closeDialogSubscription?.unsubscribe();
    this.closeDialogSubscriptionWithInformation?.unsubscribe();
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  /**
   * Closes the dialog
   * @param onSubmit if true, after submit dialog closed else cancel action
   */
  closeDialog (onSubmit: boolean) {
    this.dialogRef.close(onSubmit);
  }

  closeDialogWithData (data: any) {
    this.dialogRef.close(data);
  }

  /**
   * Submits the form with action defined in submitDialog() in the component which is rendered inside the dialog
   */
  submitForm () {
    this.componentRef.instance.submitDialog();
  }
}
