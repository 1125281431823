<div class="d-flex align-items-center justify-content-between mb-4">
  <p mat-dialog-title>{{ title | i18next }}</p>
  <mat-icon (click)="closeDialog(false)" [hidden]="isLoading" class="close-icon">close</mat-icon>
</div>

<div mat-dialog-content cdkFocusInitial class="client-content">
  <ng-template #dialogBody></ng-template>
</div>

<div mat-dialog-actions align="end" *ngIf="showFooterButtons">
  <button color="accent" mat-stroked-button (click)="closeDialog(false)" [disabled]="isLoading" type="button">{{
    cancelButtonName | i18next }}
  </button>
  <button color={{submitButtonColor}} type="button" mat-flat-button (click)="submitForm()"
    [disabled]="isLoading || isSubmitButtonDisabled">
    {{ isLoading ? ('Loading..' | i18next) : (submitButtonName | i18next) }}
  </button>
</div>