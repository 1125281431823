import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  environment;
  constructor(
    @Inject('AppEnvironment') private environmentConfig
  ) {
    this.environment = environmentConfig;
  }

  get s3PublicUrl(): string {
    return this.environment.S3PublicUrl;
  }
}
