<div class="p-2">
  <div class="client-title d-flex align-items-center justify-content-between pb-4">
    <h4 class="heading">{{ data.title }}</h4>
    <mat-icon class="drawer-close-icon pointer" [hidden]="isLoading" (click)="closeDialog(false)">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <form #form="ngForm">
      <div class="row px-2 mt-2">
        <label class="asterix--after">Currency</label>
        <mat-form-field
          class="full-width"
          floatLabel="never"
          [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading, 'cursor-not-allowed': data.isEdit }"
        >
          <mat-select
            [(ngModel)]="invoiceForm.currency"
            name="currency"
            [disabled]="data.isEdit"
            #currency="ngModel"
            placeholder="Select Currency"
            [required]="true"
          >
            <mat-form-field class="full-width mb-1" floatLabel="never">
              <mat-icon matPrefix>search</mat-icon>
              <input
                [placeholder]="'Search currency'"
                matInput
                name="search"
                [(ngModel)]="currencySearchText"
                (keyup)="search(currencySearchText)"
                (keydown)="$event.stopPropagation()"
              />
              <mat-icon matSuffix class="pointer" (click)="clearSearch()" [hidden]="!currencySearchText?.length">close</mat-icon>
            </mat-form-field>
            <p class="no-found" *ngIf="dropDownItems?.length === 0">No Search Found!</p>
            <mat-option *ngFor="let currency of dropDownItems" [value]="currency._id">
              <div class="d-flex align-items-center">
                <div>
                  <h4 class="grey-darken-8 text-truncate">{{ currency.code + ' - ' + currency.name }}</h4>
                </div>
              </div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="currency.hasError('required')">{{ 'Currency is Required' }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row px-2">
        <label class="asterix--after" floatLabel="never">Amount</label>
        <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
          <input
            matInput
            type="number"
            name="amount"
            [pattern]="amountPattern"
            placeholder="Enter amount here"
            [(ngModel)]="invoiceForm.amount"
            #amountRef="ngModel"
            required
          />
          <mat-error *ngIf="amountRef.hasError('pattern')">{{ 'Invaild Amount' }}</mat-error>
          <mat-error *ngIf="amountRef.hasError('required')">{{ 'Amount is Required' }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row px-2">
        <label>Applicable Tax</label>
        <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
          <input
            matInput
            type="number"
            [(ngModel)]="invoiceForm.taxPerc"
            name="taxperc"
            min="0"
            max="100"
            placeholder="Tax %"
            #TaxRef="ngModel"
            [pattern]="taxPattern"
          />
          <mat-error *ngIf="TaxRef.hasError('pattern')">{{ 'Invaild Tax Percent' }}</mat-error>
          <mat-error *ngIf="TaxRef.hasError('min') || TaxRef.hasError('max')">{{ 'Please enter a value between 0 - 100' }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row px-2">
        <label>Memo</label>
        <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
          <textarea
            matInput
            placeholder="Enter Memo here"
            name="memo"
            #memoRef="ngModel"
            [(ngModel)]="invoiceForm.memo"
            (blur)="invoiceForm.memo = invoiceForm.memo?.trim()"
          >
          </textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="d-flex justify-content-end mt-3">
    <div class="text-left d-flex">
      <button class="mr-4" mat-stroked-button color="accent" (click)="closeDialog(false)" [disabled]="isLoading" type="button" back-button>Cancel</button>
      <button
        color="primary"
        type="button"
        mat-flat-button
        (click)="submitForm()"
        [disabled]="isLoading || form.invalid || isEqual(invoiceForm, initialInvoiceForm)"
      >
        {{ isLoading ? 'Loading..' : data.buttonName }}
      </button>
    </div>
  </div>
</div>
