import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@conpulse-web/core';
import { PermissionService } from 'libs/core/src/lib/services/permission.service';
import { compact } from 'lodash-es';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements OnInit {
  elseTemplateRef: TemplateRef<any>;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
    private userService: UserService
  ) {}

  @Input() set permissionElse(elseTemplate: TemplateRef<any>) {
    this.elseTemplateRef = elseTemplate;
  }
  @Input() permission: { modules: string[]; access: string[]; match?: '$or' | '$and'; userId?: string };

  ngOnInit(): void {
    if (this.permission.userId === this.userService.currentUserInformation._id) {
      let permissionAccess = [];
      this.permission.modules.forEach((module) => {
        permissionAccess.push(...this.permissionService.permissionDetails[module]);
      });
      if (this.permission.access.some((module) => permissionAccess.includes(module))) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      } else this.viewContainer.clear();
      return;
    } else {
      if (this.permission.userId) {
        this.permission.modules.forEach((module) => {
          const permission = this.permissionService.permissionDetails[module] || [];
          if (this.permission.access.some((data) => permission.includes(data))) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
          }
        });
        this.viewContainer.clear();
      }
    }
    if (!this.permission.userId) {
      if (this.permission.modules?.length === 1) {
        const permissionAccess = this.permissionService.permissionDetails[this.permission.modules[0]];
        this.viewContainer.clear();
        if (permissionAccess?.length && this.permission.access.some((access) => permissionAccess.includes(access))) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        const permissions = this.permission.modules.map((module) => this.permissionService.permissionDetails[module]);
        if (compact(permissions)?.length) {
          if (this.permission.match === '$or') {
            if (permissions.some((permission) => permission?.length && this.permission.access.some((access) => permission.includes(access)))) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
              this.viewContainer.clear();
              this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
          } else {
            if (permissions.every((permission) => permission?.length && this.permission.access.some((access) => permission.includes(access)))) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
              this.viewContainer.clear();
              this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
          }
        } else {
          this.viewContainer.clear();
        }
      }
    }
  }
}
