<div class="add-details">
  <form role="form" id="appearance-form" #form="ngForm">
    <mat-form-field class="full-width mb-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': intialLoader }">
      <input
        [(ngModel)]="searchText"
        name="searchText"
        matInput
        maxlength="60"
        [required]="isEmpty(selectedItems)"
        [placeholder]="matInputPlaceHolder"
        #customRegionText="ngModel"
        [readonly]="loading"
      />
      <mat-error *ngIf="customRegionText.hasError('required')">{{ requiredErrorMsg }}</mat-error>
      <span matSuffix class="add">
        <button
          *ngIf="!editMode; else updateButton"
          (click)="addRegion(form)"
          (keyup.enter)="addRegion(form)"
          mat-flat-button
          [disabled]="isEmpty(searchText) || loading"
          color="secondary"
          class="small"
        >
          {{ loading ? ('Adding' | i18next) : ('Add' | i18next) }}
        </button>
        <ng-template #updateButton>
          <button
            (click)="addRegion(form)"
            (keyup.enter)="addRegion(form)"
            mat-flat-button
            [disabled]="isEmpty(searchText) || isEqual(searchText, oldItemValue) || loading || isDisabled"
            color="secondary"
            class="small"
          >
            {{ loading ? 'Updating' : 'Update' }}
          </button>
          <button *ngIf="!loading" (click)="cancelEdit(form)" mat-stroked-button color="accent" class="small ml-2">Cancel</button>
        </ng-template></span
      >
    </mat-form-field>
  </form>
</div>
<div *ngIf="!showLess; else OrganizationalUnitChip">
  <div class="d-flex flex-wrap account-content" *ngIf="selectedItems?.length">
    <div class="tag-clouds" [class.border-line]="selectedItems?.length > 5">
      <mat-chip-list class="new-chip">
        <mat-chip *ngFor="let item of selectedItems; let i = index"
          >{{ item }}
          <ng-container *ngIf="editTiers; else onlyRemoveRef">
            <mat-icon class="mat-chip-remove" [matMenuTriggerFor]="matMenu">more_vert</mat-icon>
            <mat-menu class="p-1 chip-more-vert" xPosition="before" #matMenu="matMenu">
              <button mat-menu-item class="text-grey-primary-4" (click)="editTier(item)">
                <mat-icon class="material-icons-outlined f-20 mr-2">edit</mat-icon>
                <span class="f-14 f-w-500">{{ 'Edit' | i18next }}</span>
              </button>
              <button
                mat-menu-item
                class="menu-delete-icon-btn"
                [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
                (click)="removeCustomRegion(item)"
              >
                <mat-icon class="material-icons-outlined menu-delete-icon-btn f-20 mr-2">delete</mat-icon>
                <span class="f-14 f-w-500">{{ 'Delete' | i18next }}</span>
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #onlyRemoveRef>
            <mat-icon
              [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
              class="ml-0"
              matTooltip="Delete"
              matTooltipPosition="above"
              matChipRemove
              (click)="removeCustomRegion(item)"
              >close</mat-icon
            >
          </ng-template>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
<ng-template #OrganizationalUnitChip>
  <div class="select-filter-chip">
    <div *ngIf="!showMoreChips">
      <mat-chip-list class="new-chip">
      <ng-container *ngFor="let item of selectedItems; let i = index">
        <ng-container *ngIf="i < 3 || showMoreChips">
            <mat-chip
              >{{ item }}
              <ng-container *ngIf="editTiers; else onlyRemoveRef">
                <mat-icon class="mat-chip-remove" [matMenuTriggerFor]="matMenu">more_vert</mat-icon>
                <mat-menu class="p-1 chip-more-vert" xPosition="before" #matMenu="matMenu">
                  <button mat-menu-item class="text-grey-primary-4" (click)="editTier(item)">
                    <mat-icon class="material-icons-outlined f-20 mr-2">edit</mat-icon>
                    <span class="f-14 f-w-500">Edit</span>
                  </button>
                  <button
                    mat-menu-item
                    class="menu-delete-icon-btn"
                    [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
                    (click)="removeCustomRegion(item)"
                  >
                    <mat-icon class="material-icons-outlined menu-delete-icon-btn f-20 mr-2">delete</mat-icon>
                    <span class="f-14 f-w-500">Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
              <ng-template #onlyRemoveRef>
                <mat-icon
                  [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
                  class="ml-0"
                  matTooltip="Delete"
                  matTooltipPosition="above"
                  matChipRemove
                  (click)="removeCustomRegion(item)"
                  >close</mat-icon
                >
              </ng-template>
            </mat-chip>
          </ng-container>
      </ng-container>
      </mat-chip-list>
      <div>
        <p class="p-title txt-secondary pointer ml-2" *ngIf="selectedItems?.length > 3 && !showMoreChips" (click)="showMoreChips = true">
          + {{ selectedItems.length - 3 }} {{ 'more' | i18next }}
        </p>
      </div>
    </div>
    <div *ngIf="showMoreChips">
        <div class="select-filter-chip tag-clouds" [class.border-line]="selectedItems?.length > 5">
          <mat-chip-list class="new-chip">
            <mat-chip *ngFor="let item of selectedItems; let i = index"
              >{{ item }}
              <ng-container *ngIf="editTiers; else onlyRemoveRef">
                <mat-icon class="mat-chip-remove" [matMenuTriggerFor]="matMenu">more_vert</mat-icon>
                <mat-menu class="p-1 chip-more-vert" xPosition="before" #matMenu="matMenu">
                  <button mat-menu-item class="text-grey-primary-4" (click)="editTier(item)">
                    <mat-icon class="material-icons-outlined f-20 mr-2">edit</mat-icon>
                    <span class="f-14 f-w-500">Edit</span>
                  </button>
                  <button
                    mat-menu-item
                    class="menu-delete-icon-btn"
                    [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
                    (click)="removeCustomRegion(item)"
                  >
                    <mat-icon class="material-icons-outlined menu-delete-icon-btn f-20 mr-2">delete</mat-icon>
                    <span class="f-14 f-w-500">Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
              <ng-template #onlyRemoveRef>
                <mat-icon
                  [hidden]="(isBrand && i === 0) || (editTiers && selectedItems.length === 1) || deleteDisabledItems[i] || false"
                  class="ml-0"
                  matTooltip="Delete"
                  matTooltipPosition="above"
                  matChipRemove
                  (click)="removeCustomRegion(item)"
                  >close</mat-icon
                >
              </ng-template>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div>
          <p class="p-title txt-secondary pointer mb-2 mt-2" (click)="showMoreChips = false">
            {{ 'Show less' | i18next }}
          </p>
        </div>
    </div>
    </div>
</ng-template>
