<div
  class="validation-chip d-flex align-items-center justify-content-center text-nowrap"
  [class.done]="currentStatus === SoWFieldValidationStatus.VALIDATED"
  [class.pending]="currentStatus === SoWFieldValidationStatus.PENDING"
  *ngIf="this.SoWFieldValidationShowStatus.includes(currentStatus)"
>
  <ng-container *ngIf="currentStatus === SoWFieldValidationStatus.VALIDATED">
    <i class="material-icons mr-1 f-14">check_circle</i>
    <span class="mr-1">
      {{ 'Validated' | i18next }}
    </span>
  </ng-container>
  <ng-container *ngIf="currentStatus === SoWFieldValidationStatus.PENDING">
    <i class="material-icons mr-1 f-14">hourglass_bottom</i>
    <span class="mr-1">
      {{ 'Validation Pending' | i18next }}
    </span>
  </ng-container>
</div>
