import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { InputChipInstantSaveComponent } from './input-chip-instant-save.component';
import { ConpulseMaterialModule } from '../../material-module/index';

@NgModule({
  declarations: [InputChipInstantSaveComponent],
  imports: [CommonModule, ConpulseMaterialModule, I18NextModule],
  exports: [InputChipInstantSaveComponent],
})
export class InputChipInstantSaveModule {}
