<div class="layout-container">
  <div class="d-flex justify-content-between align-items-center p-20">
    <div class="d-flex align-items-center">
      <button mat-icon-button (click)="exitEmitter.emit(false)"><mat-icon>arrow_back</mat-icon></button>
      <h4 class="heading ml-2">{{ parentData?.layoutName }}</h4>
    </div>
    <button *ngIf="isEditAccess && !isFromChanges" mat-stroked-button color="secondary" (click)="changelayout.emit()">{{ 'Change Layout' | i18next }}</button>
  </div>
  <div class="section-fields p-x-20 col-12">
    <div class="style-section">
      <p class="font-Jakarta tab-inside-title mb-20">{{ 'Page Style' | i18next }}</p>
      <div
        class="template-editor-field quill-bordered m-b-24 d-flex"
        *ngFor="let field of section[0].fields; let fieldIndex = index"
        [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }"
      >
        <div>
          <div
            class="poniter"
            matTooltipPosition="above"
            [matTooltip]="'Cover Page Content, gives highlight about of the contract and it is place as first page of the document' | i18next"
            *ngIf="field.fieldKey === 'coverPageContent'"
          >
            <img src="assets/images/cover-hint.png" alt="asc" />
          </div>
          <div
            class="poniter"
            matTooltipPosition="above"
            [matTooltip]="'Header Content, is placed on top of the main content on each page of the document' | i18next"
            *ngIf="field.fieldKey === 'headerContent'"
          >
            <img src="assets/images/header-hint.png" alt="asc" />
          </div>
          <div
            class="poniter"
            matTooltipPosition="above"
            [matTooltip]="'Footer Content, is placed on the bottom of the main content on each page of the document' | i18next"
            *ngIf="field.fieldKey === 'footerContent'"
          >
            <img src="assets/images/footer-hint.png" alt="asc" />
          </div>
        </div>
        <div class="quill-editor-container ml-20 w-100">
          <div>
            <p class="f-13 f-w-700 m-b-10" [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
              {{ field.fieldName | i18next }}
            </p>
          </div>
          <div [ngClass]="{ 'pointer-none': !isEditAccess }" [isTextDisabled]="!isEditAccess" quillToggleShow>
            <div class="quill-text text pl-2">
              <div
                class="template-editor-heading f-15 f-w-400 txt-aluminium-grey"
                [innerHTML]="
                  (parentData[section[0].sectionKey][field.fieldKey]
                    ? parentData[section[0].sectionKey][field.fieldKey]
                    : (('Enter' | i18next) + ' ' + field.fieldName | i18next)
                  ) | safeHtml
                "
              ></div>
            </div>
            <div class="quill-editor-display-status">
              <div class="quill-height-200">
                <!-- <quill-editor
                  *ngIf="!isLoading"
                  [(ngModel)]="parentData[section[0].sectionKey][field.fieldKey]"
                  name="template-{{ field.fieldKey }}"
                  placeholder="{{ 'Enter' | i18next }} {{ field.fieldName }}"
                  [modules]="RfpSettingsQuillConfig"
                  (onEditorCreated)="onEditorCreated($event)"
                ></quill-editor> -->
                <editor [init]="initializeEditor('Enter '+ field.fieldName)"  [required]="true" [(ngModel)]="parentData[section[0].sectionKey][field.fieldKey]" name="template-{{ field.fieldKey }}"></editor>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isFromChanges">
        <div class="border-top m-b-24"></div>

        <ng-container *ngFor="let section of styleSection; let isLast = last">
          <div class="m-b-24">
            <p class="font-Jakarta tab-inside-title mb-20">{{ section.sectionName | i18next }}</p>
            <div
              class="template-editor-field mb-4 quill-bordered"
              *ngFor="let field of section.fields; let styleIndex = index"
              [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex align-items-center mb-18">
                  <span class="alphabet mr-2 f-w-600 f-13 d-flex align-items-center justify-content-center border-radius-full">
                    {{ field.icon }}
                  </span>
                  <p class="f-w-600 f-13 txt-primary-01" [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                    {{ field.fieldName | i18next }}
                  </p>
                </div>
                <div class="d-flex">
                  <ng-container *ngIf="section.sectionKey === 'textStyle'">
                    <div class="template-font-family w-150px mr-12">
                      <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                        <mat-select
                          required
                          [(ngModel)]="parentData[field.fieldKey]['fontFamily']"
                          name="fontFamily-{{ field.fieldKey }}"
                          placeholder="{{ 'Font' | i18next }}"
                          (selectionChange)="constructStyle(field.fieldKey)"
                          #FontFace="ngModel"
                        >
                          <mat-option *ngFor="let font of fontFamily" [value]="font.value"> {{ font.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="FontFace.hasError('required')">{{ 'Font is required' | i18next }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="template-font-size w-150px mr-12">
                      <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                        <mat-select
                          required
                          [(ngModel)]="parentData[field.fieldKey]['fontSize']"
                          name="fontSize-{{ field.fieldKey }}"
                          placeholder="{{ 'Font Size' | i18next }}"
                          (selectionChange)="constructStyle(field.fieldKey)"
                          #FontSize="ngModel"
                        >
                          <mat-option *ngFor="let size of fontSize" [value]="size.value"> {{ size.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="FontSize.hasError('required')">{{ 'Font size is required' | i18next }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="template-font-style w-150px mr-12">
                      <mat-form-field class="full-width" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                        <mat-select
                          [(ngModel)]="parentData[field.fieldKey]['fontStyle']"
                          name="fontStyle-{{ field.fieldKey }}"
                          placeholder="{{ 'Font Style' | i18next }}"
                          (selectionChange)="constructStyle(field.fieldKey)"
                          #FontStyle="ngModel"
                          multiple
                        >
                          <mat-option *ngFor="let style of fontStyle" [value]="style.value"> {{ style.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="FontStyle.hasError('required')">{{ 'Style is required' | i18next }}</mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <div>
                    <div
                      class="pointer d-flex align-items-center color-button w-fit border-radius-6"
                      [matMenuTriggerFor]="menu"
                      (click)="updateMenuToggle(field.fieldKey)"
                    >
                      <p class="">{{ parentData[field.fieldKey]['color'] ? parentData[field.fieldKey]['color'] : '#00000' }}</p>
                      <div class="color-box ml-8" [style.background-color]="parentData[field.fieldKey]['color']"></div>
                    </div>
                    <mat-menu #menu="matMenu">
                      <div class="color-menu p-2 d-flex flex-wrap justify-content-between border-radius-6" [hidden]="colorMenuToggle[field.fieldKey]">
                        <div *ngFor="let color of colorCodes; let colorIndex = index">
                          <div
                            class="color-box mb-2 poniter"
                            [ngClass]="(colorIndex + 1) % 7 === 0 ? 'mr-0' : 'mr-2'"
                            (click)="selectColor(field.fieldKey, color)"
                            [style.background-color]="color"
                          ></div>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </div>
              </div>
              <div class="layout-item">
                <div class="layout-text align-items-center d-flex h-100px">
                  <p [style]="preview[field.fieldKey]" *ngIf="section.sectionKey === 'textStyle'; else marginRef">
                    {{ defaultStyleText[field.fieldKey] | i18next }}
                  </p>
                  <ng-template #marginRef>
                    <div class="margin-layout" [style.background-color]="parentData[field.fieldKey]['color']"></div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="border-top m-b-24" *ngIf="!isLast"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="d-flex justify-content-end border-top align-items-center pl-3 p-r-72 py-3 bottom-stick" *ngIf="isEditAccess">
    <button mat-stroked-button color="accent" class="mr-2" (click)="exitEmitter.emit(false)">Cancel</button>
    <button mat-flat-button color="primary" (click)="updateLayout.emit(parentData)" [disabled]="checkValue()">Save</button>
  </div>
</div>
