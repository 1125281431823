import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDialogComponent } from './common-dialog.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { FilterSelectModule } from '../filter-select/filter-select.module';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [CommonDialogComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    FilterSelectModule,
    I18NextModule
  ]
})
export class CommonDialogModule { }
