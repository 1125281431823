import { Component, Injectable } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
    constructor(private readonly i18next: I18NextPipe) {

    }
    changes = new Subject<void>();

    // For internationalization, the `i18next.transform(` function from
    // the `@angular/localize` package can be used.
    firstPageLabel = this.i18next.transform(`First page`);
    itemsPerPageLabel = this.i18next.transform(`Records per page`);
    lastPageLabel = this.i18next.transform(`Last page`);

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
          return this.i18next.transform(`Page 1 of 1`);
        }
        const amountPages = Math.ceil(length / pageSize);
        return this.i18next.transform('Page') + ` ${page + 1} ` + this.i18next.transform('of') + ` ${amountPages}`;
    }
}
