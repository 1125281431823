<div class="d-flex align-items-center justify-content-between mb-4">
  <p mat-dialog-title>{{ title | i18next }}</p>
  <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
</div>

<div mat-dialog-content cdkFocusInitial>
  <div *ngIf="!checkConflict">
    <p class="dialog-info" *ngIf="type === 'warning'; else list" [innerHTML]="message | i18next"></p>
  </div>
  <div class="mt-2 d-flex" *ngIf="checkConflict">
    <mat-checkbox class="mr-2"  color="secondary" [checked]="isConflict"
      (change)="isConflict = $event.checked">
    </mat-checkbox>
    <p class="dialog-info">{{ 'We confirm that' | i18next }} {{ firmName }} {{ 'has no conflict of interest for this
      project' | i18next }}</p>
  </div>
  <ng-template #list>
    <p class="dialog-info mb-1">{{ message | i18next }}</p>
    <ul>
      <li class="ml-2" *ngFor="let name of names">
        <b>{{ name | i18next }}</b>
      </li>
    </ul>
  </ng-template>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close color="accent" (click)="handleClick(false)" *ngIf="cancelText?.length">{{
    cancelText | i18next }}</button>
  <button mat-flat-button mat-dialog-close color="primary" (click)="handleClick(true)">{{ acceptanceText | i18next
    }}</button>
</div>
