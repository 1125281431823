import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountAndBillingComponent } from './account-and-billing.component';


const routes: Routes = [{
  path: '',
  component: AccountAndBillingComponent,
  children: [
    {
      path: '',
      redirectTo: 'subscription',
      pathMatch: 'full',
    },
    {
      path: 'subscription',
      loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.ClientAccountModule),
    },
    {
      path: 'contracts',
      loadChildren: () => import('../client-contracts/client-contracts.module').then((m) => m.ClientContractsModule),
    },
    {
      path: 'options',
      loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.ClientModuleManagementModule),
    },
    {
      path: 'billing-info',
      loadChildren: () => import('../billing-address/billing-address.module').then((m) => m.BillingAddressModule),
    },
    {
      path: 'invoice-billing',
      loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.ClientBillingModule),
    },
    {
      path: 'off-boarding',
      loadChildren: () => import('../client-off-boarding/client-off-boarding.module').then((m) => m.ClientOffBoardingModule),
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountAndBillingRoutingModule { }
