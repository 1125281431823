import { Inject, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { get, remove } from 'lodash-es';
import { NotificationService, SowDynamicFormsValue, UploaderService, UploadFileDetails, UploadType, UserService } from '@conpulse-web/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilityMethodsService } from './utility-methods.service';

@Injectable({
  providedIn: 'root',
})
export class TinyMceService {
  environment;
  imageUrlsUploadedOnlyInS3 = [];
  fileList = [];
  formatList = [];
  allowedFormats = 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,svg'
  baseConfigs = {
    base_url: '/client/tinymce',
    indent: false,
    toolbar_location: "bottom",
    toolbar_mode: 'wrap',
    images_reuse_filename: false,
    block_formats: 'Heading 1=h1; Heading 2=h2; Heading 3=h3; Normal=p;',
    images_file_types: this.allowedFormats,
    suffix: '.min',
    menubar: false,
    min_height: 300,
    paste_as_text: false, // This ensures that styles are not stripped
    paste_webkit_styles: 'all', // Retain styles from webkit browsers
    paste_retain_style_properties: 'all', // Retain all style properties
    paste_data_images: false, // Allows pasting images as base64 encoded data
    paste_merge_formats: true, // Merge formatting of pasted text
    statusbar: false, // This will hide the status bar
    image_dimensions: false,
    content_style:
      `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    font-style: italic;
    }
    body {
     font-family: Open Sans,sans-serif; 
    }
    img {
     max-width: 500px;
     object-fit: contain;
    }
    p {margin: 0};
    `
  };
  imageUrlsToBeDeletedFromS3: { Key: string }[] = [];
  deleteUploadedFile$: Subject<UploadFileDetails> = new Subject<UploadFileDetails>();
  reset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bulkDeleteUploadedFile$: Subject<{ Key: string }[]> = new Subject<{ Key: string }[]>();
  saveButtonTrigger$: BehaviorSubject<{ isClicked: Boolean; diffArray: string[] }> = new BehaviorSubject<{ isClicked: Boolean; diffArray: string[] }>({
    isClicked: true,
    diffArray: [],
  });

  constructor(private userService: UserService,
    private uploaderService: UploaderService,
    private utilityService: UtilityMethodsService,
    private notificationService: NotificationService,
    @Inject('AppEnvironment') private environmentConfig
  ) {
    this.environment = environmentConfig;

    this.baseConfigs.base_url = this.environment.tinyMceUrl

    this.deleteUploadedFile$.subscribe(async (fileDetails) => {
      await this.uploaderService.deleteFile(fileDetails).toPromise();
    });

    this.bulkDeleteUploadedFile$.subscribe((files) => {
      if (files?.length)
        this.uploaderService.bulkDeleteFile(files).subscribe((data) => {
          if (data.response === 'success') {
            this.imageUrlsToBeDeletedFromS3 = [];
          }
        });
    });

    this.reset$.subscribe((data) => {
      if (data) {
        if (this.imageUrlsUploadedOnlyInS3?.length) {
          const formattedArray = this.imageUrlsUploadedOnlyInS3.map((url) => ({ Key: url }));
          this.bulkDeleteUploadedFile$.next(formattedArray);
        }
        this.imageUrlsToBeDeletedFromS3 = [];
        this.imageUrlsUploadedOnlyInS3 = [];
      }
    });

    /**Deletes saved images from s3 on save button click*/
    this.saveButtonTrigger$.subscribe((data) => {
      if (data.isClicked) {
        this.imageUrlsUploadedOnlyInS3 = [];
        /**diffArray => to check whether the removed images has been used in the diff */
        if (data.diffArray?.length === 0) {
          this.bulkDeleteUploadedFile$.next(this.imageUrlsToBeDeletedFromS3);
        } else {
          const filesToBeRemoved = this.imageUrlsToBeDeletedFromS3.filter((file) => !data.diffArray.includes(file.Key));
          this.bulkDeleteUploadedFile$.next(filesToBeRemoved);
        }
      }
    });

    this.loadFileFormats()
  }

  loadFileFormats = async () => {
    const data = await this.utilityService.loadAllowedFileFormats();
    this.fileList = data.fileList;
    this.formatList = data.formatList;
    const formats = data.fileList.map((data) => data.value.fileFormat)
  }

  getTextAreaConfigs(placeholder = '') {
    const editorConfigs = {
      ...this.baseConfigs,
      placeholder,
      toolbar: 'bold italic underline forecolor alignleft aligncenter alignright alignjustify bullist numlist link image ',
      plugins: 'lists image table link',
      setup: (editor) => {
        let previousContent; // Store initial content to get deleted image url
        editor.on('init', () => {
          previousContent = editor.getContent();
        });

        editor.on('OpenWindow', () => {
          this.isImageUploading$.next(true)
        })

        editor.on('CloseWindow', () => {
          this.isImageUploading$.next(false)
        })

        editor.on('Change', () => {
          const currentContent = editor.getContent();
          this.checkForImageDeletions(previousContent, currentContent);
          previousContent = currentContent; // Update previous content
        });
      },
      images_upload_handler: (blobInfo) => this.imageHandler(blobInfo),
    };
    return editorConfigs;
  }

  getTextAreaConfigsWithoutToolBar(placeholder = '') {
    const editorConfigs = {
      ...this.baseConfigs,
      placeholder,
      toolbar: false,
      setup: (editor) => {
        let previousContent; // Store initial content to get deleted image url
        editor.on('init', () => {
          previousContent = editor.getContent();
        });

        editor.on('Change', () => {
          const currentContent = editor.getContent();
          this.checkForImageDeletions(previousContent, currentContent);
          previousContent = currentContent; // Update previous content
        });
      },
      images_upload_handler: (blobInfo) => this.imageHandler(blobInfo),
    };
    return editorConfigs;
  }

  getInitialConfigs(contentStyle?, placeholder = '') {
    const editorConfigs = {
      ...this.baseConfigs,
      placeholder,
      toolbar: 'blocks table fontfamily fontsize bold italic underline forecolor alignleft aligncenter alignright alignjustify bullist numlist image LayoutButton',
      plugins: 'lists image table autoresize',
      font_family_formats:
        'Inter=Inter, sans-serif; ' +
        'Lato=Lato, sans-serif; ' +
        'Merriweather=Merriweather, serif; ' +
        'Montserrat=Montserrat, sans-serif; ' +
        'Noto Sans=Noto Sans, sans-serif; ' +
        'Poppins=Poppins, sans-serif; ' +
        'PT Sans=PT Sans, sans-serif; ' +
        'Raleway=Raleway, sans-serif; ' +
        'Roboto=Roboto, sans-serif; ' +
        'Roboto Slab=Roboto Slab, serif; ' +
        'Source Sans Pro=Source Sans Pro;',
      font_size_formats: '10px 12px 14px 16px 18px 20px 22px 24px',
      font_size_input_default_unit: 'px',
      setup: (editor) => {
        let previousContent; // Store initial content to get deleted image 

        editor.on('init', () => {
          previousContent = editor.getContent();

          if (contentStyle) {
            // Register a new button in the tool bar section
            editor.ui.registry.addButton('LayoutButton', {
              text: 'Apply Layout Styles',
              onAction: (_) => editor.formatter.apply('mycustomformat'),
            });

            // Registers and sets a editor's default format with the selected layout styles
            editor.formatter.register('mycustomformat', {
              selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
              styles: {
                ...contentStyle,
                fontWeight: contentStyle?.fontStyle?.includes('bold') ? 'bold' : null,
                fontStyle: contentStyle?.fontStyle?.includes('italic') ? 'italic' : null,
                textDecoration: contentStyle?.fontStyle?.includes('underline') ? 'underline' : null,
              },
            });
            if (!previousContent)
              editor.formatter.apply('mycustomformat');
          }
        });

        editor.on('OpenWindow', () => {
          this.isImageUploading$.next(true)
        })

        editor.on('CloseWindow', () => {
          this.isImageUploading$.next(false)
        })

        editor.on('Change', () => {
          const currentContent = editor.getContent();
          this.checkForImageDeletions(previousContent, currentContent);
          previousContent = currentContent; // Update previous content
        });
      },
      images_upload_handler: (blobInfo) => this.imageHandler(blobInfo),
    };
    return editorConfigs;
  }

  imageHandler = (blobInfo) => {
    this.isImageUploading$.next(true)
    return new Promise(async (resolve, reject) => {
      const imageFileName = `image-${uuidv4()}`;
      const file = blobInfo.blob()
      const imageMimeType = blobInfo.blob()?.type;
      const fileName = blobInfo.blob().name;
      const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
      const fileSupported = this.formatList.includes(`.${fileType}`);
      const maxFileSize = this.utilityService.findMaxFileSize(this.fileList, `.${fileType}`);
      if (!fileSupported) {
        this.notificationService.openErrorSnackBar(`Unable to upload ${fileName} since it is not of allowed file formats`);
        reject({ message: 'File format not allowed!', remove: true });
        this.isImageUploading$.next(false)
        return;
      } else if (blobInfo.blob().size > maxFileSize * Math.pow(10, 6)) {
        this.notificationService.openErrorSnackBar(`Total size of the file ${fileName} should be less than ${maxFileSize} MB`);
        reject({ message: `File Size should be less than ${maxFileSize} MB!`, remove: true });
        this.isImageUploading$.next(false)
        return
      }
      let refIds = {};
      let uploadType = UploadType.SA_QUILL;
      if (this.userService.currentUserInformation?.companyId) {
        uploadType = UploadType.CLIENT_QUILL;
        refIds = { clientId: this.userService.currentUserInformation?.companyId._id };
      } else if (this.userService.currentUserInformation?.firmId) {
        uploadType = UploadType.PROVIDER_QUILL;
        refIds = { providerId: this.userService.currentUserInformation?.firmId._id };
      }
      const fileDetails: UploadFileDetails = {
        uploadType,
        fileKey: `${imageFileName}.${fileType}`,
        mimeType: imageMimeType,
      };
      const imageUrls = get(await this.uploaderService.getUploadUrl(fileDetails, refIds).toPromise(), 'data', null);
      const imageUploadUrl: string = imageUrls?.uploadUrl;
      const imagePublicUrl: string = imageUrls?.publicUrl;
      if (imageUploadUrl) {
        try {
          await this.uploaderService.uploadToPresignedUrl(imageUploadUrl, file, imageMimeType).toPromise();
          this.imageUrlsUploadedOnlyInS3.push(imagePublicUrl);
          resolve(imagePublicUrl);
          this.isImageUploading$.next(false)
        } catch (err) {
          this.isImageUploading$.next(false)
          reject('Could not upload image!');
        }
      } else {
        this.isImageUploading$.next(false)
        reject('Could not upload image!');
      }
    });
  };

  extractImageUrls(content: string): string[] {
    // Use a regex or DOM parser to extract image URLs from content
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    return Array.from(doc.querySelectorAll('img')).map((img) => img.src);
  }

  checkForImageDeletions(previousContent: string, currentContent: string) {
    // Extract image URLs from previous and current content
    const previousImages = this.extractImageUrls(previousContent);
    const currentImages = this.extractImageUrls(currentContent);

    previousImages.forEach((url) => {
      /**Verifies whether the image has been removed in the current change
       * CASE 1 => removed image was uploaded in s3 but not saved in DB then we can
       * delete the image from s3 in the on change event itself.
       * CASE 2 => removed image was saved in DB means push it into the seperate array
       **/
      if (!currentImages.includes(url)) {
        if (this.imageUrlsUploadedOnlyInS3.includes(url)) {
          this.deleteUploadedFile$.next({ fileKey: url });
        } else {
          this.imageUrlsToBeDeletedFromS3.push({ Key: url });
        }
      }
    });
  }

  getRetainedUrls(previous: string, current: string): string[] {
    const previousUrls = this.extractImageUrls(previous);
    const currentUrls = new Set(this.extractImageUrls(current));
    return previousUrls?.filter((url) => !currentUrls.has(url)) ?? [];
  }

  compareContentsToRetainUrls(content: object, fields: string[], userDataType: string): string[] {
    return fields.reduce((acc, field) => {
      const currentText = content[field]?.commonData?.text || '';
      const previousText = content[field]?.[userDataType]?.text || '';
      return acc.concat(this.getRetainedUrls(previousText, currentText));
    }, []);
  }

  getsUrlsToRetainForDynamicForms(content: SowDynamicFormsValue[], userDataType: string): string[] {
    return content.reduce((acc, field) => {
      const currentText = field?.commonData?.text || '';
      const previousText = field?.[userDataType]?.text || '';
      return acc.concat(this.getRetainedUrls(previousText, currentText));
    }, []);
  }
}
