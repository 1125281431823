import { Pipe, PipeTransform } from '@angular/core';
import { OptionsLists } from '@conpulse-web/core';

/**
 * object Key Pipe
 * *************
 * Used to return object key values when value is passed
 * Example: {{ numberValue | objectKey: Object }} object with key as string and value as number.
 */
@Pipe({
	name: 'objectKey'
})
export class ObjectKeyPipe implements PipeTransform {
	transform(value: number | string, object: OptionsLists) {
		return value ? Object.keys(object).find(key => object[key] === value) : '-'
	}
}
