import { Component, OnInit } from '@angular/core';
import { ChatService } from '@conpulse-web/core';

@Component({
  selector: 'conpulse-web-view-chat',
  templateUrl: './view-chat.component.html',
  styleUrls: ['./view-chat.component.scss']
})
export class ViewChatComponent implements OnInit {
  isChatListLoading = false;
  conversation
  chatRoomId: string;
  constructor(private readonly chatService: ChatService) { }

  ngOnInit(): void {
    this.chatService.refreshUnreadCount()
  }

  updateconversation(event) {
    this.conversation = event
  }

  updateconversationList(event){
    this.chatRoomId = event
  }
}
