import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BlogEditorMode, ChatRoom, ChatService, NotificationService } from '@conpulse-web/core';
import { isEmpty, trim } from 'lodash-es';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-add-thread-dialog',
  templateUrl: './add-thread-dialog.component.html',
  styleUrls: ['./add-thread-dialog.component.scss'],
})
export class AddThreadDialogComponent implements OnInit {
  trim = trim;
  threadName = '';
  @ViewChild('form', { static: true }) form: NgForm;
  @Output() isSubmitButtonDisabled = new EventEmitter<boolean>();
  @Output() isDialogLoading = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();
  @Input() parentData: {
    projectId: string;
    conversation: ChatRoom;
    type: BlogEditorMode;
  };
  isLoading: boolean = false;
  initialName: string;
  constructor(
    private readonly chatService: ChatService,
    private readonly i18nextPipe: I18NextPipe,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.parentData.conversation && this.parentData.type === BlogEditorMode.EDIT) {
      this.threadName = this.parentData.conversation.chatProfile.name;
      this.initialName = this.threadName;
    }
  }

  checkValue() {
    this.isSubmitButtonDisabled.emit(!this.threadName?.trim()?.length || this.threadName.trim() === this.initialName);
  }

  createConversation = () => {
    this.isDialogLoading.emit(true);
    if (this.parentData.type === BlogEditorMode.ADD) {
      this.chatService.createWorspaceChatRoom(this.parentData.projectId, this.threadName).subscribe(
        (data) => {
          this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('Conversation Room created successfully'));
          this.isDialogLoading.emit(false);
          this.closeDialog.emit(true);
        },
        (error) => {
          this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(error.message));
          this.isDialogLoading.emit(false);
        }
      );
    } else {
      this.chatService.updateWorspaceChatRoom(this.parentData.conversation._id, this.threadName, true).subscribe(
        (data) => {
          this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('Conversation Room updated successfully'));
          this.isDialogLoading.emit(false);
          this.closeDialog.emit(true);
        },
        (error) => {
          this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(error.message));
          this.isDialogLoading.emit(false);
        }
      );
    }
  };

  submitDialog() {
    this.createConversation();
  }
}
