import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[restrictSpecialChar]'
})
export class RestrictSpecialCharDirective {

  @Output() valueChange = new EventEmitter()

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event) {
      const initalValue = this.elementRef.nativeElement.value;
      const newValue = initalValue.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      this.elementRef.nativeElement.value = newValue;
      this.valueChange.emit(newValue);
      if (initalValue !== this.elementRef.nativeElement.value) {
          event.stopPropagation();
      }
  }

}
