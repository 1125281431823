import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { ASSETS, CLIENT_MODULES, CONSTANTS, CommonTableData, SpecialDiffType, UpdateStatus, UserService, user } from '@conpulse-web/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MyCustomPaginatorIntl } from '../../services';
import { MessagePopupComponent } from '../message-popup/message-popup.component';

@Component({
  selector: 'conpulse-web-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
})
export class CommonTableComponent implements OnInit {
  @Input() tableSelector: string;
  @Input() isPagination = true;
  tableData: CommonTableData;
  SpecialDiffType = SpecialDiffType;
  @Input() set tableValues(values: CommonTableData) {
    this.tableData = values;
    this.isCurrencyUpdating = false;
  }
  @Input() isClient: boolean = false;
  @Input() isActiveFirm = false;
  @Input() errorMessage: string = undefined;
  @Output() view: EventEmitter<string | Object> = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() download: EventEmitter<{ documentName: string; documentKey: string; documentType: number }> = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() archive = new EventEmitter();

  /* Start - Project -> Project Management -> Invoicing & Payment Table  */
  @Input() isProjectManagementFirmPartner = false;
  @Input() isProjectManagementClientProjectLeader = false;
  @Input() isProjectProcurementLeader = false;
  @Output() uploadInvoicing = new EventEmitter();
  @Output() approveInvoice = new EventEmitter();
  @Output() requestToApproveInvoice = new EventEmitter();
  @Output() updatePaymentStatus = new EventEmitter();
  /* End */
  
  @ViewChild(MessagePopupComponent) messagePopupComponentMenu: MessagePopupComponent;
  currencyEditValue: string = null;
  currencyEditId: string = null;
  @Output() updateCurrency: EventEmitter<{ id: string; rate: string }> = new EventEmitter();
  @Input() set currencyUpdated(isUpdated: UpdateStatus) {
    this.isCurrencyUpdating = false;
    if (this.currencyEditId !== null && this.currencyEditValue !== null && isUpdated === UpdateStatus.Success) {
      this.currencyEditValue = null;
      this.currencyEditId = null;
    }
  }
  isCurrencyUpdating: boolean = false;
  currentUserInfo: user;

  isEmpty = isEmpty;
  notFoundImage: string;

  notDataFoundImage: string;
  clientModules = CLIENT_MODULES;
  access = CONSTANTS.accessValue;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.notDataFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
    this.currentUserInfo = this.userService.currentUserInformation;
  }

  passReceiverIdToMessagePopup(id) {
    this.messagePopupComponentMenu.receiverId.next(id);
  }

  /**
   * Sort table values
   * @param field field to sort
   */
  sort(field: string) {
    this.tableData.sortBy = field;
    this.tableData.sortOrder = this.tableData.sortOrder === 1 ? -1 : 1;
    this.tableData.page = 0;
    this.changed.emit();
  }

  /**
   * Switch pages
   */
  changePage(value) {
    this.tableData.selectedData = [];
    switch (value) {
      case '-':
        if (this.tableData.page - 1 >= 0) {
          this.tableData.page = this.tableData.page - 1;
          this.changed.emit();
        }
        break;
      case '+':
        if (this.tableData.page + 1 < this.tableData.endPage) {
          this.tableData.page = this.tableData.page + 1;
          this.changed.emit();
        }
        break;
      default:
        if (parseInt(value, 10) - 1 !== this.tableData.page) {
          this.tableData.page = parseInt(value, 10) - 1;
          this.changed.emit();
        }
        break;
    }
  }

  /**
   * View action
   * @param data required field from table to view
   */
  viewAction(data: string) {
    this.view.emit(data);
  }

  /**
   * view onboarding data
   * @param clientId linked client's unique Id
   * @param entityId Id of the respective legal entity
   */
  viewOnboardingData(clientId: string, entityId: string) {
    this.view.emit({ clientId, entityId });
  }

  /**
   * Edit action
   * @param data required field from table to view
   */
  editAction(data) {
    this.edit.emit(data);
  }

  /**
   * Delete action
   * @param data required field from table to view
   */
  deleteRow(data: string) {
    this.delete.emit(data);
  }

  /**
   * To archive the risk data
   * @param data: entire risk data
   */
  archiveRow(data, isArchived: boolean) {
    this.archive.emit({ riskData: data, isArchived: isArchived });
  }

  /**
   * Get class sets class for name
   */
  getClass(i: number) {
    const modvalue = i % 4;
    switch (modvalue) {
      case 0:
      default:
        return 'orange';
      case 1:
        return 'blue';
      case 2:
        return 'dark-green';
      case 3:
        return 'red';
    }
  }

  downloadDocument(data: { documentName: string; documentKey: string; documentType: number }) {
    this.download.emit(data);
  }

  /**
   * Edits currency data
   */
  editCurrency(data: string, conversionValue: string) {
    this.currencyEditId = data;
    this.currencyEditValue = conversionValue;
  }

  /**
   * Update currency details in DB
   */
  updateCurrencyDetails(id: string, rate: string) {
    this.isCurrencyUpdating = true;
    this.updateCurrency.emit({ id, rate });
  }

  /**
   * Handles next page click
   * @param event page click event consits pageIndex, previousPageIndex,pageSize,length
   */
  handlePageEvent(event: PageEvent) {
    this.tableData.page = event.pageIndex;
    this.tableData.limit = event.pageSize;
    this.changed.emit();
  }

  approveOrRejectRequest(request: object, isApproved: boolean = false) {
    this.edit.emit({ request, isApproved });
  }

  openUrl(url: string) {
    window.open(url, '_blank')
  }
}
