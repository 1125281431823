import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { DemographicsFilterComponent } from './demographics-filter.component';
import { ConpulseMaterialModule } from '../../material-module/conpulse-material.module';
import { MatAssetsModule } from '../mat-assets/mat-assets.module';
import { FilterSelectModule } from '../filter-select/filter-select.module';
import { ConpulseModule } from '../../conpulse.module';

@NgModule({
  declarations: [DemographicsFilterComponent],
  imports: [CommonModule, ConpulseMaterialModule, FilterSelectModule, MatAssetsModule, I18NextModule, ConpulseModule],
  exports: [DemographicsFilterComponent],
})
export class DemographicsFilterModule {}
