import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicInputComponent } from './dynamic-input.component';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';



@NgModule({
  declarations: [DynamicInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ConpulseMaterialModule,
    ConpulseModule
  ], 
  exports: [
    DynamicInputComponent
  ]
})
export class DynamicInputModule { }
