import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[fileDrop]'
})
export class FileDropDirective {
  @HostBinding('class.filehover') fileHover: boolean;
  @Input() pauseDrop: boolean = false;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.dataTransfer.dropEffect = 'copy';
    this.fileHover = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileHover = false;
    let fileList = evt.dataTransfer.files;
    if(fileList?.length && !this.pauseDrop){
      this.fileDropped.emit(fileList)
    }
  }
}
