<div class="chatbox-container d-flex border-radius-6">
	<ng-container *ngFor="let chatContext of activeChatWindows">
		<ng-container *ngIf="chatContext.isWindowActive">
			<div class="chatbox-content bg-white ml-2" [ngClass]="{'chatbox-content-height': !chatContext.isPrivate}">
				<conpulse-web-chat-messages
					[conversation]="{_id: chatContext.roomId, isPrivate: chatContext.isPrivate, chatProfile: chatContext.fromUser, name: chatContext.name, projectName: chatContext?.projectName }"
          [isFloatingChat]="true"
          (minimizeRoom)="chatContext.isWindowActive = false"
          (closeRoom)="leavePrivateChat(chatContext.roomId)"
				></conpulse-web-chat-messages>
			</div>
		</ng-container>
	</ng-container>
</div>

<div class="chat-bubble-container">
  <div class="chat-bubble-content position-relative mt-2" *ngFor="let chatContext of activeChatWindows; let i = index">
    <div
      class="border-radius-full overflow-hidden"
      [class.pointer]="!chatContext.isWindowActive"
      (click)="chatContext.isWindowActive = true; chatContext.unreadCount = 0"
    >
      <ng-container *ngIf="chatContext.isPrivate; else groupProfileRef">
        <img class="size-56 width-100" [src]="chatContext?.fromUser?.picture || defaultProfilePhoto" />
      </ng-container>
      <ng-template #groupProfileRef>
        <img class="size-56 bg-white width-100" [src]="chatContext?.photo || defaultGroupPhoto" />
      </ng-template>
    </div>
    <div class="chat-user-status border-radius-full" *ngIf="chatContext.isPrivate && chatContext.fromUser.onlineStatus"></div>
    <p
      class="chat-message-count f-11 l-h-16 l-w-700 d-flex align-items-center justify-content-center border-radius-full px-1"
      *ngIf="chatContext.unreadCount > 0"
    >
      {{ chatContext.unreadCount > 9 ? '9+' : chatContext.unreadCount }}
    </p>
    <mat-icon class="close-icon p-1 border-radius-full pointer" (click)="leavePrivateChat(chatContext?.roomId)">close</mat-icon>
  </div>
</div>
