import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientAccountRoutingModule } from './client-account-routing.module';
import { ClientAccountComponent } from './client-account.component';
import { ConpulseModule } from '../../conpulse.module';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [ClientAccountComponent],
  imports: [CommonModule, ClientAccountRoutingModule, ConpulseModule, ConpulseMaterialModule, I18NextModule],
  exports: [ClientAccountComponent],
})
export class ClientAccountModule {}
