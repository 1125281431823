import { Pipe, PipeTransform } from '@angular/core';
import { UtilityMethodsService } from '../services';
import { NOTATION_PIPE_RESPONSE_TYPE } from '@conpulse-web/core';

/**
 * This pipe is to convert amount values from its minor units(Cents) to Units(Dollars)
 */
@Pipe({
  name: 'delimit',
})
export class AmountDelimitPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    const parts = value?.toString().split('.');
    if(!parts?.length){
      return;
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}
