import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteConfirmationComponent,
  MsaAnswerType,
  MsaDynamicFieldFilePath,
  NotificationService,
  OnBoardingFormVisibility,
  UploaderService,
  ViewType,
  fileInputDocument,
} from '@conpulse-web/core';
import { cloneDeep, isEqual, trim } from 'lodash-es';
import { CommonService, UtilityMethodsService } from '../../services';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'conpulse-web-common-appendix-drawer',
  templateUrl: './common-appendix-drawer.component.html',
  styleUrls: ['./common-appendix-drawer.component.scss'],
})
export class CommonAppendixDrawerComponent implements OnInit, OnChanges {
  @Input() sectionDetails;
  @Output() back: EventEmitter<any> = new EventEmitter();
  title: string = 'Add Section';
  drawerVisibility: OnBoardingFormVisibility = {} as OnBoardingFormVisibility;
  initialSectionDetails;
  selectedDocument: fileInputDocument[] = [];
  answerType = MsaAnswerType;
  isEqual = isEqual;
  trim = trim;
  isLoading = false;
  isValidForm: boolean = false;
  formatList = [];
  fileList = [];
  fileLimit: number;
  @ViewChild('form', { static: true }) form: NgForm;

  constructor(
    private notificationService: NotificationService,
    private uploaderService: UploaderService,
    private dialog: MatDialog,
    private utilityService: UtilityMethodsService,
    private readonly commonService: CommonService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedDocument = [];
    if (this.sectionDetails?.question?.commonData?.template?.length) {
      this.selectedDocument = this.sectionDetails?.question?.commonData?.template;
    }
    this.setDrawerTitle();
  }

  ngOnInit(): void {
    this.initialSectionDetails = JSON.parse(JSON.stringify(this.sectionDetails));
    this.loadFileFormats();
  }

  async viewDoc(key) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey: key }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  /**
   * Check for unsaved changes and displays pop up
   */
  checkForUnsavedChanges(reload: boolean) {
    if (!isEqual(this.sectionDetails, this.initialSectionDetails) && !reload) {
      const deleteConfirmDialogRef = this.dialog.open(DeleteConfirmationComponent, {
        disableClose: true,
        width: '450px',
      });
      deleteConfirmDialogRef.componentInstance.title = 'Unsaved Changes';
      deleteConfirmDialogRef.componentInstance.message = 'Your unsaved changes will be lost. Are you sure you want to leave this page?';
      deleteConfirmDialogRef.componentInstance.acceptanceText = 'Leave';
      deleteConfirmDialogRef.componentInstance.cancelText = 'Stay';
      deleteConfirmDialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.closeDrawer();
        }
      });
    } else {
      this.closeDrawer();
    }
  }

  resetDocumentDetails() {
    this.sectionDetails.question = {
      ...this.sectionDetails.question,
      commonData: {
        text: '',
        document: cloneDeep([]),
        template: cloneDeep({ file: null, type: undefined, key: undefined, name: undefined, isNewUpload: false }),
      },
      clientData: { text: '' },
      firmData: { text: '' },
    };
  }

  async downloadDocument({ key, name }) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: key, fileName: name }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  /**
   * Loads files fornats list
   */
  async loadFileFormats() {
    const data = await this.utilityService.loadAllowedFileFormats();
    this.fileList = data.fileList;
    this.formatList = data.formatList;
    this.fileLimit = data.fileLimit;
  }

  /**
   * Closes the drawer
   */
  closeDrawer() {
    this.back.emit({});
    this.form.resetForm();
    this.form.reset();
  }

  /**
   *Sets value on changes
   * @param event mat slide toggle event
   */
  changeAnswerByToggle(event: MatSlideToggleChange) {
    this.sectionDetails.isClient = event.checked;
  }

  submitForm() {
    if (this.sectionDetails.question.type === this.initialSectionDetails.question.type) {
      this.sectionDetails.question = {
        ...this.sectionDetails.question,
        commonData: this.initialSectionDetails.question.commonData,
        clientData: this.initialSectionDetails.question.clientData,
        firmData: this.initialSectionDetails.question.firmData,
      };
    }
    this.sectionDetails.question.commonData.template = this.selectedDocument;
    this.commonService.saveAppendixData$.next(this.sectionDetails);
    this.closeDrawer();
  }

  onValueChanges(event) {
    this.sectionDetails.question.commonData.template = JSON.parse(JSON.stringify(event));
  }

  setDrawerTitle() {
    if (!this.sectionDetails['isEdit'] && this.sectionDetails['isSectionEditable']) {
      this.title = 'Add section';
    }
    if (!this.sectionDetails['isSectionEditable'] && !this.sectionDetails['isEdit']) {
      this.title = 'Add Field';
    }

    if (this.sectionDetails['isSectionEditable'] && this.sectionDetails['isEdit']) {
      this.title = 'Edit section';
    }

    if (!this.sectionDetails['isSectionEditable'] && this.sectionDetails['isEdit']) {
      this.title = 'Edit Field';
    }
  }
}
