import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[ellipsisTooltip]',
})
export class EllipsisTooltipDirective {

  @Input() ellipsisTooltip: string = '';
  @Input() ellipsisLimit: number = 50; // Set your character limit here
  private isEllipsisActive: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.checkEllipsis();
  }

  private checkEllipsis() {
    const element = this.el.nativeElement;
    this.isEllipsisActive = element.textContent.length > this.ellipsisLimit;

    if (this.isEllipsisActive) {
      this.applyEllipsis();
    }
  }

  private applyEllipsis() {
    const element = this.el.nativeElement;
    const truncatedText = element.textContent.substring(0, this.ellipsisLimit) + '...';
    
    this.renderer.setProperty(element, 'innerText', truncatedText);
  }
}
