import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ChatMessage } from '../interfaces';
import { Socket, io } from 'socket.io-client';
import { AuthService } from '@auth0/auth0-angular';
import { NotificationService } from '../notification';
import { ChatSocketEvents } from '../constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseApiService } from './base.api';
import { get, isEmpty, isNumber, map } from 'lodash-es';

const chatRoutes = {
  conversationList: 'chat/conversationList',
  messageList: 'chat/messages',
  sendMessage: 'chat/send-message',
  newMembers: 'chat/new-users',
  workspaceChat: '/chat/project/workspace-chat',
  chatRoom: 'chat/room/{roomId}',
  filesList: 'chat/project/workspace-files',
  links: 'chat/project/workspace-links',
  tasks: 'chat/project/workspace-tasks',
  deleteMessage: 'chat/message/{messageId}',
  conversationRoom: 'chat/chat-room',
  blockChat: 'chat-room/{chatRoomId}/block',
  unBlockChat: 'chat-room/{chatRoomId}/un-block/{chatRoomSettingsId}',
};

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private latestMessage$ = new BehaviorSubject<ChatMessage>({} as ChatMessage);
  socketUrl: string;
  socketPath: string;
  private socket: Socket | null = null;
  private incomingMessage: Subject<ChatMessage> = new Subject<ChatMessage>();
  public incomingMessage$: Observable<ChatMessage> = this.incomingMessage.asObservable();
  private onlineStatus: Subject<{ userId: string; status: boolean }> = new Subject<{ userId: string; status: boolean }>();
  public onlineStatus$: Observable<{ userId: string; status: boolean }> = this.onlineStatus.asObservable();
  private roomUnreadCount: BehaviorSubject<{ roomId: string; count: number; latestMessage: ChatMessage | null }> = new BehaviorSubject<{
    roomId: string;
    count: number;
    latestMessage: ChatMessage | null;
  }>({
    roomId: '',
    count: 0,
    latestMessage: null,
  });
  public roomUnreadCount$: Observable<{ roomId: string; count: number; latestMessage: ChatMessage | null }> = this.roomUnreadCount.asObservable();
  private unreadCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadCount$: Observable<number> = this.unreadCount.asObservable();

  private blockedUser$: BehaviorSubject<object> = new BehaviorSubject<object>({});

  constructor(
    @Inject('AppEnvironment') environmentConfig,
    private auth: AuthService,
    private baseApi: BaseApiService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.socketUrl = `${environmentConfig?.socketUrl}`;
    this.socketPath = environmentConfig?.socketChannels?.chat;
    if (this.socketPath) {
      this.initializeChat();
    }
  }

  /**
   * Initializes Chat Service
   */
  async initializeChat() {
    this.auth &&
      this.auth.getAccessTokenSilently().subscribe(
        (token) => {
          this.socket = io(this.socketUrl, {
            // TODO:- Token assigned in request headers  is standard approach.
            auth: {
              token: `${token}`,
            },
            path: this.socketPath,
            transports: ['websocket'],
            secure: true,
            reconnection: true,
            reconnectionAttempts: 10,
          });
          this.listenToSocketEvents();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Starts listening to common chat socket events
   */
  private listenToSocketEvents() {
    if (!this.socket) {
      this.notificationService.openErrorSnackBar('Unable to connect to chat!');
    } else {
      // Room Unread Count
      this.socket.on(ChatSocketEvents.RoomUnreadCount, ({ roomId: chatRoomId, count: unreadCount, latestMessage }) => {
        this.roomUnreadCount.next({ roomId: chatRoomId, count: unreadCount, latestMessage });
      });

      this.socket.on(ChatSocketEvents.IncomingMessage, (data: { messageData: ChatMessage }) => {
        this.incomingMessage.next(data.messageData);
        const isChatPageRoute = this.router.routerState.snapshot.url.split('/');
        if (isChatPageRoute.length && !isChatPageRoute?.includes('chat')) this.refreshUnreadCount();
      });

      // Total Unread Count for User
      this.socket.on(ChatSocketEvents.UserUnreadCount, (unreadCount) => {
        this.unreadCount.next(unreadCount);
      });

      this.socket.on(ChatSocketEvents.OnlineStatus, ({ userId, status }) => {
        this.onlineStatus.next({ userId, status });
      });
    }
  }

  /**
   * Refreshes unread count for particular chat room
   * @param roomId Unique ID of the chat room
   */
  public refreshRoomUnreadCount(roomId: string) {
    if (!this.socket) {
      this.notificationService.openErrorSnackBar('Unable to connect to chat!');
    } else {
      this.socket.emit('message', { type: ChatSocketEvents.RoomUnreadCount, roomId });
    }
  }

  /**
   * Refreshes chat message unread count for user
   */
  public refreshUnreadCount() {
    if (this.socketPath) {
      if (this.socket) {
        try {
          this.socket.emit('message', { type: ChatSocketEvents.UserUnreadCount });
        } catch (error) {
          this.notificationService.openErrorSnackBar('Unable to connect to chat!');
        }
      }
    }
  }

  /**
   * Sends a chat message
   * @param messageId The message ID - Unique Id of message
   */
  public sendMessage(messageId: string) {
    if (!this.socket) {
      this.notificationService.openErrorSnackBar('Unable to connect to chat!');
    } else {
      this.socket.emit('message', { type: ChatSocketEvents.SendMessage, messageId: messageId });
    }
  }

  /**
   * Sets latest conversation
   * @param  data loading data for list
   */
  setChatMessageState(data: ChatMessage) {
    this.latestMessage$.next(data);
  }

  /**
   * To fetch latest conversation
   */
  getChatMessage(): Observable<any> {
    return this.latestMessage$.asObservable();
  }

  resetChatMessage() {
    this.latestMessage$.next({} as ChatMessage);
  }

  /**
   * Retrieves list of conversation rooms
   * @param values conversation payload Data
   * @returns List of conversation rooms
   */
  getConversationList(values) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('search', `${values?.search}`);
    if (values?.startDate && values?.endDate) {
      params.append('startDate', `${values?.startDate}`);
      params.append('endDate', `${values?.endDate}`);
    }
    return this.baseApi.httpPost(`${chatRoutes.conversationList}?${params.toString()}`, { isPrivate: !values?.projectId, projectId: values?.projectId });
  }

  /**
   * Retrieves list of message rooms
   * @param values conversation payload Data
   * @returns List of conversation rooms
   */
  getMessageList(values, conversationId) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${chatRoutes.messageList}/${conversationId}?${params.toString()}`);
  }

  /**
   * Save messages in the conversation
   * @param messageDetails send messgae payload
   * @returns boolean value indicates the message status
   */
  sendNewMessage(messageDetails) {
    return this.baseApi.httpPost(`${chatRoutes.sendMessage}`, messageDetails);
  }

  /**
   * Update message in the conversation
   * @param messageDetails send messgae payload
   * @returns boolean value indicates the message status
   */
  updateMessage(messageDetails) {
    return this.baseApi.httpPut(`${chatRoutes.sendMessage}`, messageDetails);
  }

  /**
   * Delete message in the conversation
   * @param messageId messageId
   * @returns Deleted Message
   */
  deleteMessage(messageId) {
    const url = chatRoutes.deleteMessage.replace('{messageId}', messageId);
    return this.baseApi.httpDelete(url);
  }

  blockChat(chatRoomId) {
    const url = chatRoutes.blockChat.replace('{chatRoomId}', chatRoomId);
    return this.baseApi.httpPost(url);
  }

  unBlockChat(chatRoomId, chatRoomSettingsId) {
    const url = chatRoutes.unBlockChat.replace('{chatRoomId}', chatRoomId).replace('{chatRoomSettingsId}', chatRoomSettingsId);
    return this.baseApi.httpPut(url);
  }

  /**
   * Retrieves list of New chat members
   * @param page page of new chat members
   * @param limit limit of records in response
   * @param search search by user name
   * @returns List of new chat members
   */
  getNewChatMembers({ page, limit, search }: { page: number; limit: number; search: string }) {
    const params = new URLSearchParams();
    params.append('page', `${page}`);
    params.append('limit', `${limit}`);
    params.append('search', `${search}`);
    return this.baseApi.httpGet(`${chatRoutes.newMembers}?${params.toString()}`);
  }

  /**
   * creates room for project workspace chat
   * @param projectId Unique Id of the project
   * @returns
   */
  createWorspaceChatRoom(projectId, name) {
    return this.baseApi.httpPost(chatRoutes.workspaceChat, { projectId, name });
  }

  /**
   * Retrieves chat room details By RoomId
   * @param roomId unique Id of room
   * @returns Chat room Details
   */
  getChatRoom(roomId: string) {
    const url = chatRoutes.chatRoom.replace('{roomId}', roomId);
    return this.baseApi.httpGet(url);
  }

  /**
   * Retrieves list of files upload in workspace chat and meetings
   * @param values files payload
   * @returns List of files
   */
  getFilesList(values, projectId) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${chatRoutes.filesList}/${projectId}?${params.toString()}`);
  }

  /**
   * Retrieves list of links upload in workspace chat
   * @param values links payload
   * @returns List of links
   */
  getLinks(values, projectId) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${chatRoutes.links}/${projectId}?${params.toString()}`);
  }

  /**
   * creates/edits the links details
   */
  addOrUpdateLinks(payload, projectId) {
    return this.baseApi.httpPost(`${chatRoutes.links}/${projectId}`, payload);
  }

  /**
   * creates/edits the tasks details
   */
  addOrUpdateTasks(payload, projectId) {
    return this.baseApi.httpPost(`${chatRoutes.tasks}/${projectId}`, payload);
  }

  /**
   * Marks the tasks as closed
   */
  closeTask(payload, projectId) {
    return this.baseApi.httpPut(`${chatRoutes.tasks}/${projectId}`, payload);
  }

  /**
   * Retrieves list of tasks created in workspace
   * @param values tasks payload
   * @returns List of tasks
   */
  getTasks(values, projectId) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('search', `${values?.search}`);

    if (!isEmpty(get(values, 'owner', []))) {
      map(values.owner, (owner) => {
        params.append('assignedTo', owner);
      });
    }

    if (isNumber(get(values, 'status'))) {
      params.append('status', get(values, 'status'));
    }

    if (!isEmpty(get(values, 'createdBy', []))) {
      map(values.createdBy, (createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (!isEmpty(get(values, 'assignedDateStart'))) {
      params.append('assignedDate.start', get(values, 'assignedDateStart'));
    }

    if (!isEmpty(get(values, 'assignedDateEnd'))) {
      params.append('assignedDate.end', get(values, 'assignedDateEnd'));
    }

    if (!isEmpty(get(values, 'dueDateStart'))) {
      params.append('dueDate.start', get(values, 'dueDateStart'));
    }

    if (!isEmpty(get(values, 'dueDateEnd'))) {
      params.append('dueDate.end', get(values, 'dueDateEnd'));
    }
    return this.baseApi.httpGet(`${chatRoutes.tasks}/${projectId}?${params.toString()}`);
  }

  updateWorspaceChatRoom(_id, name, isUpdate) {
    return this.baseApi.httpPut(chatRoutes.workspaceChat, { _id, name, isUpdate });
  }

  getChatRoomByMembers(receiverId) {
    return this.baseApi.httpPost(chatRoutes.conversationRoom, { receiverId });
  }

  getBlockedUserDetails(): Observable<any> {
    return this.blockedUser$.asObservable();
  }

  updateBlockeduser(blockedUser: object) {
    this.blockedUser$.next(blockedUser);
  }
}
