import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { isEmpty, trim } from 'lodash-es';

// validator for password match
@Directive({
    selector: '[validatePassword][formControlName],[validatePassword][formControl],[validatePassword][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordValidator), multi: true }
    ]
})

export class PasswordValidator implements Validator {

    isParent: boolean;
    constructor(
        @Attribute('validatePassword') public validatePassword: string,
        @Attribute('isParentField') public isParentField: string,
    ) {}

    validate(field: AbstractControl): { [key: string]: any } {

        const checkingValue = field.value;
        let elm = field.root.get(this.validatePassword);

        this.isParent = (!isEmpty(this.isParentField) && this.isParentField === 'true');

        if (elm) {
            const elmVal = !isEmpty(trim(elm.value));

            // value not equal and check from confirm field
            if (checkingValue !== elm.value && elmVal && !this.isParent) {
                return {
                    validatePassword: { valid: false }
                }
            }

            // value equal and check from main field
            if (checkingValue === elm.value && elmVal && this.isParent) {
                delete elm.errors['validatePassword'];
                if (!Object.keys(elm.errors).length) elm.setErrors(null);
            }

            // value not equal and check from main field
            if (checkingValue !== elm.value && elmVal && this.isParent) {
                elm.setErrors({ validatePassword: { valid: false } });
            }
        }

        return null;
    }
}
