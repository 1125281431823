import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[conpulseWebAmountSeperator]',
})
export class AmountSeperatorDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>, public renderer: Renderer2, private ngModel: NgModel) {}

  @Output() fieldToUpdateChange = new EventEmitter<number>();

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this.formatBudget(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.formatBudget(value);
  }

  /**Need to optimize this */
  formatBudget = (value) => {
    if (value) {
      const numberFormat = parseInt(value?.toString()?.replace(/,/g, ''));
      const formattedValue = numberFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedValue);
      this.fieldToUpdateChange.emit(numberFormat);
      this.ngModel.update.emit(formattedValue);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
      this.fieldToUpdateChange.emit(null);
      this.ngModel.update.emit(null);
    }
  };
}
