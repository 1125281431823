import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout.component';
import { ConpulseMaterialModule } from '../../material-module/index';


@NgModule({
  declarations: [LogoutComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule
  ],
  exports: [
    LogoutComponent
  ]
})
export class LogoutModule { }
