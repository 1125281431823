import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { ConpulseMaterialModule } from '../../../material-module/conpulse-material.module';
import { LibraryManagementComponent } from './library-management.component';
import { CreateTemplateExistingModule } from '../create-template-existing/create-template-existing.module';

@NgModule({
  declarations: [LibraryManagementComponent],
  imports: [CommonModule, ConpulseMaterialModule, CreateTemplateExistingModule, I18NextModule],
  exports: [LibraryManagementComponent],
})
export class LibraryManagementModule {}
