import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatService, NotificationService, UserService, defaultProfilePhoto, user } from '@conpulse-web/core';
import { MatMenu } from '@angular/material/menu';
import { Subject, Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'conpulse-web-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  exportAs: 'menuInOtherComponent',
})
export class MessagePopupComponent implements OnInit, OnDestroy {
  receiverId: Subject<string> = new Subject<string>();
  receiverIdSubscriber: Subscription | null;
  userDetails = {} as { _id: string; name: string; picture: string; companyId: { companyName: string }; firmId: { firmName: string } };
  defaultProfilePhoto = defaultProfilePhoto;
  isLoading: boolean = false;
  @ViewChild(MatMenu, { static: true }) popMenu: MatMenu;
  titleCasePipe: TitleCasePipe = new TitleCasePipe();
  // fullBioText: string = '';

  constructor(private userService: UserService, private chatService: ChatService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.receiverIdSubscriber = this.receiverId.subscribe((data) => {
      this.isLoading = true;
      this.userService.getUser(data).subscribe((data) => {
        this.userDetails = data.data;
        this.userDetails.name = this.userDetails.name;
        if (this.userDetails?.companyId?.companyName) this.userDetails.companyId['companyName'] = this.userDetails.companyId['companyName'];
        if (this.userDetails?.firmId?.firmName) this.userDetails.firmId['firmName'] = this.userDetails.firmId['firmName'];
        // TODO:- commented for future use
        // \u00A0- Non breaking space;
        // \u2022 - Bullet
        // this.fullBioText = `${this.userDetails?.['role'] > 5 ? this.userDetails?.firmId?.firmName : this.userDetails?.companyId?.companyName}${
        //   this.userDetails?.['jobtitle'] ? '\u00A0\u2022\u00A0' + this.userDetails?.['jobtitle'] : ''
        // }`;
        this.isLoading = false;
      });
    });
  }

  ngOnDestroy() {
    this.receiverIdSubscriber?.unsubscribe();
  }

  handleClick() {
    this.userService.getChatRoomByMembers(this.userDetails?._id).subscribe(
      (data) => {
        this.userService.triggerChatWidget.next(data.data._id);
      },
      (error) => {
        this.notificationService.openErrorSnackBar(error.message);
      }
    );
  }
}
