import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeAdminFormComponent } from './change-admin-form.component';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';



@NgModule({
  declarations: [ChangeAdminFormComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ConpulseModule,
    InfiniteScrollModule
  ],exports: [ChangeAdminFormComponent]
})
export class ChangeAdminFormModule { }
