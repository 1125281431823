import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService, user } from '@conpulse-web/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit {
  title: string;
  message: string;
  acceptanceText: string;
  type = 'warning';
  names = [];
  cancelText = 'Cancel';
  checkConflict: boolean;
  isConflict: boolean;
  firmName = '';
  constructor(private user: UserService, private dialogRef: MatDialogRef<DeleteConfirmationComponent>) {}

  ngOnInit() {
    if (this.checkConflict) {
      this.user.getCurrentUserInformation().subscribe((data: user | any) => {
        this.firmName = data?.firmId?.firmName;
      });
    }
  }

  handleClick(value) {
    this.checkConflict ? this.dialogRef.close({ isConflict: this.isConflict, confirmed: value }) : this.dialogRef.close(value);
  }
}
