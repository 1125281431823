import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LightListPriority, RiskData, UserService, user } from '@conpulse-web/core';
import { MessagePopupComponent } from '../message-popup/message-popup.component';

@Component({
  selector: 'conpulse-web-view-risk',
  templateUrl: './view-risk.component.html',
  styleUrls: ['./view-risk.component.scss'],
})
export class ViewRiskComponent implements OnInit {
  currentUserInfo: user = {} as user;
  @ViewChild(MessagePopupComponent) messagePopupComponentMenu: MessagePopupComponent;
  constructor(private dialogRef: MatDialogRef<ViewRiskComponent>, private userService: UserService) {
  }
  risk = {} as RiskData;
  LightPriorities = LightListPriority;
  ngOnInit(): void {
    this.currentUserInfo =  this.userService.currentUserInformation;
  }

  openMessagePopup(id) {
    this.messagePopupComponentMenu.receiverId.next(id);
  }
}
