import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicSectionsComponent } from './dynamic-sections.component';
import { CustomisationDynamicTemplateModule } from '../customisation-dynamic-template/customisation-dynamic-template.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';



@NgModule({
  declarations: [DynamicSectionsComponent],
  imports: [
    CommonModule,
    CustomisationDynamicTemplateModule,
    DragDropModule,
    ConpulseMaterialModule,
    FormsModule,
    I18NextModule
  ],
  exports: [DynamicSectionsComponent]
})
export class DynamicSectionsModule { }
