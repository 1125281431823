import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountAndBillingRoutingModule } from './account-and-billing-routing.module';
import { AccountAndBillingComponent } from './account-and-billing.component';
import { I18NextModule } from 'angular-i18next';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';


@NgModule({
  declarations: [AccountAndBillingComponent],
  imports: [
    CommonModule,
    AccountAndBillingRoutingModule,
    I18NextModule,
    ConpulseMaterialModule,
    ConpulseModule,
  ],
  exports: [AccountAndBillingComponent]
})
export class AccountAndBillingModule { }
