<div>
    <form #myForm="ngForm" name="myForm">
      <div class="row">
        <div class="col-12">
          <label>Amount</label>
          <mat-form-field class="full-width">
            <input
              matInput
              type="text"
              name="amount"
              pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$"
              [(ngModel)]="amount"
              (ngModelChange)="validateForm()"
              [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }"
              required
            />
            <mat-error *ngIf="myForm.controls['amount']?.errors?.pattern">{{ 'Invalid Amount' }}</mat-error>
            <mat-error *ngIf="myForm.controls['amount']?.errors?.required">{{ 'Required' }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

