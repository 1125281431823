import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../notification';
import { AuthService } from '@auth0/auth0-angular';
import { BaseApiService } from './base.api';
import { Socket, io } from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationBackendService {
  socketUrl: string;
  socketPath: string;
  private socket: Socket | null = null;
  private isNotificationMenuOpen: boolean = false;
  private newNotification: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public newNotification$: Observable<any> = this.newNotification.asObservable();
  public unreadCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public openedTaskCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadCount$: Observable<number> = this.unreadCount.asObservable();
  constructor(
    @Inject('AppEnvironment') environmentConfig,
    private auth: AuthService,
    private baseApi: BaseApiService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.socketUrl = `${environmentConfig?.socketUrl}`;
    this.socketPath = environmentConfig?.socketChannels?.notification;
    if (this.socketPath) {
      this.initializeNotifications();
    }
  }

  get getNotificationMenuOpenStatus() {
    return this.isNotificationMenuOpen;
  }
  updateNotificationMenuOpenStatus(isOpen: boolean) {
    this.isNotificationMenuOpen = isOpen;
  }

  async initializeNotifications() {
    this.auth &&
      this.auth.getAccessTokenSilently().subscribe(
        (token) => {
          this.socket = io(this.socketUrl, {
            // TODO:- Token assigned in request headers  is standard approach.
            auth: {
              token: `${token}`,
            },
            path: this.socketPath,
            transports: ['websocket'],
            secure: true,
            reconnection: true,
            reconnectionAttempts: 10,
          });
          this.listenToSocketEvents();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /**
   * Starts listening to common chat socket events
   */
  private listenToSocketEvents() {
    if (!this.socket) {
      this.notificationService.openErrorSnackBar('Unable to connect to chat!');
    } else {
      this.socket.on('unreadCount', (notificationsList) => {
        this.unreadCount.next(notificationsList?.unreadCount);
      });

      this.socket.on('notification', (notification) => {
        this.newNotification.next(notification);
        this.unreadCount.next(this.unreadCount.value + 1);
      });

      this.socket.on('openedTaskCount', (count) => {
        this.openedTaskCount$.next(count);
      })
    }
  }

  public readNotification(notificationsToBeMarkedAsRead: Array<string>) {
    this.socket.emit('readNotification', notificationsToBeMarkedAsRead);
  }

  public unreadNotification(notificationsToBeMarkedAsUnread: Array<string>) {
    this.socket.emit('unreadNotification', notificationsToBeMarkedAsUnread);
  }

  public refreshOpenedTaskCount() {
    this.socket.emit('refreshOpenedTaskCount');
  }
}
