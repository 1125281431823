import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService, AdminService, NotificationService } from '@conpulse-web/core';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, isNil } from 'lodash-es';

@Component({
  selector: 'conpulse-web-add-invoice-billing',
  templateUrl: './add-invoice-billing.component.html',
  styleUrls: ['./add-invoice-billing.component.scss'],
})
export class AddInvoiceBillingComponent implements OnInit {
  amount = 0;
  invoiceId: '';
  invoice = {};
  meetingNatureAlreadyExistError = false;
  isLoading: boolean = false;
  clientId = '';
  @Input() parentData;
  @Output() isSubmitButtonDisabled = new EventEmitter<boolean>();
  @Output() isDialogLoading = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();
  status = '';
  constructor(
    private userService: UserService,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private i18Next: I18NextPipe
  ) {}

  ngOnInit(): void {
    if (this.parentData?.isEdit) {
      this.invoiceId = cloneDeep(this.parentData?.editData?.id);
      this.amount = cloneDeep(this.parentData?.amount);
      this.status = cloneDeep(this.parentData?.editData?.status);
      this.invoice = cloneDeep(this.parentData?.findInvoice);
    }
    this.clientId = cloneDeep(this.parentData?.clientId);
  }

  //Validates form
  validateForm() {
    if (!isNil(this.amount) && this.amount > 0) {
      this.isSubmitButtonDisabled.emit(false);
    } else {
      this.isSubmitButtonDisabled.emit(true);
    }
  }
  //TODO API CALL
  addInvoiceBilling(dataToAdd) {
    this.isDialogLoading.emit(true);
    this.isLoading = true;
    this.adminService.createOrUpdateInvoiceBilling(dataToAdd).subscribe(
      (response) => {
        this.isLoading = false;
        this.isDialogLoading.emit(false);
        this.notificationService.openSuccessSnackBar(this.i18Next.transform(`Invoice added successfully`));
        this.closeDialog.emit(true);
        this.userService.refreshLanguageComp.next();
      },
      (error) => {
        this.notificationService.openErrorSnackBar(error.message);
        this.isDialogLoading.emit(false);
        this.isLoading = false;
      }
    );
    this.isDialogLoading.emit(false);
  }

  submitDialog() {
    let dataToUpdate;
    if (this.parentData?.isEdit) {
      dataToUpdate = {
        _id: this.invoiceId,
        amount: this.amount,
        isUpdate: true,
        clientId: this.clientId,
      };
      this.addInvoiceBilling(dataToUpdate);
    } else {
      const dataToAdd = {
        amount: this.amount,
        clientId: this.clientId,
      };
      this.addInvoiceBilling(dataToAdd);
    }
  }
}
