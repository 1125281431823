import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeFrench from '@angular/common/locales/fr';
import localePortuguese from '@angular/common/locales/pt';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeApi from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';
import { environment } from '../environments/environment';
import { ConpulseMaterialModule, ConpulseModule } from '@conpulse-web/conpulse';
import { LoginGuard, AccountGuard, CoreModule, NotVerifiedGuard, CustomAuthHttpInterceptor } from '@conpulse-web/core';

// Import the module from the SDK
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { I18NEXT_SERVICE, I18NextLoadResult, I18NextModule, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';
import { registerLocaleData } from '@angular/common';

const locizeOptions = {
  projectId: 'a802d3c3-247c-4256-8594-91c15166e3dd',
};

const i18nextOptions = {
  debug: false,
  fallbackLng: 'en-US',
  // saveMissing: true, // do not use the saveMissing functionality in production: https://docs.locize.com/guides-tips-and-tricks/going-production
  backend: locizeOptions,
  locizeLastUsed: locizeOptions,
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
};

export function appInit(i18next: ITranslationService) {
  return () => {
    let promise: Promise<I18NextLoadResult> = i18next
      // locize-lastused
      // sets a timestamp of last access on every translation segment on locize
      // -> safely remove the ones not being touched for weeks/months
      // https://github.com/locize/locize-lastused
      // do not use the lastused functionality in production: https://docs.locize.com/guides-tips-and-tricks/going-production
      .use(LastUsed)
      // locize-editor
      // InContext Editor of locize
      .use(locizePlugin)
      // i18next-locize-backend
      // loads translations from your project, saves new keys to it (saveMissing: true)
      // https://github.com/locize/i18next-locize-backend
      .use(LocizeApi)
      .use<any>(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

registerLocaleData(localeFrench);
registerLocaleData(localePortuguese);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConpulseMaterialModule,
    ConpulseModule,
    CoreModule.configureEnvironment(environment),
    HttpClientModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      ...environment.auth,
      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [ {
          uri: 'http://localhost:4200/api/verify/*',
          allowAnonymous: true,
        }, environment.httpInterceptorUrl,]
      }
    }),
    I18NextModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomAuthHttpInterceptor, multi: true },
    LoginGuard,
    AccountGuard,
    NotVerifiedGuard,
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
