import { CONSTANTS } from '@conpulse-web/core';

export const environment = {
  production: true,
  APIEndpoint: 'https://dev-api.consource.io/api/',
  systemBase: 'https://devapp.consource.io/',
  auth: {
    ...CONSTANTS.auth0Config,
    redirectUri: 'https://devapp.consource.io/'
  },
  socketUrl: 'https://dev-api.consource.io/',
  httpInterceptorUrl: 'https://dev-api.consource.io/api/*',
  loadFromSandboxData: true,
};

// Stage Env
// export const environment = {
//   production: true,
//   APIEndspoint: 'https://stage.consource.io/api/',
//   systemBase: 'https://stage.consource.io/',
//   auth: {
//     ...CONSTANTS.auth0Config,
//     redirectUri: 'https://stage.consource.io/',
//   },
//   httpInterceptorUrl: 'https://stage.consource.io/api/*',
// };

// export const environment = {
//   production: true,
//   APIEndpoint: 'https://app.consource.io/api/',
//   systemBase: 'https://app.consource.io/',
//   auth: {
//     ...CONSTANTS.auth0Config,
//     redirectUri: 'https://app.consource.io/',
//   },
//   httpInterceptorUrl: 'https://app.consource.io/api/*',
// };
