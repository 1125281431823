import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyProfileComponent } from './company-profile.component';

const routes: Routes = [
  {
    path: '',
    component: CompanyProfileComponent,
    children: [
      {
        path: 'general-info',
        loadChildren: () => import('./general-info/general-info.module').then((m) => m.GeneralInfoModule),
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'offices',
        loadChildren: () => import('./office/office.module').then((m) => m.OfficeModule),
      },
      {
        path: 'publications',
        loadChildren: () => import('./publications/publications.module').then((m) => m.PublicationsModule),
      },
      {
        path: 'offerings',
        loadChildren: () => import('./offerings/offerings.module').then((m) => m.OfferingsModule),
      },
      {
        path: 'case-studies',
        loadChildren: () => import('./case-study/case-study.module').then((m) => m.CaseStudyModule),
      },
      {
        path: 'legal-entity',
        loadChildren: () => import('./legal-entity/legal-entity.module').then((m) => m.LegalEntityModule),
      },
      {
        path: 'legal-entity/edit/:id',
        loadChildren: () => import('./view-legal-entity/view-legal-entity.module').then((m) => m.ViewLegalEntityModule),
      },
      {
        path: 'legal-entity/changes/:id',
        loadChildren: () => import('./legal-entity-changes/legal-entity-changes.module').then((m) => m.LegalEntityChangesModule),
      },
      {
        path: 'account',
        loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.ClientAccountModule),
      },
      {
        path: 'contracts',
        loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.ClientContractsModule),
      },
      // TODO: we comment this line as per this CN-1283
      // {
      //   path: 'billing-address',
      //   loadChildren: () => import('@conpulse-web/conpulse').then((m) => m.BillingAddressModule),
      // },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'general-info',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyProfileRoutingModule { }
