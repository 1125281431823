import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientContractsRoutingModule } from './client-contracts-routing.module';
import { ClientContractsComponent } from './client-contracts.component';
import { ConpulseModule } from '../../conpulse.module';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';


@NgModule({
  declarations: [ClientContractsComponent],
  imports: [
    CommonModule,
    ClientContractsRoutingModule,
    ConpulseModule,
    ConpulseMaterialModule,
    I18NextModule
  ]
})
export class ClientContractsModule { }
