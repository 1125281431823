import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  
    selector:'[focus]',
  })
  
  export class FocusDirective implements AfterViewInit{
    @Input() focus: boolean
    constructor(private el:ElementRef, private rd:Renderer2,private changeDetectorRef: ChangeDetectorRef){
    }
    
    ngAfterViewInit(){
      if(this.focus){
        this.el.nativeElement.focus();
        this.changeDetectorRef.detectChanges();
      }
    }
  }