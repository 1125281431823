import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewChatComponent } from './view-chat.component';
import { ConpulseMaterialModule } from '../../material-module/index';
import { ChatRoomListModule } from '../chat-room-list/chat-room-list.module';
import { ChatMessagesModule } from '../chat-messages/chat-messages.module';


@NgModule({
  declarations: [ViewChatComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    ChatRoomListModule,
    ChatMessagesModule,
  ],
  exports: [ViewChatComponent]
})
export class ViewChatModule { }
